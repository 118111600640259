<template>
  <v-container>
    <v-row>
      <v-card flat width="60%" class="ma-5" outlined rounded>
        <v-card-subtitle>会社・代表者情報</v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
            <tr><th width="30%">会社名</th><td>{{ form.company_name_kanji }}</td></tr>
            <tr><th>会社名（カナ）</th><td>{{ form.company_name_kana }}</td></tr>
            <tr><th>本社郵便番号</th><td>{{ form.zip_cd }}</td></tr>
            <tr><th>本社住所1</th><td>{{ form.address_1_kanji }}</td></tr>
            <tr><th>本社住所2</th><td>{{ form.address_2_kanji }}</td></tr>
            <tr><th>本社住所1（カナ）</th><td>{{ form.address_1_kana }}</td></tr>
            <tr><th>本社住所2（カナ）</th><td>{{ form.address_2_kana }}</td></tr>
            <tr><th>代表TEL</th><td>{{ form.tel }}</td></tr>
            <tr><th>インボイス登録番号</th><td>{{ form.invoice_no }}</td></tr>
            <tr><th>代表者氏名</th><td>{{ form.representative_name }}</td></tr>
            <tr><th>代表者役職</th><td>{{ form.representative_title }}</td></tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-card flat width="60%" class="ma-5" outlined rounded>
        <v-card-subtitle>担当者情報</v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
            <tr><th width="30%">担当者名 姓</th><td>{{ form.person_name_sei_kanji }}</td></tr>
            <tr><th>担当者名 名</th><td>{{ form.person_name_mei_kanji }}</td></tr>
            <tr><th>担当者名 姓（カナ）</th><td>{{ form.person_name_sei_kana }}</td></tr>
            <tr><th>担当者名 名（カナ）</th><td>{{ form.person_name_mei_kana }}</td></tr>
            <tr><th>部課名</th><td>{{ form.buka_name_kanji }}</td></tr>
            <tr><th>部課名（カナ）</th><td>{{ form.buka_name_kana }}</td></tr>
            <tr><th>担当者TEL</th><td>{{ form.person_tel }}</td></tr>
            <tr><th>担当者FAX</th><td>{{ form.person_fax }}</td></tr>
            <tr><th>メールアドレス</th><td>{{ form.person_email }}</td></tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row>
      <v-card flat width="60%" class="ma-5" outlined rounded>
        <v-card-subtitle>銀行口座情報</v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
            <tr><th width="30%">銀行コード</th><td>{{ form.bank_code }}</td></tr>
            <tr><th>支店コード</th><td>{{ form.branch_code }}</td></tr>
            <tr><th>銀行名</th><td>{{ form.bank_name }}</td></tr>
            <tr><th>支店名</th><td>{{ form.branch_name }}</td></tr>
            <tr><th>預金種類</th><td>{{ $t(form.account_type) }}</td></tr>
            <tr><th>口座番号</th><td>{{ form.account_number }}</td></tr>
            <tr><th>口座名義</th><td>{{ form.account_name }}</td></tr>
            <tr><th>添付ファイル</th><td>
              <v-btn
                  v-for="(item) of form.bank_attachment_filename"
                  :key="item.id"
                  class="mr-2 mt-2 custom-transform-class text-none"
                  outlined
                  color="indigo"
                  @click="downloadAttachment(item.id, item.name)"
              >
                <v-icon class="mr-1" small>mdi-file-table-outline</v-icon>
                {{item.name}}
              </v-btn> <br/>
            </td></tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>

    <v-row>
      <v-card flat width="60%" class="ma-5" outlined rounded>
        <v-card-subtitle>利用料ご請求先情報</v-card-subtitle>
        <v-card-text>
          <v-simple-table dense>
            <tbody>
            <tr><th width="30%">担当者名 姓</th><td>{{ form.comission_person_name_sei_kanji }}</td></tr>
            <tr><th>担当者名 名</th><td>{{ form.comission_person_name_mei_kanji }}</td></tr>
            <tr><th>担当者名 姓（カナ）</th><td>{{ form.comission_person_name_sei_kana }}</td></tr>
            <tr><th>担当者名 名（カナ）</th><td>{{ form.comission_person_name_mei_kana }}</td></tr>
            <tr><th>担当者TEL</th><td>{{ form.comission_person_tel }}</td></tr>
            <tr><th>メールアドレス</th><td>{{ form.comission_person_email }}</td></tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import candidateService from "../services/candidateService";
import axios from 'axios'
import { saveAs } from 'file-saver';

export default {
  name: 'Confirm',
  title: 'パートナー登録申請内容確認',
  components: {
  },
  props: {
    form: PartnerForm,
  },
  data: () => ({
    checkbox: false
  }),
  methods: {
    submit () {
      this.$refs.observer.validate()
      this.$emit('update:validation_confirm', true)
      this.$emit('register')
    },
    getFileNames(obj) {
      let names = ""
      if ( obj ) {
        for (const item of obj) {
          names = names + item.name + " "
        }
      }
      return names
    },
    async downloadAttachment(fileid, filename) {

      try{
        const presignedurl= await candidateService.getAttachmentDownloadPresignedUrl(fileid)
        const response = await axios.get(presignedurl, {responseType:'blob'})
            .catch((error) => {
              console.log(error)
            })
        //const filename = presignedurl.match(".+/(.+?)([?#;].*)?$")[1];

        if (response && (200 == response.status)) {
          saveAs(response.data, filename)
        }

      } catch(error) {
        console.log(error)
      }
    }
  },
  async mounted () {
    this.$emit('update:zip_cd', this.form.zip_cd)
    this.$emit('update:address_1_kanji', this.form.address_1_kanji)
    this.$emit('update:address_2_kanji', this.form.address_2_kanji)
    this.$emit('update:address_1_kana', this.form.address_1_kana)
    this.$emit('update:address_2_kana', this.form.address_2_kana)
    this.$emit('update:tel', this.form.tel)
    this.$emit('update:fax', this.form.fax)
  }
}
</script>
