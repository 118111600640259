<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>

      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-text>
                <br/>{{ this.process_message }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <v-card flat width="100%" class="mb-2" id="pageTop">
        <v-card-title>
          手数料明細
          <v-btn-toggle
            v-model="selected_page"
            class="ml-5"
            dense
            group
          >
          <v-btn color="indigo darken-4" outlined value="partnerSearch" class="ml-10 font-weight-black">
              パートナー一覧
            </v-btn>
            <v-btn color="teal darken-4" outlined value="transactionhistory" to="/transactionhistory" elevation="3" class="ml-10 font-weight-black">
              月次取引履歴/明細検索
            </v-btn>
            <v-btn color="teal darken-4" outlined value="monthlyfeestatements" to="/monthlyfeestatements" elevation="3" class="font-weight-black">
              月次手数料明細書一覧
            </v-btn>
            <v-btn color="orange darken-4" outlined value="feestatements" to="/feestatements" elevation="3" class="ml-10 font-weight-black">
              手数料明細書検索
            </v-btn>
          </v-btn-toggle>
          <v-btn elevation="3" class="ml-10 font-weight-black orange--text text--darken-2 rounded-0" style="background-color:#fff" @click="uploadHelperParam.visible = true">
            仕入追加・単価修正+/-CSV登録
          </v-btn>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="light-blue darken-4"
              fab
              large
              dark
              depressed
              @click="helperParam.helper_dialog = true"
              >
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-title>

        <!-- ヘルプ -->
        <FeeCalculateHelper :helperParam="helperParam" />

        <!-- CSVアップロードダイアログ -->
        <RegisterCsvDialog 
          :uploadHelperParam="uploadHelperParam"
          @onUploadCsvSuccess="onUploadCsvSuccess" />

        <v-alert dense outlined :type=this.process_message_type v-if="this.process_message">
          {{ this.process_message }}
        </v-alert>

        <v-card flat class="ml-5">
          <v-row>
            <v-col cols="2">
              <v-text-field
                  dense
                  v-model.trim="input_company_name"
                  outlined placeholder="会社名"
                  clearable>
              </v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                  dense
                  v-model.trim="input_supplier_cd"
                  outlined placeholder="仕入先コード"
                  clearable>
              </v-text-field>
            </v-col>

            <v-col cols="2">
              <v-text-field
                  dense
                  v-model.trim="input_customer_cd"
                  outlined placeholder="得意先コード"
                  clearable>
              </v-text-field>
            </v-col>
            <v-col cols="2">
            <v-select
              v-model="input_mep_partner_sales_fee_target"
              :items="mep_partner_sales_fee_target_codes"
              label="手数料請求対象"
              dense
              clearable
              hide-details
              full-width
              outlined
            ></v-select>
          </v-col>

            <v-col cols="2">
              <v-btn :color="search_btn_color"
                      :loading=search_loading
                      dark
                      class="ml-5"
                      @click="searchPartners()">
                <v-icon class="mr-1">mdi-database-search-outline</v-icon>
                パートナー検索
              </v-btn>
            </v-col>
          </v-row>

        </v-card>
      </v-card>

      <v-container
          v-if="suppliers" fluid class="pa-1">
        <v-data-table
          :headers="headers"
          :items="suppliers"
          :items-per-page="20"
          :sort-by="['candidate_complete_datetime']"
          :sort-desc="true"
          class="elevation-1"
          item-key="id"
          fixed-header
          height="550"
          >
          <template
              v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
              v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn 
              v-if="item.mep_partner_sales_fee_target=='1'"
              @click="disableFeeTarget(item.id)"
              outlined
              color="red"
              dense>
              対象外にする
            </v-btn>
            <v-btn
              v-else
              @click="enableFeeTarget(item.id)"
              outlined
              color="indigo"
              dense>
              対象にする
            </v-btn>
          </template>
        </v-data-table>
        <v-btn 
          @click="$vuetify.goTo('#pageTop', {duration: 500, offset: 0})"
          outlined
          depressed
          >ページトップへ</v-btn>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import feeCalculatorService from '@/services/feeCalculatorService';
import formatterutil from "@/util/formatter"
import FeeCalculateHelper from '@/components/feeCalculate/FeeCalculateHelper.vue'
import RegisterCsvDialog from '@/components/feeCalculate/RegisterCsvDialog.vue'

export default {
  name: 'partnerSearch',
  title: '手数料明細:パートナー一覧',
  data () {
    return {
      search_btn_color: "light-blue darken-4",
      // 検索入力項目用
      selected_page: 'partnerSearch',
      input_company_name: '',
      input_supplier_cd: '',
      input_customer_cd: '',
      input_mep_partner_sales_fee_target: '',

      mep_partner_sales_fee_target_codes:[
        {value:"1", text:"対象"}, 
        {value:"0", text:"対象外"}, 
      ],

      // データ表示用
      suppliers:[],
      headers: [
        { text: '仕入先コード', value: 'supplier_cd' },
        { text: '得意先コード', value: 'customer_cd' },
        { text: '会社名', value: 'company_name_kanji' },
        { text: '登録ステータス', value: 'candidate_status', formatter: formatterutil.mepPartnerCandidateStatusFormatter, },
        { text: '登録完了日時', value: 'candidate_complete_datetime' },
        { text: '手数料請求対象', value: 'mep_partner_sales_fee_target', formatter: formatterutil.mepPartnerSalesFeeTargetFormatter, },
        { text: '更新', value: 'actions' },
      ],

      // 画面操作用
      process_message:'',
      process_message_type:'',
      search_loading: false,
      search_finished: false,
      dialog: false,

      helperParam: {
        stepper_step: 1,
        helper_dialog: false,
      },

      /** CSV登録ダイアログ */
      uploadHelperParam: {
        /** ダイアログ表示可否 */
        visible: false,
      }

    }
  },
  components: {
    FeeCalculateHelper,
    RegisterCsvDialog
  },
  methods: {
    // 検索用メソッド
    // パートナー検索
    searchPartners () {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""

      feeCalculatorService.searchPartners(
        this.input_company_name, 
        this.input_supplier_cd, 
        this.input_customer_cd, 
        this.input_mep_partner_sales_fee_target
        ).then(data => {
        // 取得データ用
        this.suppliers = data

        // 画面操作用
        this.search_loading = false
        this.search_finished=true
      })
    },

    // 手数料請求対象フラグ更新
    enableFeeTarget (id) {
      feeCalculatorService.setPartnerFeeTarget(id, "1")
      .then(data => {
        this.searchPartners()
        this.process_message = data.detail
        this.process_message_type = "info"
        this.dialog = true
      })
      .catch((error) => {
        console.log(error)
        this.process_message = error.detail
        this.process_message_type = "error"
        this.dialog = true
      })
    },
    disableFeeTarget (id) {
      feeCalculatorService.setPartnerFeeTarget(id, "0")      
      .then(data => {
        this.searchPartners()
        this.process_message = data.detail
        this.process_message_type = "info"
        this.dialog = true
      })
      .catch((error) => {
        console.log(error)
        this.process_message = error.detail
        this.process_message_type = "error"
        this.dialog = true
      })
    },
    /** CSV アップロード成功後 */
    onUploadCsvSuccess(message) {
      this.process_message = message
      this.process_message_type = "info"
      this.dialog = true
    },
  },
  mounted () {
    this.searchPartners()
  },
}
</script>


<style type="text/css">

table.history, table.history td, table.history th {
  border: 1px lightgray solid;
  border-collapse: collapse;
  font-size: 14px;
}

table.history td, table.history th {
  padding: 2px 10px;
}

</style>