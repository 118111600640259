<template>
  <v-container>
    <v-row dense>
      <v-col cols="3" class="text-h6 pt-5" align-self="center">得意先コード登録情報</v-col>
      <v-col cols="1" class="pt-5" align-self="center"><v-chip v-if="this.form.customer_cd" color="success" dark class="ma-0" small>完了</v-chip></v-col>
    </v-row>
    <v-row class="pt-5 pl-5 pr-5" dense>
      <v-col>
        <v-text-field v-if="form.customer_cd && form.customer_cd.length > 0"
          label="得意先コード"
          v-model="customercd"
          required readonly
        >
          <template v-slot:append-outer>
            <v-btn
                class="mr-4"
                color="blue darken-4"
                outlined
                disabled
            >
              登録
            </v-btn>
          </template>
        </v-text-field>
        <form v-else-if="this.form.status=='processing'" @submit.prevent="submit">
          <validation-observer
              ref="observer"
              v-slot="{ invalid }"
          >
            <validation-provider
                v-slot="{ errors }"
                name="得意先コード"
                rules="required|max:6"
            >
              <v-text-field
                  label="得意先コード"
                  v-model="customercd"
                  :error-messages="errors"
                  required
                  hint="6桁までで入力してください"
              >
                <template v-slot:append-outer>
                  <v-btn
                      class="mr-4"
                      color="blue darken-4"
                      outlined
                      @click="confirm"
                      :disabled="invalid"
                      :loading="form.customercd_loading"
                  >
                    登録
                  </v-btn>
                </template>
              </v-text-field>
              <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="320"
              >
                <v-card>
                  <v-card-title class="text-h6">
                    得意先コードを登録します
                  </v-card-title>
                  <v-card-text class="text-h6">得意先コード:  {{ customercd }} </v-card-text>
                  <v-card-text>一度登録すると、修正はできません。<br />得意先コードに間違いがないか再度ご確認ください。</v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="dialog = false">キャンセル</v-btn>
                    <v-btn color="blue darken-4" outlined @click="submit()" :disabled="invalid">登録する</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </validation-provider>

          </validation-observer>
        </form>
      </v-col>
      <v-col align-self="center" align="right">
        <v-btn
            class="mr-2 custom-transform-class text-none"
            outlined
            color="indigo"
            @click="downloadCSV()"
        >
          <v-icon class="mr-1">mdi-download</v-icon>
          CSVファイルDL
        </v-btn>
        <v-btn
            class="mr-2 custom-transform-class text-none"
            outlined
            color="indigo"
            @click="downloadTemplate()"
        >
          <v-icon class="mr-1">mdi-download</v-icon>
          申請テンプレートDL
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col lg="12" md="12" sm="12">
        <v-simple-table class="pa-5">
          <tbody>
          <tr v-for="item in subform" :key="item.id">
            <th width="20%">{{ item.name }}</th>
            <td>{{ item.value }}</td>
            <td align="right"><v-btn icon @click="doCopy(item.id, item.value)"><v-icon dark>mdi-content-copy</v-icon></v-btn></td>
            <td width="10%" align="left"><v-chip v-if="item.showcopy">
              Copied!
            </v-chip></td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import CustomerInfoForm from "@/forms/CustomerInfoForm";
import downloadutil from "@/util/download"
import {ValidationObserver, ValidationProvider} from "vee-validate";

export default {
  name: 'CustomerInfo',
  title: '得意先コード登録情報',
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    form: PartnerForm,
    subform: CustomerInfoForm,
  },
  data: () => ({
    customercd: '',
    dialog: false,
  }),
  methods: {
    doCopy(id, value) {
      this.$copyText(value)
      for (const d in this.subform) {
        if(id == this.subform[d]['id']) {
          this.subform[d]['showcopy'] = true
        } else {
          this.subform[d]['showcopy'] = false
        }
      }
    },
    confirm () {
      this.dialog = true
    },
    submit () {
      this.$refs.observer.validate()
      this.$emit('updateCustomerCd',this.customercd)
      this.dialog = false
    },
    getFileNames(obj) {
      let names = ""
      if ( obj ) {
        for (const item of obj) {
          names = names + item.name + " "
        }
      }
      return names
    },
    downloadCSV () {
      try {
        downloadutil.downloadCSV(this.subform, 'customer_' + this.form.id + '_' + this.form.company_name_kanji + '.csv')
      } catch(error) {
        console.log(error)
      }
    },
    async downloadTemplate() {
      try{
        const filename = "meviy partners用得意先CD登録申請書Ver.1.1.xlsx"
        await downloadutil.downloadTemplate(filename)
      } catch(error) {
        console.log(error)
      }
    },
  },
  async mounted () {
    this.customercd = this.form.customer_cd
    for (const d in this.subform) {
      this.subform[d]['showcopy'] = false
    }
  }
}
</script>
