/* eslint-disable no-console */
import {API} from 'aws-amplify'
import store from "@/store";
import apiutil from "@/util/apiutil"

export default {

  async getCmsApplications (param) {

    const token = await apiutil.getMepapiToken()

    let queryParameters= {}
    if (param['application_status']) {
      queryParameters['application_status'] = param['application_status']
    }
    if (param['application_type']) {
      queryParameters['application_type'] = param['application_type']
    }
    if (param['supplier_id']) {
      queryParameters['supplier_id'] = param['supplier_id']
    }
    if (param['latest_edit_at']) {
      queryParameters['latest_edit_at'] = param['latest_edit_at']
    }

    const myInit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryParameters,
    };
    const res = await API.get('cms', '/api/v1/cmsApplications', myInit).catch((error) => {
      console.log(error)
    });

    return res
  },
  async processCmsApplication (item, action_type, action_reason) {

    let queryParameters= {}
    if (store.state.user.attributes.sub) {
      queryParameters['action_user_cognito_sub'] = store.state.user.attributes.sub
    }
    if (item['id']) {
      queryParameters['cms_application_id'] = item['id']
    }
    if (action_type) {
      queryParameters['action_type'] = action_type
    }
    if (item['application_type']) {
      if(item['application_type'].indexOf("partner_page") == 0) {
        queryParameters['application_type'] = "partner_page"
      }else if(item['application_type'].indexOf("service_card") == 0) {
        queryParameters['application_type'] = "service_card"
      }
    }
    if (item['cms_partner_page_id']) {
      queryParameters['cms_partner_page_id'] = item['cms_partner_page_id']
    }
    if (item['cms_partner_page_content_id']) {
      queryParameters['cms_partner_page_content_id'] = item['cms_partner_page_content_id']
    }
    if (item['cms_service_card_id']) {
      queryParameters['cms_service_card_id'] = item['cms_service_card_id']
    }
    if (item['cms_service_card_content_id']) {
      queryParameters['cms_service_card_content_id'] = item['cms_service_card_content_id']
    }
    if(action_reason) {
      const action_reason_rn = action_reason.replace("\n", "<br/>")
      queryParameters['action_reason'] = action_reason_rn
    }

    const token = await apiutil.getMepapiToken()
    const myInit = {
      headers: {
        Authorization: token,
      },
      body: queryParameters,
    };
    var resstatus = ""
    await API.post('cms', '/api/v1/cmsApplications', myInit).then(response => {
      if(response.message == "success") {
        resstatus = 200
      }
    }).catch((error) => {
      resstatus = error.response.status
    });
    return resstatus
  },
}