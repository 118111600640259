<script> 
  export default { 
    data: () => ({
      supplier_cd: {id:'supplier_cd', name:'', value: '', hint:'', showcopy:false},
      company_name_kanji : {id:'company_name_kanji', name:'', value: '', hint:'', showcopy:false},
      company_name_kana : {id:'company_name_kana', name:'', value: '', hint:'', showcopy:false},
      zip_cd : {id:'zip_cd', name:'', value: '', hint:'', showcopy:false},
      address_1_kanji : {id:'address_1_kanji', name:'', value: '', hint:'', showcopy:false},
      address_2_kanji : {id:'address_2_kanji', name:'', value: '', hint:'', showcopy:false},
      address_1_kana : {id:'address_1_kana', name:'', value: '', hint:'', showcopy:false},
      address_2_kana : {id:'address_2_kana', name:'', value: '', hint:'', showcopy:false},
      tel : {id:'tel', name:'', value: '', hint:'', showcopy:false},
      fax : {id:'fax', name:'', value: '', hint:'', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'', value: '', hint:'', showcopy:false},
      person_name_kana : {id:'person_name_kana', name:'', value: '', hint:'', showcopy:false},
      person_email : {id:'person_email', name:'', value: '', hint:'', showcopy:false},
      mep_servicedesk_orgid : {id:'mep_servicedesk_orgid', name:'', value: '', hint:'', showcopy:false},
    }),
  }
</script>