import axios from "axios";

export default {
    makeMepapiEndPoint(apiname) {
        const beapi_domain = process.env.VUE_APP_PLATFORM_BEAPI_DOMAIN;
        const apiHost = apiname + "." + beapi_domain
        const apiEndpoint = "https://" + apiHost

        return apiEndpoint
    },

    async getMepapiToken(){

        var url_token = process.env.VUE_APP_MEPOPUSERPOOL_TOKEN_ENDPOINT
        var clientstr = process.env.VUE_APP_MEPOPUSERPOOL_CLIENT_ID + ":" + process.env.VUE_APP_MEPOPUSERPOOL_CLIENT_SECRET
        var basic_auth_str = btoa(clientstr)
        var headers = {"Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control":"no-cache",
            "Authorization": "Basic " + basic_auth_str,
        }

        basic_auth_str = btoa(clientstr)
        headers = {"Content-Type": "application/x-www-form-urlencoded",
            "Cache-Control":"no-cache",
            "Authorization": "Basic " + basic_auth_str,
        }

        // Token取得
        try{
            const response = await axios.post(url_token, "grant_type=client_credentials",{
                headers: headers
            })
                .catch((error) => {
                    console.log(error)
                })
            if (response && (200 == response.status)) {
                const access_token= response.data['access_token']
                const token_type= response.data['token_type']

                return token_type + " " + access_token
            }
            return null

        } catch(error) {
            console.log("token exception:")
            console.log(error)
        }
    },
}