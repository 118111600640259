<script> 
  export default { 
    data: () => ({
      id: {id:'id', name:'', value: '', hint:''},
      supplier_cd: {id:'supplier_cd', name:'', value: '', hint:''},
      company_name_kanji : {id:'company_name_kanji', name:'仕入先名', value: '', hint:'（漢字）'},
      company_name_kana : {id:'company_name_kana', name:'', value: '', hint:'（半角カナ30文字）'},
      buka_name_kanji : {id:'buka_name_kanji', name:'', value: '', hint:'（漢字15文字）'},
      buka_name_kana : {id:'buka_name_kana', name:'', value: '', hint:'（半角カナ30文字）'},
      person_name_kanji : {id:'person_name_kanji', name:'', value: '', hint:'（漢字10文字）'},
      person_name_kana : {id:'person_name_kana', name:'', value: '', hint:'（半角カナ20文字）'},
      zip_cd : {id:'zip_cd', name:'', value: '', hint:'（ハイフン含む8桁）'},
      address_1_kanji : {id:'address_1_kanji', name:'', value: '', hint:'（漢字15文字）'},
      address_2_kanji : {id:'address_2_kanji', name:'', value: '', hint:'（漢字15文字）'},
      address_1_kana : {id:'address_1_kana', name:'', value: '', hint:'（半角カナ30文字）'},
      address_2_kana : {id:'address_2_kana', name:'', value: '', hint:'（半角カナ30文字）'},
      tel : {id:'tel', name:'', value: '', hint:'（ハイフン含む20文字）'},
      fax : {id:'fax', name:'', value: '', hint:'（ハイフン含む20文字）'},
      invoice_no : {id:'invoice_no', name:'', value: '', hint:'（半角数字13文字）'},
    }),
  }
</script>
