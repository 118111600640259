/* eslint-disable no-console */
import { API, graphqlOperation } from 'aws-amplify'

export default {
  async getReleases () {
    const listPartnerCandidate = `query getReleaseNotes {
      getReleaseNotes {
        versions {
          tickets {
            id
            title
          }
          date
          version
        }
      }
    }`
    const params = {
      operation: "ADMIN"
    }
    const partnercandidate = await API.graphql(graphqlOperation(listPartnerCandidate, params))
    return partnercandidate.data.getReleaseNotes.versions

  }
}