<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>
      <v-card flat>
        <v-card-title>
          パートナー登録申請一覧
          <v-spacer></v-spacer>
          <template>
            <v-row class="mt-3" align="center">
              <template>
                <v-autocomplete
                    dense
                    v-model="filter_status"
                    outlined placeholder="ステータス" clearable
                    :items="allstatus" item-value="item" item-text="text">
                </v-autocomplete>
                <v-spacer></v-spacer>
                <v-autocomplete
                    dense
                    v-model="filter_assignee"
                    outlined placeholder="ミスミ担当者" clearable
                    :items="allassignees" item-value="assignee_name" item-text="assignee_name">
                </v-autocomplete>

                <v-menu
                    v-model="fdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                        clearable
                        v-model="filter_fdate"
                        label="申請日From"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="filter_fdate"
                      @input="fdatemenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-menu
                    v-model="tdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                        clearable
                        v-model="filter_tdate"
                        label="申請日To"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="filter_tdate"
                      @input="tdatemenu = false"
                  ></v-date-picker>
                </v-menu>

                <v-spacer></v-spacer>
                <v-text-field
                    dense
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                ></v-text-field>
              </template>
            </v-row>
          </template>
        </v-card-title>
        <v-data-table
            v-if="candidateHeaders"
          :headers="headers"
          :items="candidateHeaders"
          :items-per-page="20"
          :search="search"
          :sort-by="['opened_datetime']"
          :sort-desc="true"
          class="elevation-1"
          item-key="id"
        >
          <template
              v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
              v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.status="{ item }">
              {{ $t(item.status) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn @click="viewcandidate(item.id)">
              確認
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import candidateService from '../services/candidateService'
import { mdiDeleteCircleOutline, mdiCloudDownloadOutline } from '@mdi/js'
import i18n from "@/i18n";
import moment from 'moment'

export default {
  name: 'candidate',
  title: 'パートナー登録申請一覧',
  data () {
    return {
      email: this.$store.state.user.attributes.email,
      search: '',
      filter_status:'',
      filter_assignee:'',
      allassignees: [],
      allstatus: [],
      filter_fdate: '',
      filter_tdate: '',
      fdatemenu: false,
      tdatemenu: false,
      resCandidates: '',
      headers: null,
      candidateHeaders: null,
      icons: {
        mdiDeleteCircleOutline,
        mdiCloudDownloadOutline,
      },
    }
  },
  methods: {
    viewcandidate(val) {
      this.$router.push({ name: "viewcandidate" , params: {id : val}}).catch(() => {});
    },
    statusFilter(value) {
      if (!this.filter_status) {
        return true
      }
      return value === this.filter_status
    },
    assigneeFilter(value) {
      if (!this.filter_assignee) {
        return true
      }
      return value === this.filter_assignee
    },

    dateFilter(value) {
      if (!this.filter_fdate && !this.filter_tdate) {
        return true
      }
      if(value) {
        var fboolean = true
        var tboolean = true
        const itemdate = moment.utc(value)
        if (this.filter_fdate) {
          const fdate = moment(this.filter_fdate + " 00:00:00")
          if(fdate.isAfter(itemdate)) {
            fboolean = false
          }
        }
        if (this.filter_tdate) {
          const tdate = moment(this.filter_tdate + " 00:00:00").add(1, 'd')
          if(tdate.isBefore(itemdate)) {
            tboolean = false
          }
        }
        return fboolean && tboolean
      } else {
        return false
      }
    },

    getAllAssignee () {
      let assigneelist = []
      const filterallassignee = this.candidateHeaders.filter((v1, i1, a1) => {
        return a1.findIndex(v => v1.assignee_name === v.assignee_name) === i1
      })
      for (const filterassignee in filterallassignee) {
        if(filterallassignee[filterassignee]["assignee_name"]) {
          const assigneeitem = {assignee_name: filterallassignee[filterassignee]["assignee_name"]}
          assigneelist.push(assigneeitem)
        }
      }
      this.allassignees = assigneelist
    },
    getAllStatus () {
      let statuslist = []
      const filterallstatus = this.candidateHeaders.filter((v1, i1, a1) => {
        return a1.findIndex(v => v1.status === v.status) === i1
      })
      for (const filterstatus in filterallstatus) {
        if(filterallstatus[filterstatus]["status"]) {
          const statusitem = {item: filterallstatus[filterstatus]["status"], text: i18n.t(filterallstatus[filterstatus]["status"])}
          statuslist.push(statusitem)
        }
      }
      this.allstatus = statuslist
    },
  },
  async mounted () {
    this.search = ''
    this.headers = [
      { text: '受付番号', value: 'id' },
      { text: 'ステータス', value: 'status', filter: this.statusFilter },
      { text: '会社名', value: 'company_name_kanji'},
      { text: '仕入先コード', value: 'supplier_cd'},
      { text: '得意先コード', value: 'customer_cd'},
      { text: '申請者名', value: 'regist_user_name'},
      { text: '申請日時', value: 'opened_datetime', formatter: candidateService.datetimeFormatter, filter: this.dateFilter },
      { text: 'ミスミ対応者', value: 'assignee_name', filter: this.assigneeFilter},
      { text: '完了日時', value: 'complete_datetime'},
      { text: '経過時間', value: 'complete_elapsed_seconds', formatter: candidateService.secondsToDatetimeFormatter },
      { text: '', value: 'actions' },
    ]
    this.candidateHeaders = await candidateService.getPartnerCandidateHeader()
    this.getAllStatus()
    this.getAllAssignee()
  }
}
</script>