<template>
  <v-container>
    <v-row dense>
      <v-col cols="4" class="text-h6 pt-5" align-self="center">PROSUGATE登録情報</v-col>
      <v-col cols="1" class="pt-5" align-self="center"><v-chip v-if="this.form.prosugate_status" color="success" dark class="ma-0" small>{{ $t(this.form.prosugate_status) }}</v-chip></v-col>
      <v-col class="pt-5">
        <v-btn
            v-if="this.form.status == 'processing' && this.form.supplier_cd && this.form.prosugate_status != 'done'"
            elevation="5"
            color="orange"
            class="white--text"
            :loading="form.prosugate_loading"
            :disabled="form.prosugate_loading"
            @click="updateProsugateProcess('done')"
        >
          処理完了
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-spacer></v-spacer>
      <v-btn
          class="custom-transform-class text-none"
          outlined
          color="indigo"
          @click="downloadCSV()"
      >
        <v-icon class="mr-1">mdi-download</v-icon>
        CSVファイルDL
      </v-btn>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" sm="12">
        <v-simple-table class="pa-5">
          <tbody>
          <tr v-for="item in subform" :key="item.id">
            <th width="20%">{{ item.name }}</th>
            <td>{{ item.value }}</td>
            <td align="right"><v-btn icon @click="doCopy(item.id, item.value)"><v-icon dark>mdi-content-copy</v-icon></v-btn></td>
            <td width="10%" align="left"><v-chip v-if="item.showcopy">
              Copied!
            </v-chip></td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import ProsugateInfoForm from "../forms/ProsugateInfoForm";
import downloadutil from "@/util/download"

export default {
  name: 'ProsugateInfo',
  title: 'Prosugate登録情報',
  components: {
  },
  props: {
    form: PartnerForm,
    subform: ProsugateInfoForm,
  },
  data: () => ({
    checkbox: false,
    ZenSP: "　",
  }),
  methods: {
    doCopy(id, value) {
      this.$copyText(value)
      for (const d in this.subform) {
        if(id == this.subform[d]['id']) {
          this.subform[d]['showcopy'] = true
        } else {
          this.subform[d]['showcopy'] = false
        }
      }
    },
    async updateProsugateProcess(prosugatestatus) {
      await this.$emit('updateProsugateProcess',prosugatestatus)
    },
    getFileNames(obj) {
      let names = ""
      if ( obj ) {
        for (const item of obj) {
          names = names + item.name + " "
        }
      }
      return names
    },
    downloadCSV () {
      try {
        downloadutil.downloadCSV(this.subform, this.form.id + '_' + this.form.company_name_kanji + '.csv')
      } catch(error) {
        console.log(error)
      }
    },
  },
  async mounted () {
    for (const d in this.subform) {
      this.subform[d]['showcopy'] = false
    }
  }
}
</script>
