/* eslint-disable no-console */
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/ja';
import awsconfig from './aws-exports';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import { components } from 'aws-amplify-vue'
import vuetify from '@/plugins/vuetify' // path to vuetify export
import axios from 'axios'
import VueAxios from 'vue-axios'
import { I18n } from "aws-amplify";
import { Translations } from "@aws-amplify/ui-components";
import ja from "@aws-amplify-jp/vocabulary-ja";
import  { cognito_messages } from '@/messages/CognitoMessage'
import VueClipboard from 'vue-clipboard2'
import i18n from './i18n'
import titleMixin from '@/util/title'
import apiutil from '@/util/apiutil'

Amplify.configure(awsconfig);

// 既存mepop-Appsyncの設定を追加
const myAppConfig = {
    'aws_appsync_graphqlEndpoint': process.env.VUE_APP_APPSYNC_ENDPOINT,
    'aws_appsync_region': process.env.VUE_APP_APPSYNC_REGION,
    'aws_appsync_authenticationType': process.env.VUE_APP_APPSYNC_AUTHENTICATIONTYPE,
    API: {
        endpoints: [
            {
                name: 'mail',
                endpoint:apiutil.makeMepapiEndPoint('mail'),
            },
            {
                name: 'cms',
                endpoint: apiutil.makeMepapiEndPoint('cms'),
            },
            {

                name: 'userpooluseroperation',
                endpoint: apiutil.makeMepapiEndPoint('mepop-user'),
            },
            {
                name: 'feecalc',
                endpoint: apiutil.makeMepapiEndPoint('feecalc'),
            },
            {
                name: 'pdf-creator',
                endpoint: apiutil.makeMepapiEndPoint('pdf-creator'),
            },
        ],
    },
}

Amplify.configure(myAppConfig);

Vue.use(AmplifyPlugin, AmplifyModules);
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(VueAxios, axios)
Vue.use(VueClipboard)

I18n.putVocabulariesForLanguage("ja-JP", ja(Translations));
I18n.setLanguage('ja-JP');
I18n.putVocabularies(cognito_messages);

Vue.mixin(titleMixin)

new Vue({
  vuetify,
  router,
  store,
  components: {
    App,
    ...components
  },
  i18n,
  render: h => h(App)
}).$mount('#app')

