<template>
    <v-menu :offset-x='true' :offset-y='false' :open-on-hover='false'>
      <template v-slot:activator="{ on }">
        <v-list-item v-on="on">
            <v-list-item-icon>
                <v-icon>{{ navIcon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ navName }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </template>
      <v-list dense>
        <v-list-item v-for="(item, index) in menuItems" :key='index' :to="item.linkto" @click='emitClickEvent(item)'>
            <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
  export default {
    name: 'NestedMenu',
    props: {
        navName: String,
        navIcon: String,
        menuItems: Array
    },
    methods: {
        emitClickEvent (item) {
            this.$emit('nested-menu-click', item)
        }
    }
  }
</script>
