import Vue from 'vue';
import {
    ValidationProvider,
    localize,
    extend
} from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
import ja from 'vee-validate/dist/locale/ja.json';
localize('ja', ja);
import {digits, max, oneOf, regex, email, confirmed, required, ext} from 'vee-validate/dist/rules';
import { formInputRegex } from '@/rules/validation'

// カスタムルールの設定
const kanaallpattern = new RegExp( "^[" + formInputRegex.ZenHiraKata
    + formInputRegex.HanAlphaDigit
    + formInputRegex.ZenAlphaDigit
    + formInputRegex.HanSymbolSpace
    + formInputRegex.ZenSymbolSpace + "]*$")

const zipcdpattern = new RegExp( "^[" + formInputRegex.ZipcdJp + "]*$")

const telpattern = new RegExp( "^[" + formInputRegex.TelJp + "]*$")

const banknamepattern = new RegExp( "^[" + formInputRegex.HanKata + formInputRegex.BankAccountNameJp + "]*$")


// 必須チェック
const kanaall = {
    validate(value) {
        if (value.match(kanaallpattern)) {
            return true;
        } else {
            return false;
        }
    },
    message(field){
        return `${field}はひらがな、カタカナ、英数記号で入力してください`
    },
}
const kanamax = {
    params: ['max'],
    validate(value, { max }) {
        //計算用にひらがなをカタカナ変換→カタカナを半角変換
        let checkval = hiraToKana(value)
        checkval = zenkana2Hankana(checkval)
        if(checkval.length <= max) {
            return true;
        }
    },
    message(field, { max }){
        return `${field}は ${max} 文字までで入力してください。濁点・半濁点（「゛」「゜」）も1文字としてカウントされます`
    },
}
const zipcdjp = {
    validate(value) {
        if (value.match(zipcdpattern)) {
            return true;
        }
    },
    message(field){
        return `${field}の入力形式が不正です。「0000000」または「000-0000」の形式でご入力ください`
    },
}

const telfaxjp = {
    validate(value) {
        if (value.match(telpattern)) {
            return true;
        }
    },
    message(field){
        return `${field}の入力形式が不正です。市外局番・市内局番・加入者番号をハイフンで区切り正しくご入力ください`
    },
}

const bankaccountnamejp = {
    validate(value) {
        if (value.match(banknamepattern)) {
            return true;
        }
    },
    message(field){
        return `${field}の入力形式が不正です。半角カナ、半角数字、半角アルファベット大文字、半角記号【().^/のいずれか】でご入力ください`
    },
}

extend('ext', {
    ...ext,
    message: '拡張子に誤りがあります',
})

extend('required', {
    ...required,
    message: '{_field_} は必須入力項目です',
})

extend('max', {
    ...max,
    message: '{_field_} は最大 {length} 文字までです',
})

extend('regex', {
    ...regex,
    message: '{_field_} の入力形式に誤りがあります。入力例を参考にしてください',
})

extend('digits', {
    ...digits,
    message: '{_field_} は半角数値  {length}  桁で入力してください',
})

extend('oneOf', {
    ...oneOf,
    message: '{_field_} はいずれかを選んでください',
})

extend('confirmed', {
    ...confirmed,
    message: '入力値が一致しません。内容をご確認ください',
})

extend('email', {
    ...email,
    message: 'メールアドレスの形式が不正です。入力値をご確認ください',
})

function hiraToKana(str) {
    return str.replace(/[\u3041-\u3096]/g, function(match) {
        var chr = match.charCodeAt(0) + 0x60;
        return String.fromCharCode(chr);
    });
}
/*
function zenAlphaDigit2hanAlphaDigit(str) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
}
}
 */
function zenkana2Hankana(str) {
    var kanaMap = {
        "ガ": "ｶﾞ", "ギ": "ｷﾞ", "グ": "ｸﾞ", "ゲ": "ｹﾞ", "ゴ": "ｺﾞ",
        "ザ": "ｻﾞ", "ジ": "ｼﾞ", "ズ": "ｽﾞ", "ゼ": "ｾﾞ", "ゾ": "ｿﾞ",
        "ダ": "ﾀﾞ", "ヂ": "ﾁﾞ", "ヅ": "ﾂﾞ", "デ": "ﾃﾞ", "ド": "ﾄﾞ",
        "バ": "ﾊﾞ", "ビ": "ﾋﾞ", "ブ": "ﾌﾞ", "ベ": "ﾍﾞ", "ボ": "ﾎﾞ",
        "パ": "ﾊﾟ", "ピ": "ﾋﾟ", "プ": "ﾌﾟ", "ペ": "ﾍﾟ", "ポ": "ﾎﾟ",
        "ヴ": "ｳﾞ", "ヷ": "ﾜﾞ", "ヺ": "ｦﾞ",
        "ア": "ｱ", "イ": "ｲ", "ウ": "ｳ", "エ": "ｴ", "オ": "ｵ",
        "カ": "ｶ", "キ": "ｷ", "ク": "ｸ", "ケ": "ｹ", "コ": "ｺ",
        "サ": "ｻ", "シ": "ｼ", "ス": "ｽ", "セ": "ｾ", "ソ": "ｿ",
        "タ": "ﾀ", "チ": "ﾁ", "ツ": "ﾂ", "テ": "ﾃ", "ト": "ﾄ",
        "ナ": "ﾅ", "ニ": "ﾆ", "ヌ": "ﾇ", "ネ": "ﾈ", "ノ": "ﾉ",
        "ハ": "ﾊ", "ヒ": "ﾋ", "フ": "ﾌ", "ヘ": "ﾍ", "ホ": "ﾎ",
        "マ": "ﾏ", "ミ": "ﾐ", "ム": "ﾑ", "メ": "ﾒ", "モ": "ﾓ",
        "ヤ": "ﾔ", "ユ": "ﾕ", "ヨ": "ﾖ",
        "ラ": "ﾗ", "リ": "ﾘ", "ル": "ﾙ", "レ": "ﾚ", "ロ": "ﾛ",
        "ワ": "ﾜ", "ヲ": "ｦ", "ン": "ﾝ",
        "ァ": "ｧ", "ィ": "ｨ", "ゥ": "ｩ", "ェ": "ｪ", "ォ": "ｫ",
        "ッ": "ｯ", "ャ": "ｬ", "ュ": "ｭ", "ョ": "ｮ",
        "。": "｡", "、": "､", "ー": "ｰ", "「": "｢", "」": "｣", "・": "･"
    }
    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    return str
        .replace(reg, function (match) {
            return kanaMap[match];
        })
        .replace(/゛/g, 'ﾞ')
        .replace(/゜/g, 'ﾟ');
}

// 定義
export {
    kanaall, kanamax, zipcdjp, telfaxjp, bankaccountnamejp, ext, required, email, confirmed, oneOf,
}
