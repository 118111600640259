<template>

  <v-row class="text-center" justify="center">

    <div class="signin">
      <div v-if="!signedIn">
        <amplify-authenticator v-bind:authConfig="authConfig"></amplify-authenticator>
      </div>
      <div v-if="signedIn">
        <amplify-sign-out></amplify-sign-out>
      </div>
    </div>
  </v-row>

</template>
<script>
/* eslint-disable no-console */
import { AmplifyEventBus } from 'aws-amplify-vue'
import { Auth } from 'aws-amplify'

export default {
  name: 'SignIn',
  data () {
    return {
      signedIn: false,
      authConfig: {
        signInConfig: {
          isSignUpDisplayed: false
        }
      }
    }
  },
  async beforeCreate() {
    try {
      await Auth.currentAuthenticatedUser()
      this.signedIn = true
    } catch (err) {
      this.signedIn = false
    }
    AmplifyEventBus.$on('authState', info => {
      if (info === 'signedIn') {
        this.signedIn = true
      } else {
        this.signedIn = false
      }
    })
  }
}
</script>
<style>
.signin {
  margin: 20px;
}
input {
  font-size: 16px !important;
}
@import '../assets/cognito.css';
@media screen and (max-width: 480px) {
  .signin div {
    min-width: 0;
  }
}
</style>