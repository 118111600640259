<template>
  <v-dialog
    v-model="helperParam.helper_dialog"
    width="70%"
  >
  <v-card flat :height="dialog_height" class="d-flex flex-column">
    <v-card-title class="text-h5 grey lighten-2">
      手数料明細書の作り方
    </v-card-title>
    <template>
      <v-stepper class="elevation-0" v-model="helperParam.stepper_step">
        <v-stepper-header>
          <v-stepper-step :complete="helperParam.stepper_step > 1" step="1">
            月次取引履歴の見方
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="helperParam.stepper_step > 2" step="2">
            手数料請求パートナーの設定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="helperParam.stepper_step > 3" step="3">
            変キャン情報の設定
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="helperParam.stepper_step > 4" step="4">
            明細データの作成
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="5">
            明細書エクセル/PDFの確認
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content step="1">
            <v-card 
              flat
              class="mb-5"
            >
              <v-card-title class="text-h6">月次取引履歴の見方</v-card-title>
              <ul class="text-subtitle">
                <li>「月次取引履歴」画面では、受注時に発行されたグローバルNo（「受注時グローバルNo」）でグルーピングされた単位で表示されます。</li>
                <li>1つの受注時グローバルNoグループには、1つ以上のグローバルNoグループ（同じグローバルNoを持つ履歴のグループ）が入ります。</li>
                <ul><li>単価修正+-、仕入れ追加、キャン再などで受注時とは異なるグローバルNoが発行されている場合、1つの受注時グローバルNoグループに複数のグローバルNoが入ります。</li></ul>
                <li><span class="font-weight-black pink lighten-4">異なるグローバルNoを特定の受注時グローバルNoグループに含めるには、「受注時グローバルNo」を設定します。</span></li>
                <li>各受注時グローバルNoグループの先頭行が、受注時に作成された履歴です。</li>
                <li>各受注時グローバルNoグループの2行目以降は、変キャンによって作成された履歴です。<span class="font-weight-black pink lighten-4">2行目以降には「変キャン区分」「変キャン起因」を設定します。</span></li>

              </ul>
              <v-img class="ma-5"
                v-bind:src="require('@/assets/fee_statement_transaction_history_image.png')"
              ></v-img>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="2">
            <v-card 
              flat
              class="mb-5"
            >
              <v-card-title class="text-h6">手数料請求パートナーの設定</v-card-title>
              <ul class="text-subtitle">
                <li>「パートナー一覧」画面で、当月の手数料請求対象パートナーに「手数料請求対象」が正しく「対象」と設定されているか確認してください</li>
                <li>「手数料請求対象」を修正したい場合、「対象にする」または「対象外にする」ボタンを押下してください</li>
              </ul>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="3">
            <v-card 
              flat
              class="mb-5"
            >
              <v-card-title class="text-h6">対象パートナー/月度の変キャン伝票の受注時グローバルNoへの紐づけ、変キャン情報の設定</v-card-title>
              <ul class="text-subtitle">
                <li>「月次取引履歴」画面の「月次取引履歴」タブで、対象のパートナー、月度を指定し取引履歴の一覧を表示します</li>
                  <ul><li>手数料請求対象となっているパートナーの取引履歴のみが検索できます</li></ul>
                <li>JIRAで連絡を受けた変更・キャンセル伝票について、変キャン区分・起因を設定します</li>
                <li>新規にグローバルNoを発行して対応したもの（キャン再、単価修正、仕入れ追加など）については、変更後の取引履歴レコードに対して、受注時のグローバルNoを「受注時グローバルNo」としてセットします</li>
              </ul>
              <v-alert dense outlined type="error" class="ma-5">
                「受注時グローバルNo未設定明細」について
                <ul>
                  <li>イレギュラー対応などで受注時とは異なるグローバルNoが発行されている場合、各イレギュラー対応用の明細に受注時のグローバルNoを設定した上で、必ず変キャン区分・起因を設定してください</li>
                  <li>受注時グローバルNo未設定明細の一覧には、期間指定を問わず全件出力します</li>
                </ul>
              </v-alert>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4">
            <v-card 
              flat
              class="mb-5"
            >
              <v-card-title>対象パートナー、対象月度の手数料明細データ作成</v-card-title>
              <ul>
                <li>「月次取引履歴」画面の「月次取引履歴」タブで、「チェックした明細で明細データ作成」ボタンを押すと、明細データの作成とエクセル・PDFファイルの作成が開始されます</li>
                <li>PDF作成には時間がかかることがあります。画面上部の「手数料明細書一覧」ボタンを押し、「手数料明細一覧」画面でPDFステータスを確認できます</li>
              </ul>
              <v-alert dense outlined type="error" class="ma-5">
                  注意点
                  <ul>
                    <li>変更・キャンセル情報が設定されていない変キャン明細は明細データに含めることができません</li>
                    <li>まだ明細データが作られていない明細に限り、出力チェックボックスをONにして明細データ作成ボタンを押すと明細データを作成します</li>
                    <li>明細の追加や注文時グローバルNoの再設定などで、一度作成した明細データを作り直したい場合、手数料明細書一覧画面から作成済みの明細データを取り下げ、改めて明細データの再作成を行ってください</li>
                  </ul>
              </v-alert>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="5">
            <v-card 
              flat
              class="mb-5"
            >
              <v-card-title>手数料明細書エクセル、PDFのDL</v-card-title>
              <ul>
                <li>「手数料明細一覧」画面で明細データ作成済みの一覧が表示されます</li>
                <li>作成した明細データのPDFステータスを確認し、作成済であればPDFアイコン・エクセルアイコンをクリックしてファイルをDLしてください</li>
              </ul>
              <v-alert dense outlined type="error" class="ma-5">
                  明細データの修正が必要な場合
                  <ul>
                    <li>変キャン情報の再設定などが必要な明細に関しては、該当の明細書の「取り下げ」処理をした上で、「取引履歴」画面から情報を修正し、明細データの再作成を行ってください</li>
                    <li>伝票の作成タイミングが入れ違ってしまったなど、画面上からはどうしようもない特別なケースについては、「手数料明細一覧」画面から該当明細書のエクセルデータをDLして直接編集した上で、履歴管理用に編集後のエクセル・PDFをアップロードしてください</li>
                  </ul>
              </v-alert>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
    <v-spacer></v-spacer>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="helperParam.stepper_step > 1"
            color="primary"
            @click="goback"
          >戻る</v-btn>
        <v-btn
          v-if="helperParam.stepper_step == 5"
            color="primary"
            @click="gofirst"
          >最初へ戻る</v-btn>
        <v-btn
          v-else
            color="primary"
            @click="gonext"
          >次へ</v-btn>
        <v-btn
          color="primary"
          text
          @click="helperParam.helper_dialog = false"
        >閉じる
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>


<script>

export default {
  name: 'FeeCalculateHelper',
  title: '手数料明細書の作り方',
  data () {
    return {
      dialog_height: 1200,
    }
  },
  props: {
      helperParam: Object,
  },
  methods: {
    arrangeHeights() {
      if (this.helperParam.stepper_step == 1) {
        this.dialog_height = 1200
      } else {
        this.dialog_height = 650
      }
    },
    gofirst() {
        this.helperParam.stepper_step = 1
        this.arrangeHeights()
    },
    gonext() {
        this.helperParam.stepper_step = this.helperParam.stepper_step + 1
        this.arrangeHeights()
    },
    goback() {
      this.helperParam.stepper_step = this.helperParam.stepper_step - 1
      this.arrangeHeights()
    }
  },
}

</script>