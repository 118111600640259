<template>
  <v-container>
  <v-row dense>
    <v-col cols="3" class="text-h6 pt-5" align-self="center">口座登録情報</v-col>
    <v-col cols="1" class="pt-5" align-self="center"><v-chip v-if="this.form.bank_status" color="success" dark class="ma-0" small>{{ $t(this.form.bank_status) }}</v-chip></v-col>
    <v-col class="pt-5">
      <v-btn
          v-if="this.form.status == 'processing' && this.form.bank_status != 'done'"
          elevation="5"
          color="orange"
          class="ma-2 white--text"
          :loading="form.bank_loading"
          :disabled="form.bank_loading"
          @click="updateBankProcess('done')"
      >
        処理完了
      </v-btn>
    </v-col>
  </v-row>
    <v-row dense>
      <v-spacer></v-spacer>
      <v-btn color="blue-grey lighten-4" href="https://misumig.sharepoint.com/sites/BPR/Concur/JP/Lists/List/Application.aspx" target="_blank" class="ma-2 mt-2">登録サイト<v-icon>mdi-open-in-new</v-icon></v-btn>
      <v-btn
          class="mr-2 mt-2 custom-transform-class text-none"
          outlined
          color="indigo"
          @click="downloadCSV()"
      >
        <v-icon class="mr-1">mdi-download</v-icon>
        CSVファイルDL
      </v-btn>
  </v-row>
    <v-row dense>
      <v-col lg="12" md="12" sm="12">
        <v-simple-table class="pa-5">
          <tbody>
          <tr><th>添付ファイル</th><td colspan="3">
              <v-btn
                  v-for="(item) of form.bank_attachment_filename"
                  :key="item.id"
                  class="mr-2 mt-2 custom-transform-class text-none"
                  outlined
                  color="indigo"
                  @click="downloadAttachment(item.id, item.name)"
              >
                <v-icon class="mr-1" small>mdi-file-table-outline</v-icon>
                {{item.name}}
              </v-btn> <br/>
            </td></tr>
            <tr v-for="item in subform" :key="item.id">
              <th width="20%">{{ item.name }}</th>
              <td>{{ item.value }}</td>
              <td align="right"><v-btn icon @click="doCopy(item.id, item.value)"><v-icon dark>mdi-content-copy</v-icon></v-btn></td>
              <td width="10%" align="left"><v-chip v-if="item.showcopy">
                Copied!
              </v-chip></td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import BankInfoForm from "@/forms/BankInfoForm";
import candidateService from "@/services/candidateService";
import axios from 'axios'
import { saveAs } from 'file-saver';
import downloadutil from "@/util/download"

export default {
  name: 'BankInfo',
  title: '口座登録情報',
  components: {
  },
  props: {
    form: PartnerForm,
    subform: BankInfoForm,
  },
  data: () => ({
    checkbox: false,
  }),
  methods: {
    doCopy(id, value) {
      this.$copyText(value)
      for (const d in this.subform) {
        if(id == this.subform[d]['id']) {
          this.subform[d]['showcopy'] = true
        } else {
          this.subform[d]['showcopy'] = false
        }
      }
    },
    getFileNames(obj) {
      let names = ""
      if ( obj ) {
        for (const item of obj) {
          names = names + item.name + " "
        }
      }
      return names
    },
    async downloadAttachment(fileid, filename) {

      try{
        const presignedurl= await candidateService.getAttachmentDownloadPresignedUrl(fileid)
        const response = await axios.get(presignedurl, {responseType:'blob'})
            .catch((error) => {
              console.log(error)
            })
        //const filename = presignedurl.match(".+/(.+?)([?#;].*)?$")[1];

        if (response && (200 == response.status)) {
          saveAs(response.data, filename)
        }

      } catch(error) {
        console.log(error)
      }
    },
    async updateBankProcess(bankstatus) {
      this.$emit('updateBankProcess',bankstatus)
    },
    downloadCSV () {
      try {
        downloadutil.downloadCSV(this.subform, 'bank_' + this.form.id + '_' + this.form.company_name_kanji + '.csv')
      } catch(error) {
        console.log(error)
      }
    },
  },
  async mounted () {
    for (const d in this.subform) {
      this.subform[d]['showcopy'] = false
    }
  }
}
</script>
