<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>

      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-text>
                <br/>{{ this.process_message }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <v-card flat width="100%" class="mb-2" id="pageTop">
        <v-card-title>
          手数料明細
          <v-btn-toggle
            v-model="selected_page"
            color="success"
            class="ml-5"
            dense
            group
          >
          <v-btn color="indigo darken-4" outlined value="partnerSearch" to="/partnerSearch" elevation="3" class="ml-10 font-weight-black">
              パートナー一覧
            </v-btn>
            <v-btn color="teal darken-4" outlined value="transactionhistory" class="ml-10 font-weight-black">
              月次取引履歴/明細検索
            </v-btn>
            <v-btn color="teal darken-4" outlined value="monthlyfeestatements" to="/monthlyfeestatements" elevation="3" class="font-weight-black">
              月次手数料明細書一覧
            </v-btn>
            <v-btn color="orange darken-4" outlined value="feestatements" to="/feestatements" elevation="3" class="ml-10 font-weight-black">
              手数料明細書検索
            </v-btn>
          </v-btn-toggle>
          <v-btn elevation="3" class="ml-10 font-weight-black orange--text text--darken-2 rounded-0" style="background-color:#fff" @click="uploadHelperParam.visible = true">
            仕入追加・単価修正+/-CSV登録
          </v-btn>
          <v-spacer></v-spacer>
            <v-fab-transition>
              <v-btn
                color="light-blue darken-4"
                fab
                large
                dark
                depressed
                @click="helperParam.helper_dialog = true"
                >
                <v-icon>mdi-help</v-icon>
              </v-btn>
            </v-fab-transition>
        </v-card-title>

        <FeeCalculateHelper :helperParam="helperParam" />
        
        <!-- CSVアップロードダイアログ -->
        <RegisterCsvDialog 
          :uploadHelperParam="uploadHelperParam"
          @onUploadCsvSuccess="onUploadCsvSuccess" />

        <v-alert dense outlined :type=this.process_message_type v-if="this.process_message">
          {{ this.process_message }}
        </v-alert>

        <v-card>
          <v-tabs
            background-color="green lighten-5"
          >
            <v-tab href="#tab-history">月次取引履歴</v-tab>
            <v-tab href="#tab-transaction">明細検索</v-tab>
            <v-tab href="#tab-unpublished">未出力明細検索</v-tab>

            <v-tab-item value="tab-history" class="pa-3 pb-0 mt-1">
                <v-row>
                  <v-col cols="3">
                    <v-select
                        dense
                        v-model="input_history_supplier_cd"
                        outlined placeholder="パートナー"
                        clearable
                        :items="suppliers"
                        item-value="supplier_cd"
                        :item-text="item => '[' + item.supplier_cd + ']' + item.company_name_kanji">
                    </v-select>
                  </v-col>
                  <v-col cols="2">
                    <v-menu
                        v-model="input_month_menu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dense
                            clearable
                            v-model="input_month"
                            label="月度"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                          v-model="input_month"
                          type="month"
                          @input="input_month_menu = false"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="2">
                    <v-btn :color=search_btn_color
                            :loading=search_loading
                            dark
                            class="ml-5"
                            @click="getTransactionHistory()">
                      <v-icon class="mr-1">mdi-database-search-outline</v-icon>
                      取引履歴表示
                    </v-btn>
                  </v-col>
                </v-row>
            </v-tab-item>

            <v-tab-item value="tab-transaction" class="pa-3 pb-0 mt-1">
              <v-row dense>
                <v-col cols="2" dense>
                  <v-select
                      dense
                      v-model="input_transaction_supplier_cd"
                      outlined placeholder="パートナー"
                      clearable
                      hide-details
                      :items="suppliers"
                      item-value="supplier_cd"
                      :item-text="item => '[' + item.supplier_cd + ']' + item.company_name_kanji">
                  </v-select>
                </v-col>
                <v-col cols="1" dense>
                  <v-menu
                      v-model="transaction_search_input_month_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          dense
                          clearable
                          v-model="input_month"
                          label="月度"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="input_month"
                        type="month"
                        @input="transaction_search_input_month_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="2" dense>
                  <v-menu
                      v-model="transaction_search_input_date_from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          dense
                          clearable
                          v-model="input_date_from"
                          label="NEWTON更新日From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="input_date_from"
                        @input="transaction_search_input_date_from_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="2" dense>
                  <v-menu
                      v-model="transaction_search_input_date_to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          dense
                          clearable
                          v-model="input_date_to"
                          label="NEWTON更新日To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="input_date_to"
                        @input="transaction_search_input_date_to_menu = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="2" dense>
                  <v-text-field
                      dense
                      v-model.trim="input_global_no"
                      outlined placeholder="グローバルNo."
                      hide-details
                      clearable>
                  </v-text-field>
                </v-col>
                <v-col cols="2" dense>
                  <v-text-field
                      dense
                      v-model.trim="input_product_part_no"
                      outlined placeholder="型番（先頭'%'は入力不要）"
                      hide-details
                      clearable>
                  </v-text-field>
                </v-col>
                <v-col cols="2" dense>
                  <v-text-field
                      dense
                      v-model.trim="input_statement_no"
                      outlined placeholder="明細書番号"
                      hide-details
                      clearable>
                  </v-text-field>
                </v-col>
                <v-col cols="2" dense>
                  <v-btn  :color=search_btn_color
                          :loading=search_loading
                          dark
                          class="ml-5 mb-2"
                          @click="getTransaction()">
                    <v-icon class="mr-1">mdi-database-search-outline</v-icon>
                    注文明細検索
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item value="tab-unpublished" class="pa-3 pb-0 mt-1">
              <v-row>
                <v-col cols="3">
                  <v-select
                      dense
                      v-model="input_unpublished_supplier_cd"
                      outlined placeholder="パートナー"
                      clearable
                      :items="suppliers"
                      item-value="supplier_cd"
                      :item-text="item => '[' + item.supplier_cd + ']' + item.company_name_kanji">
                  </v-select>
                </v-col>
                <v-col cols="2">
                  <v-btn  :color=search_btn_color
                          :loading=search_loading
                          @click="getUnpublished()"
                          dark
                          class="mx-auto">
                    <v-icon class="mr-1">mdi-database-search-outline</v-icon>
                    未出力明細検索
                  </v-btn>
                </v-col>
              </v-row>
            </v-tab-item>

          </v-tabs>
        </v-card>
      </v-card>

      <v-card flat width="100%" v-if="transaction_history_res">
        <v-dialog
            v-model="confirm_dialog"
            persistent
            max-width="800"
        >
          <v-card>
            <v-card-title class="text-h6">
              手数料明細データを作成します
            </v-card-title>
            <v-card-text>
              <v-simple-table class="pa-2">
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">パートナー</td><td class="pa-2">{{ transaction_history_res.supplier_cd }}</td></tr>
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">月度</td><td class="pa-2">{{ transaction_history_res.cover_date_from }} ～ {{ transaction_history_res.cover_date_to }}</td></tr>
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">履歴件数</td><td class="pa-2">{{ statement_data_selected.length }}</td></tr>
              </v-simple-table>
              <v-alert dense outlined type="error" v-if="statement_data_not_allowed_cnt > 0">
                変キャン情報が設定されていない履歴が残っています。このまま続行すると正しい手数料計算ができません。
              </v-alert>
              <v-alert dense outlined type="error" v-if="transaction_history_res.unconfirmed_total > 0">
                受注時グローバルNoが設定されていない履歴が残っています。このまま続行すると正しい手数料計算ができない可能性があります。
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="confirm_dialog=false">キャンセル</v-btn>
              <v-btn color="blue darken-4" outlined @click="setAndCreateStatementData(transaction_history_res.supplier_cd, transaction_history_res.cover_month);confirm_dialog=false;">実行する</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-card-title>
          <span class="font-weight-bold">{{ transaction_history_res.title }}</span>
          <div class="ml-6 text-subtitle-2">
              <span v-if="transaction_history_res.supplier_cd">
                [ 仕入先コード：<span class="font-weight-bold">{{ transaction_history_res.supplier_cd }}</span> ]
              </span>
              <span v-if="transaction_history_res.cover_date_from">
                [ 対象期間：<span class="font-weight-bold">{{ transaction_history_res.cover_date_from }} ～ {{ transaction_history_res.cover_date_to }}</span> ]
              </span>

              <div class="font-weight-bold">
                履歴件数：<font class="text-subtitle-1 font-weight-bold">{{ transaction_history_res.total }}</font>&nbsp;&nbsp;/ 
                変キャン情報未設定履歴件数：<font color="red" class="text-subtitle-1 font-weight-bold">{{ statement_data_not_allowed_cnt }}</font>&nbsp;&nbsp;/ 
                受注時グローバルNo未設定履歴件数：<font color="red" class="text-subtitle-1 font-weight-bold">{{ transaction_history_res.unconfirmed_total }}</font>
              </div>
              <div class="font-weight-bold">
                <span v-if="!this.can_publish_new_statement">
                  発行済明細書番号：
                  <span v-for="published_statement_no in transaction_history_res.published_statement_nos" :key="published_statement_no">
                    [ <router-link :to="`/feestatements?statement_no=${published_statement_no}`">{{ published_statement_no }}</router-link> ]
                  </span>
                </span>
              </div>
            </div>
          <v-spacer></v-spacer>
          <v-checkbox
            class="ml-5"
            v-model="show_only_unset_irregular_group"
            label="変キャン情報未設定のみ表示"
          ></v-checkbox>
          <v-btn
            class="ml-5"
            v-if="transaction_history_res.unconfirmed_total > 0" 
            @click="$vuetify.goTo('#unconfirmedList', {duration: 500, offset: 0})"
            outlined
            depressed
          >受注時グローバルNo未設定履歴へ</v-btn>

          <v-btn :color=search_btn_color
            dark  
            class="ml-5"
            v-if="this.transaction_history_res.type=='monthly' && this.can_publish_new_statement"
            @click="confirmStatementDataCreate()">
            チェックした履歴で明細データ作成
          </v-btn>
          <v-btn
            disabled
            class="ml-5"
            v-if="this.can_publish_new_statement == false">
            発行済の明細書があります
          </v-btn>

        </v-card-title>

        <v-alert dense outlined type="info" 
          v-if="this.transaction_history_res.type=='monthly'">
          <ul>
            <li>手数料明細データについて</li>
            <ul>
              <li>変更・キャンセル情報が設定されていない履歴は明細データに含めることができません</li>
              <li>履歴の追加や注文時グローバルNoの再設定などで、一度作成した明細データを作り直したい場合、手数料明細書一覧画面から作成済みの明細データを取り下げ、改めて明細データの再作成を行ってください</li>
            </ul>
            <li>受注時グローバルNo未設定履歴について</li>
            <ul>
              <li>イレギュラー対応などで受注時とは異なるグローバルNoが発行されている場合、ただしい受注時グローバルNoを設定してください</li>
              <li>受注時グローバルNo未設定履歴は期間指定を問わず全件出力します</li>
            </ul>
          </ul>
        </v-alert>
        <v-alert dense outlined type="info" 
          v-if="this.transaction_history_res.type=='transaction'">
          <span v-if="this.transaction_history_res.total > this.transaction_data_limit">  
            件数が多いため、{{ this.transaction_data_limit }}件まで表示しています<br>
          </span>
          明細データの作成は「取引履歴表示」タブから行ってください
        </v-alert>
        <v-alert dense outlined type="info" 
          v-if="this.transaction_history_res.type=='unpublished'">
          <span v-if="this.transaction_history_res.total > this.unpublish_data_limit">  
            件数が多いため、{{ this.unpublish_data_limit }}件まで表示しています<br>
          </span>
          明細データの作成は「取引履歴表示」タブから行ってください
        </v-alert>

      </v-card>

      <!-- 受注時グローバルNo.グループごと -->
      <div width="95%" 
        v-for="order_global_no_group_item in order_global_no_groups" 
        :key="order_global_no_group_item.order_global_no"
        >
        <v-container class="ma-2" v-if="!show_only_unset_irregular_group || order_global_no_group_item.show_when_unset_irregular_filter" fluid>
          <v-row justify="center">
            <v-card 
              outlined
              width="95%" 
              color="blue lighten-5"
              class="mt-2"
              justify="center"
              >
              <v-container fluid class="pa-1 pb-0">
                <span class="text-body2">受注時グローバルNo.&nbsp;<span class="font-weight-bold">{{ order_global_no_group_item.order_global_no }}</span></span>
              </v-container>

              <!-- グローバルNo.グループごと -->
              <v-container fluid class="pa-1 pt-3 pr-3 pb-3">
                <v-row justify="center">
                  <v-card 
                    flat 
                    width="95%" 
                    color="grey lighten-5"
                    class="ma-1 pr-1"
                    v-for="global_no_group_item in order_global_no_group_item.global_no_groups" 
                    :key="global_no_group_item.global_no">

                    <v-row>
                      <v-col cols="2" align="left" valign="top" class="pl-3 pt-0 ma-3 mb-0">
                        <span class="text-body2 text-no-wrap">グローバルNo: <span class="font-weight-bold">{{ global_no_group_item.global_no }}</span></span>
                        <v-text-field
                            width="100"
                            dense
                            outlined
                            label="受注時グローバルNo.を変更"
                            background-color="white"
                            hide-details="false"
                            v-model.trim="input_order_global_no[global_no_group_item.global_no]"
                            class="pt-3"
                        ></v-text-field>
                        <v-btn 
                          :color=search_btn_color
                          dark
                          class="pa-2 mt-1"
                          @click="setOrderGlobalNo(global_no_group_item.global_no)">
                          <v-icon class="mr-1">mdi-pencil-outline</v-icon>
                          変更
                        </v-btn>
                      </v-col>
                      <v-col align="left">
                        <v-container fluid class="pa-2">
                          <table border="1" width="100%" class="history" :bgcolor="global_no_group_item.global_no_items[0].slip_type=='110'?'ghostwhite':'#FCE4EC'">
                            <thead>
                              <tr class="text-center">
                                <th width="3%">削除</th>
                                <th width="3%">履歴No.</th>
                                <th width="8%">グローバルNo</th>
                                <th width="3%">伝票区分</th>
                                <th width="4%">仕入先コード</th>
                                <th width="8%">型番</th>
                                <th width="5%">受注日</th>
                                <th width="5%">VSD</th>
                                <th width="5%">単価 x 発注数</th>
                                <th width="5%">登録ユーザ</th>
                                <th width="5%">更新ユーザ</th>
                                <th width="5%">最終ユーザ更新日</th>
                                <th width="4%">更新番号</th>
                                <th width="4%">手数料明細No.</th>
                                <th width="10%">変更・キャン情報/更新</th>
                                <th width="3%">出力</th>
                              </tr>
                            </thead>

                            <tbody width="80%">
                              <!-- 履歴ごと -->
                              <tr 
                                v-for="global_no_item in global_no_group_item.global_no_items" 
                                :key="global_no_item.id"
                                align="center"
                                valign="top"
                              >
                                <td>
                                  <v-icon v-if="global_no_item.delete_flag == '1' || global_no_item.po_status == 'x'" color="red" medium>mdi-delete-circle-outline</v-icon>
                                </td>
                                <td>{{ global_no_item.mep_history_no }}</td>
                                <td nowrap>{{ global_no_item.global_no }}</td>
                                <td nowrap>{{ global_no_item.slip_type }}<br/> 
                                  <font :color="global_no_item.slip_type=='221'?'red':'black'">{{ global_no_item.slip_type=='221'?'(-)':'(+)' }}</font></td>
                                <td nowrap>{{ global_no_item.supplier_cd }}</td>
                                <td>{{ global_no_item.product_cd }}</td>
                                <td>{{ global_no_item.po_date }}</td>
                                <td>{{ global_no_item.vsd }}</td>
                                <td>{{ global_no_item.purchase_unit_price }} x {{ global_no_item.po_qty }}</td>
                                <td>{{ global_no_item.register_user }}</td>
                                <td>{{ global_no_item.update_user }}</td>
                                <td>{{ global_no_item.user_update_date }}</td>
                                <td>{{ global_no_item.update_count }}</td>
                                <td nowrap>{{ global_no_item.statement_no }}</td>
                                <td>
                                  <!-- 注文時グローバルNo＝グローバルNoであれば先頭行では変キャン情報Selectbox/Button非表示 -->
                                  <div v-if="global_no_item.showIrregularSetting">
                                    <v-row class="ma-0 pa-0">
                                      <v-col class="ma-0 pa-0">
                                        <span v-if="!global_no_item.irregular_type || !global_no_item.irregular_source" class="red white--text text--lighten-1">【未設定】</span>
                                        <!-- すでに明細データ作成済である履歴は、変キャン情報の更新を受け付けない -->
                                        <v-select
                                          v-model="input_irregular_type[global_no_item.global_no][global_no_item.update_count]"
                                          :items="irregular_type_codes"
                                          :disabled="!global_no_item.acceptIrregularSetting"
                                          label="区分"
                                          dense
                                          hide-details
                                          full-width
                                          outlined
                                        ></v-select>
                                        <v-select
                                        v-model="input_irregular_source[global_no_item.global_no][global_no_item.update_count]"
                                          :items="irregular_source_codes"
                                          :disabled="!global_no_item.acceptIrregularSetting"
                                          label="起因"
                                          dense
                                          hide-details
                                          full-width
                                          outlined
                                        ></v-select>
                                      </v-col>
                                      <v-col class="ma-0 pa-0">
                                        <a :href="jiraSearchUrl(global_no_item.global_no)" target='_blank'>JIRA検索</a><br>
                                        <v-btn
                                          :color=search_btn_color
                                          :disabled="!global_no_item.acceptIrregularSetting"
                                          @click="setIrregularInfo(global_no_item.global_no, global_no_item.update_count)"
                                          dark
                                          dense
                                          small
                                          class="ma-1 pa-2">
                                          <v-icon class="mr-1">mdi-pencil-outline</v-icon>
                                          更新
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </td>
                                <td>
                                  <!-- 取引履歴表示画面でのみ明細データ作成可能 -->
                                  <!-- すでに明細データ作成済である履歴は、新たな明細データ作成対象としない -->
                                  <!-- 変キャン情報の入力が必要な履歴で、まだDBに値が更新されていない場合は明細データ作成対象としない -->
                                  <v-checkbox
                                    v-if="transaction_history_res.type=='monthly' && !global_no_item.statement_no"
                                    v-model="statement_data_selected"
                                    :value="global_no_item.id"
                                    :disabled="!global_no_item.statementDataAllowed"
                                  ></v-checkbox>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </v-container>
                      </v-col>

                    </v-row>

                  </v-card>
                </v-row>
              </v-container>
            </v-card>
          </v-row>
          <v-row justify="center">
            <v-divider></v-divider>
          </v-row>
        </v-container>
      </div>
      <v-card flat width="100%" v-if="transaction_history_res && transaction_history_res.unconfirmed_total > 0" id="unconfirmedList">
        <v-card-title>
          <span class="font-weight-bold">【履歴紐づけ必要】受注時グローバルNo未設定履歴</span>
          <v-spacer></v-spacer>
          <v-btn 
          @click="$vuetify.goTo('#pageTop', {duration: 500, offset: 0})"
          outlined
          depressed
          >ページトップへ</v-btn>
        </v-card-title>

      </v-card>

      <!-- グローバルNo.グループごと -->
      <v-container fluid class="pa-1 pt-3 pr-3 pb-3">
        <v-card 
          flat 
          width="100%" 
          color="orange lighten-5"
          class="ma-1 pa-1"
          v-for="global_no_group_item in unconfirmed_global_no_groups" 
          :key="global_no_group_item.global_no">

          <v-row justify="center">
            <v-col cols="2" align="left" valign="top" class="pl-3 pt-0 ma-3 mb-0">
              <span class="text-body2">グローバルNo: <span class="font-weight-bold">{{ global_no_group_item.global_no }}</span></span>
              <v-text-field
                  dense
                  outlined
                  label="受注時グローバルNo"
                  background-color="white"
                  hide-details="false"
                  class="pt-1"
                  v-model.trim="input_order_global_no[global_no_group_item.global_no]"
                  ></v-text-field>
            </v-col>
            <v-col cols="1" align="left">
              <v-btn 
                :color=search_btn_color
                dark
                @click="setOrderGlobalNo(global_no_group_item.global_no)"
                class="pa-2 mt-7">
                <v-icon class="mr-1">mdi-pencil-outline</v-icon>
                設定
              </v-btn>
            </v-col>
            <v-col align="left">
              <v-container fluid class="pa-0">
                <table border="1" width="100%" class="history" bgcolor="ghostwhite">
                  <thead>
                    <tr class="text-center">
                      <th width="3%">削除</th>
                      <th width="10%">グローバルNo</th>
                      <th width="3%">伝票区分</th>
                      <th width="5%">仕入先コード</th>
                      <th width="10%">型番</th>
                      <th>受注日</th>
                      <th>VSD</th>
                      <th>単価 x 発注数</th>
                      <th>登録ユーザ</th>
                      <th>更新ユーザ</th>
                      <th>最終ユーザ更新日</th>
                      <th>更新番号</th>
                      <th>JIRA検索</th>
                    </tr>
                  </thead>

                  <tbody width="80%">
                    <!-- 履歴ごと -->
                    <tr 
                      v-for="global_no_item in global_no_group_item.global_no_items" 
                      :key="global_no_item.id"
                      align="center"
                      valign="top"
                    >
                      <td>
                        <v-icon v-if="global_no_item.delete_flag == '1' || global_no_item.po_status == 'x'" color="red" medium>mdi-delete-circle-outline</v-icon>
                      </td>
                      <td nowrap>{{ global_no_item.global_no }}</td>
                      <td nowrap>{{ global_no_item.slip_type }}</td>
                      <td nowrap>{{ global_no_item.supplier_cd }}</td>
                      <td nowrap>{{ global_no_item.product_cd }}</td>
                      <td nowrap>{{ global_no_item.po_date }}</td>
                      <td nowrap>{{ global_no_item.vsd }}</td>
                      <td>{{ global_no_item.purchase_unit_price }} x {{ global_no_item.po_qty }}</td>
                      <td>{{ global_no_item.register_user }}</td>
                      <td>{{ global_no_item.update_user }}</td>
                      <td>{{ global_no_item.user_update_date }}</td>
                      <td>{{ global_no_item.update_count }}</td>
                      <td><a :href="jiraSearchUrl(global_no_item.global_no)" target='_blank'>JIRA検索</a></td>
                    </tr>
                  </tbody>
                </table>
              </v-container>
            </v-col>
          </v-row>
        </v-card>
      </v-container>

      <v-card flat width="100%" v-if="transaction_history_res" id="unconfirmedList">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn 
          @click="$vuetify.goTo('#pageTop', {duration: 500, offset: 0})"
          outlined
          depressed
          >ページトップへ</v-btn>
        </v-card-title>
      </v-card>

    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import feeCalculatorService from '@/services/feeCalculatorService';
import FeeCalculateHelper from '@/components/feeCalculate/FeeCalculateHelper.vue'
import RegisterCsvDialog from '@/components/feeCalculate/RegisterCsvDialog.vue'
import moment from 'moment'

export default {
  name: 'transactionhistory',
  title: '手数料明細:月次取引履歴/明細検索',
  data () {
    return {
      // 検索入力項目用
      selected_page: 'transactionhistory',
      input_history_supplier_cd: '',
      input_transaction_supplier_cd: '',
      input_unpublished_supplier_cd: '',
      input_month: '',
      input_date_from: '',
      input_date_to: '',
      input_global_no: '',
      input_product_part_no: '',
      input_statement_no: '',
      unpublish_data_limit: 50,
      transaction_data_limit: 50,

      // データ表示用
      suppliers:[],
      transaction_history_res: null,
      order_global_no_groups: [],
      unconfirmed_global_no_groups: [],
      statement_data_not_allowed_cnt:0,
      show_only_unset_irregular_group: false,
      
      // 画面操作用
      stepper_step: 1,
      helper_dialog: false,
      process_message:'',
      process_message_type:'',
      dialog: false,

      search_loading: false,
      search_btn_color: "light-blue darken-4",
      input_month_menu: false,
      transaction_search_input_month_menu: false,
      transaction_search_input_date_from_menu: false,
      transaction_search_input_date_to_menu: false,

      helperParam: {
        stepper_step: 1,
        helper_dialog: false,
      },

      confirm_dialog:false,

      irregular_type_codes:[
        {value:"", text:"（未設定）"}, 
        {value:"update", text:"変更"}, 
        {value:"cancel", text:"キャンセル"}
      ],
      irregular_source_codes:[
        {value:"", text:"（未設定）"}, 
        {value:"confirmshippment", text:"出荷日確定"}, 
        {value:"customer", text:"顧客"},
        {value:"partner", text:"パートナー"},
        {value:"misumi", text:"ミスミ"},
        {value:"other", text:"その他"},
      ],

      // データ更新用
      can_publish_new_statement: true,
      statement_data_selected: [],
      input_order_global_no: {},
      input_irregular_type: {},
      input_irregular_source: {},

      /** CSV登録ダイアログ */
      uploadHelperParam: {
        /** ダイアログ表示可否 */
        visible: false,
      }
    }
  },
  components: {
    FeeCalculateHelper,
    RegisterCsvDialog
  },
  methods: {
    // 描画処理用メソッド
    confirmStatementDataCreate() {
      this.confirm_dialog = true
    },


    // 受注時グローバルNoとグローバルNoのペア、変キャン情報設定の入力可否、明細データ抽出可否をセットする
    setOrderItemOperationFlags(data) {
      // 発行済明細書あれば追加発行NG
      this.can_publish_new_statement = true
      if (data.published_statement_nos && data.published_statement_nos.length > 0) {
        this.can_publish_new_statement = false
      }

      const order_global_no_groups = data.order_global_no_groups

      this.input_order_global_no = {}
      this.input_irregular_type = {}
      this.input_irregular_source = {}
      this.statement_data_selected = []

      let input_order_global_no = {}
      let input_irregular_type = {}
      let input_irregular_source = {}
      let statementDataAllowedArray = []
      var statement_data_not_allowed_cnt = 0

      if (order_global_no_groups) {
        // 受注時グローバルNo.グループごと
        for (const order_global_no_group_item of order_global_no_groups){
          // 変キャン未設定履歴を含むグループ絞り込み時に表示するかどうか。初期値Falseを設定
          order_global_no_group_item.show_when_unset_irregular_filter = false

          if (order_global_no_group_item.global_no_groups) {

            // グローバルNo.グループごと
            for (const global_no_group_item of order_global_no_group_item.global_no_groups){
              // 受注時グローバルNoとグローバルNoのペアを作成
              input_order_global_no[global_no_group_item.global_no] = order_global_no_group_item.order_global_no

              // 履歴ごと
              global_no_group_item.global_no_items.forEach((global_no_item, index) => {
                // 注文時グローバルNo＝自身のグローバルNoでは、先頭行以外で変キャン情報を表示・設定する
                if((order_global_no_group_item.order_global_no == global_no_group_item.global_no) && index == 0) {
                  global_no_item.showIrregularSetting = false
                  global_no_item.acceptIrregularSetting = false
                } else {
                  global_no_item.showIrregularSetting = true
                  global_no_item.acceptIrregularSetting = true
                  // 変キャン情報入力フィールド用情報をセット
                  if (!input_irregular_type[global_no_item.global_no]) {
                    input_irregular_type[global_no_item.global_no] = {}
                  }
                  if (global_no_item.irregular_type) {
                    input_irregular_type[global_no_item.global_no][global_no_item.update_count] = global_no_item.irregular_type
                  } else if (global_no_item.delete_flag == '1' || global_no_item.po_status == 'x') {
                    // 既存の変キャン情報がない場合、削除フラグ立っていれば「キャンセル」、そうでなければ「変更」「出荷日確定」を初期値
                    input_irregular_type[global_no_item.global_no][global_no_item.update_count] = "cancel"
                  } else {
                    input_irregular_type[global_no_item.global_no][global_no_item.update_count] = "update"
                  }
                  if (!input_irregular_source[global_no_item.global_no]) {
                    input_irregular_source[global_no_item.global_no] = {}
                  }
                  if (global_no_item.irregular_source) {
                    input_irregular_source[global_no_item.global_no][global_no_item.update_count] = global_no_item.irregular_source
                  } else if (global_no_item.delete_flag == '0' && global_no_item.po_status != 'x') {
                    // 既存の変キャン情報がない場合、削除フラグ立っていなければ「出荷日確定」を初期値
                    input_irregular_source[global_no_item.global_no][global_no_item.update_count] = "confirmshippment"
                  }
                }

                // 変キャン情報の入力が必要な履歴で、まだDBに値が更新されていない場合は明細データ作成対象としない
                if (global_no_item.acceptIrregularSetting && (!global_no_item.irregular_type || !global_no_item.irregular_source)) {
                  global_no_item.statementDataAllowed = false
                  // 変キャン未設定履歴を含むグループ絞り込み時に表示する
                  order_global_no_group_item.show_when_unset_irregular_filter = true
                } else {
                  global_no_item.statementDataAllowed = true
                }

                // すでに明細データ作成済である履歴は、変キャン情報の更新を受け付けない
                // すでに明細データ作成済である履歴は、明細書データ作成対象としない
                if (global_no_item.statement_no) {
                  global_no_item.acceptIrregularSetting = false
                  global_no_item.statementDataAllowed = false
                }

                // 明細書データ作成対象OKの行については明細書出力チェックボックスをONにする
                if (global_no_item.statementDataAllowed) {
                  statementDataAllowedArray.push(global_no_item.id);
                  this.statement_data_selected = [...new Set(statementDataAllowedArray)]
                } else if (!global_no_item.statement_no) {
                  statement_data_not_allowed_cnt++
                }
              })
            }
          }
        }
      }

      this.order_global_no_groups = order_global_no_groups
      this.unconfirmed_global_no_groups = data.unconfirmed_global_no_groups
      this.statement_data_not_allowed_cnt = statement_data_not_allowed_cnt
      this.input_order_global_no = input_order_global_no
      this.input_irregular_type = input_irregular_type
      this.input_irregular_source = input_irregular_source
      
      return
    },

    jiraSearchUrl(global_no) {
      const jiraUrl = `https://meviypartners.atlassian.net/issues/?filter=-4&jql=project = MEPSPRT AND text ~ "${global_no}" order by created DESC`
      return jiraUrl
    },

    // 検索用メソッド
    // 0. 手数料請求対象サプライヤ検索
    getTargetPartners () {
      feeCalculatorService.getTargetPartners().then(data => {

        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          return
        }

        this.suppliers = data
      })
    },

    // 1. 取引履歴表示
    getTransactionHistory () {
      this.search_loading = true
      this.dialog = false
      this.process_message=""
      this.process_message_type=""

      if(!this.input_history_supplier_cd) {
        this.process_message = "パートナーは必ず選択してください"
        this.process_message_type = "error"
        this.search_loading = false
        this.dialog = true
        return
      }
      if(!this.input_month) {
        this.process_message = "月度は必ず選択してください"
        this.process_message_type = "error"
        this.search_loading = false
        this.dialog = true
        return
      }
      let month = this.input_month.replace( '-', '' );
      let supplier_cd = this.input_history_supplier_cd      

      feeCalculatorService.getTransactionHistory(supplier_cd, month).then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.search_loading = false
          this.dialog = true
          return
        }

        // 取得データ用
        this.transaction_history_res = {
          title: "取引履歴表示",
          type: "monthly",
          statement_data_create: true,
          supplier_cd: data.supplier_cd,
          cover_month: data.cover_month,
          cover_date_from: data.cover_date_from,
          cover_date_to: data.cover_date_to,
          published_statement_nos: data.published_statement_nos,
          total: data.total,
          unconfirmed_total: data.unconfirmed_total,
        }
        this.setOrderItemOperationFlags(data)

        // 画面操作用
        this.search_loading = false
      })
    },

    // 2. 注文明細検索
    getTransaction () {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""
      
      if(
        !this.input_transaction_supplier_cd && 
        !this.input_global_no && 
        !this.input_product_part_no && 
        !this.input_statement_no && 
        !this.input_month &&
        !this.input_date_from &&
        !this.input_date_to) {
        this.process_message = "条件は1つ以上指定してください"
        this.process_message_type = "error"
        this.search_loading = false
        this.dialog = true
        return
      }

      let supplier_cd = this.input_transaction_supplier_cd
      let global_no = this.input_global_no
      let product_part_no = this.input_product_part_no
      let statement_no = this.input_statement_no
      let month = this.input_month.replace( '-', '' );
      let date_from = this.input_date_from
      let date_to = this.input_date_to

      feeCalculatorService.getTransaction(supplier_cd, global_no, product_part_no, statement_no, month, date_from, date_to, this.transaction_data_limit).then(data => {

        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          this.search_loading = false
          return
        }

        // 取得データ用
        this.transaction_history_res = {
          title: "注文明細検索",
          type: "transaction",
          statement_data_create: false,
          supplier_cd: data.supplier_cd,
          cover_date_from: data.cover_date_from,
          cover_date_to: data.cover_date_to,
          total: data.total,
          unconfirmed_total: data.unconfirmed_total,
        }
        this.setOrderItemOperationFlags(data)

        // 画面操作用
        this.search_loading = false
      })
    },

    // 3. 未出力明細検索
    getUnpublished () {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""
      this.dialog = false

      if(!this.input_unpublished_supplier_cd) {
        this.process_message = "パートナーは必ず選択してください"
        this.process_message_type = "error"
        this.search_loading = false
        this.dialog = true
        return
      }

      let supplier_cd = this.input_unpublished_supplier_cd

      feeCalculatorService.getUnpublished(supplier_cd, this.unpublish_data_limit).then(data => {

        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          this.search_loading = false
          return
        }

        // 取得データ用
        this.transaction_history_res = {
          title: "未出力明細検索",
          type: "unpublished",
          statement_data_create: false,
          supplier_cd: data.supplier_cd,
          total: data.total,
          unconfirmed_total: data.unconfirmed_total,
        }
        this.setOrderItemOperationFlags(data)

        // 画面操作用
        this.search_loading = false
      })
    },

    refreshSearch() {
      if(this.transaction_history_res) {
        const type = this.transaction_history_res.type
        switch (type){
          case 'monthly':
            this.getTransactionHistory()
            break
          case 'transaction':
            this.getTransaction()
            break
          case 'unpublished':
            this.getUnpublished()
            break
          default:
            break
        }
      }
      return
    },

    // 受注時グローバルNo設定
    setOrderGlobalNo (global_no) {
      this.process_message=""
      this.process_message_type=""

      let order_global_no = null
      order_global_no = this.input_order_global_no[global_no]

      if(!order_global_no) {
        this.process_message = "注文時グローバルNoを入力してください"
        this.process_message_type = "error"
        this.dialog = true
        return
      }

      feeCalculatorService.setOrderGlobalNo(global_no, order_global_no).then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          return
        }

      this.refreshSearch()
      this.process_message = global_no + "に受注時グローバルNo" + order_global_no + "を設定しました"
      this.process_message_type = "info"
      this.dialog = true

      })
    },

    // 変キャン情報設定
    setIrregularInfo (global_no, update_count) {
      this.process_message=""
      this.process_message_type=""
      
      let irregular_type = null
      let irregular_source = null
      irregular_type = this.input_irregular_type[global_no][update_count]
      irregular_source = this.input_irregular_source[global_no][update_count]

      if(irregular_type=="") {
        irregular_type = null
      }
      if(irregular_source=="") {
        irregular_source = null
      }

      feeCalculatorService.setIrregularInfo(global_no, update_count, irregular_type, irregular_source)
        .then(data => {
          if ("error" in data) {
            this.process_message = data.detail
            this.process_message_type = "error"
            this.dialog = true
            return
          }

      this.refreshSearch()
      this.process_message = global_no + "の変キャン情報を設定しました"
      this.process_message_type = "info"
      this.dialog = true

      })
    },
    // 手数料明細データ作成
    setAndCreateStatementData(supplier_cd, cover_month){

      feeCalculatorService.setAndCreateStatementData(supplier_cd, cover_month, this.statement_data_selected)
        .then(data => {
          if (data != null && "error" in data) {
            if (data.detail) {
              this.process_message = data.detail
            } else {
              this.process_message = "手数料明細データの作成に失敗しました。"
            }
            this.process_message_type = "error"
            this.dialog = true
            return
          }

        this.refreshSearch()
        this.process_message = "[" + supplier_cd + "]の[" + cover_month + "]月分手数料明細データ作成を開始しました。ステータスは手数料明細一覧から確認してください"
        this.process_message_type = "info"
        this.dialog = true
      })

      return
    },
    /** CSV アップロード成功後 */
    onUploadCsvSuccess(message) {
      this.process_message = message
      this.process_message_type = "info"
      this.dialog = true
    },
  },
  mounted () {
    this.getTargetPartners()

    // 規定のクエリパラメータが渡ってきたら初期検索する
    const initial_supplier_cd = this.$route.query.supplier_cd
    const initial_cover_month = this.$route.query.cover_month // initial_cover_monthはyyyyMM

    if (initial_supplier_cd && initial_supplier_cd.length > 0 && initial_cover_month && initial_cover_month.length > 0) {
        var pattern = /^\d*$/;
        if (initial_cover_month && initial_cover_month.length == 6 && pattern.test(initial_cover_month)) {
            var date = new Date(initial_cover_month.substring(0,4), parseInt(initial_cover_month.substring(4), 10)-1, "01", "01", "01", "01");
            const initial_month_condition = moment(date).format('YYYY-MM');
            this.input_history_supplier_cd = initial_supplier_cd
            this.input_month = initial_month_condition
            this.getTransactionHistory()
        }
    }
  },
}
</script>


<style type="text/css">
table.history {
  width: 100%;
  table-layout: fixed;
}


table.history, table.history td, table.history th {
  border: 1px lightgray solid;
  border-collapse: collapse;
  font-size: 14px;
}

table.history td, table.history th {
  padding: 2px 10px;
}

</style>