export const formInputRegex = {
    "HanSymbolSpace": '!-/:-@[-`{-~ ',
    "ZenSymbolSpace": '！”＃＄％＆’（）＝～｜‘｛＋＊｝＜＞？＿ー－‐＾￥＠「；：」、。・　',
    "HanAlphaDigit": 'a-zA-Z0-9',
    "ZenAlphaDigit": 'ａ-ｚＡ-Ｚ０-９',
    "ZenHiraKata": 'ぁ-ゖァ-ヶ',
    "HanKata": 'ｦ-ﾟ',
    "ZipcdJp": '[0-9]{3}-?[0-9]{4}',
    "TelJp": '[0-9]{2,5}-[0-9]{1,4}-[0-9]{4}',
    "BankAccountNameJp": 'A-Z0-9\\(\\)\\.\\-/ ',
}
