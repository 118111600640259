import { Translations } from '@aws-amplify/ui-components'
export const cognito_messages = {
    "ja-JP": {
        [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'ログインID',
        [Translations.USERNAME_LABEL]: 'ログインID *',
        [Translations.USERNAME_PLACEHOLDER]: 'ログインIDを入力',
        "Custom auth lambda trigger is not configured for the user pool.":
            "パスワードが入力されていません",
        "Cannot reset password for the user as there is no registered/verified email or phone_number":
            "メールアドレスや電話番号が登録されていないため、ユーザーのパスワードをリセットできません。",
        "Incorrect username or password.": "ログインIDまたはパスワードが異なります",
        "Username cannot be empty": "ログインIDを入力してください",
        "Enter your Username": "ログインIDを入力",
    },
};