<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>
      <!-- 処理結果ダイアログ -->
      <template>
        <div class="text-center">
          <v-dialog v-model="dialog" width="500">
            <v-card>
              <v-card-text>
                <br/>{{ this.process_message }}
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog = false">
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </template>

      <v-card flat width="100%" class="mb-2" id="pageTop">
        <v-card-title>
          手数料明細
          <v-btn-toggle
            v-model="selected_page"
            class="ml-5"
            dense
            group
          >
          <v-btn color="indigo darken-4" outlined value="partnerSearch" to="/partnerSearch" elevation="3" class="ml-10 font-weight-black">
              パートナー一覧
            </v-btn>
            <v-btn color="teal darken-4" outlined value="transactionhistory" to="/transactionhistory" elevation="3" class="ml-10 font-weight-black">
              月次取引履歴/明細検索
            </v-btn>
            <v-btn color="teal darken-4" outlined value="monthlyfeestatements" to="/monthlyfeestatements" elevation="3" class="font-weight-black">
              月次手数料明細書一覧
            </v-btn>
            <v-btn color="orange darken-4" outlined value="feestatements" class="ml-10 font-weight-black">
              手数料明細書検索
            </v-btn>
          </v-btn-toggle>
          <v-btn elevation="3" class="ml-10 font-weight-black orange--text text--darken-2 rounded-0" style="background-color:#fff" @click="uploadHelperParam.visible = true">
            仕入追加・単価修正+/-CSV登録
          </v-btn>
          <v-spacer></v-spacer>
          <v-fab-transition>
            <v-btn
              color="light-blue darken-4"
              fab
              large
              dark
              depressed
              @click="helperParam.helper_dialog = true"
              >
              <v-icon>mdi-help</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-card-title>

        <!-- ヘルプ -->
        <FeeCalculateHelper :helperParam="helperParam" />

        <!-- CSVアップロードダイアログ -->
        <RegisterCsvDialog 
          :uploadHelperParam="uploadHelperParam"
          @onUploadCsvSuccess="onUploadCsvSuccess" />

        <v-alert dense outlined :type=this.process_message_type v-if="this.process_message">
          {{ this.process_message }}
        </v-alert>

        <v-card flat>
          <v-row>
            <v-col cols="2">
              <v-select
                  dense
                  v-model="input_supplier_cd"
                  outlined placeholder="パートナー"
                  clearable
                  :items="suppliers"
                  item-value="supplier_cd"
                  :item-text="item => '[' + item.supplier_cd + ']' + item.company_name_kanji">
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                  dense
                  v-model="input_status"
                  outlined placeholder="データステータス"
                  clearable
                  :items="status_list">
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                  dense
                  v-model="input_pdf_status"
                  outlined placeholder="PDFステータス"
                  clearable
                  :items="pdf_status_list">
              </v-select>
            </v-col>
            <v-col cols="1">
              <v-menu
                  v-model="input_month_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      dense
                      clearable
                      v-model="input_month"
                      label="月度"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="input_month"
                    type="month"
                    @input="input_month_menu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
                  <v-text-field
                      dense
                      v-model.trim="input_statement_no"
                      outlined placeholder="明細書番号"
                      clearable>
                  </v-text-field>
                </v-col>
            <v-col cols="2">
              <v-btn  :color=search_btn_color
                      :loading=search_loading
                      dark
                      class="ml-5"
                      @click="getStatements()">
                <v-icon class="mr-1">mdi-database-search-outline</v-icon>
                手数料明細書検索
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-card>


      <v-card flat>
        <v-dialog
            v-model="upload_dialog"
            persistent
            max-width="800"
        >
          <v-card>
            <v-card-title class="text-h6">
              マニュアル作成ファイルを登録します
            </v-card-title>
            <v-card-text>
              <v-alert dense outlined type="info">
                手数料明細[{{upload_statement_no}}]にマニュアル作成ファイルを登録します。
              </v-alert>
            </v-card-text>
            <validation-provider
              v-slot="{ errors }"
              name="マニュアル作成PDFファイル"
              rules="ext:pdf"
            >
              <v-file-input
                accept=".pdf"
                persistent-hint outlined
                v-model="upload_manual_pdf_file"
                :error-messages="errors"
                chips
                multiple
                label="マニュアル作成PDFファイル"
                hint="拡張子「.pdf」のファイルを選択してください"
              ></v-file-input>
            </validation-provider>

            <validation-provider
              v-slot="{ errors }"
              name="マニュアル作成エクセルファイル"
              rules="ext:xlsx"
            >
              <v-file-input
                accept=".xlsx"
                persistent-hint outlined
                v-model="upload_manual_excel_file"
                :error-messages="errors"
                chips
                multiple
                label="マニュアル作成エクセルファイル"
                hint="拡張子「.xlsx」のファイルを選択してください"
              ></v-file-input>
            </validation-provider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="upload_dialog=false">キャンセル</v-btn>
              <v-btn color="blue darken-4" outlined @click="uploadFiles();upload_dialog=false;">実行する</v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </v-card>


      <v-card flat>
        <v-dialog
            v-model="withdraw_confirm_dialog"
            persistent
            max-width="800"
        >
          <v-card>
            <v-card-title class="text-h6">
              手数料明細データを取り下げます
            </v-card-title>
            <v-card-text>
              <v-alert dense outlined type="error">
                手数料明細[{{withdraw_statement_no}}]を取り下げます。
                取り下げ後、再度取引履歴一覧から「明細データ作成」を行ってください。
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="gray darken-1" text @click="withdraw_confirm_dialog=false">キャンセル</v-btn>
              <v-btn color="blue darken-4" outlined @click="withdrawStatement(withdraw_statement_no);withdraw_confirm_dialog=false;">実行する</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-alert dense outlined type="info">
          明細書の再作成について
          <ul>
            <li>明細の追加や注文時グローバルNoの再設定などで、一度作成した手数料データを作り直したい場合、手数料明細書一覧画面から作成済みの手数料データを取り下げ、改めて手数料データの再作成を行ってください</li>
          </ul>
        </v-alert>

      </v-card>

      <v-container fluid class="pa-1">

        <v-data-table
            :headers="headers"
            :items="statements"
            :options.sync="options"
            :server-items-length="totalcount"
            :loading="search_loading"
            :items-per-page="itemsperpage"
            :footer-props = "{ 'items-per-page-text':'行/ページ:',
             'items-per-page-options': [10,20,30,50]}"
            class="elevation-1"
            fixed-header
            height="500"
        >
          <template
              v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
              v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>

          <template v-slot:item.download="{ item }">
            <v-row justify="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                  v-if="item.canDownloadData" 
                  v-on="on"
                  v-bind="attrs"
                  v-on:click="downloadFile(
                    item.data_presigned_url, 
                    (item.data_file_name ? item.data_file_name : item.statement_no + '.json')
                    )"
                  color="primary">mdi-code-json</v-icon>
                </template>
                <span>JSONデータ</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                    v-if="item.canDownloadPDF" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.pdf_presigned_url, 
                      (item.pdf_file_name ? item.pdf_file_name : item.statement_no + '.pdf')
                      )"
                    color="primary">mdi-file-pdf-box </v-icon>
                </template>
                <span>PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    v-if="item.canDownloadExcel" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.excel_presigned_url, 
                      (item.excel_file_name ? item.excel_file_name : item.statement_no + '.xslx')
                      )"
                    color="primary">mdi-microsoft-excel </v-icon>
                </template>
                <span>EXCEL</span>
              </v-tooltip>
            </v-row>
          </template>

          <template v-slot:item.cover_month="{ item }">
            <v-row justify="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-on="on" v-bind="attrs">
                    <router-link :to="`/transactionhistory?supplier_cd=${item.supplier_cd}&cover_month=${item.cover_month}`">{{ item.cover_month }}</router-link>
                  </span>
                </template>
                <span>月次取引履歴へ</span>
              </v-tooltip>
            </v-row>
          </template>
         
          <template v-slot:item.manual_file_download="{ item }">
            <v-row justify="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                  v-if="(item.manual_pdf_presigned_url != null)" 
                  v-on="on"
                  v-bind="attrs"
                  v-on:click="downloadFile(
                    item.manual_pdf_presigned_url, 
                    (item.manual_pdf_file_name ? item.manual_pdf_file_name : item.statement_no + '_MANUAL.pdf')
                    )"
                  color="deep-orange">mdi-file-pdf-box</v-icon>
                </template>
                <span>マニュアル作成PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    v-if="(item.manual_excel_presigned_url != null)" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.manual_excel_presigned_url, 
                      (item.manual_excel_file_name ? item.manual_excel_file_name : item.statement_no + '_MANUAL.xslx')
                      )"
                    color="deep-orange">mdi-microsoft-excel </v-icon>
                </template>
                <span>マニュアル作成EXCEL</span>
              </v-tooltip>
            </v-row>
          </template>

          <template v-slot:item.action="{ item }">
            <v-row justify="center">
              <v-btn small
                class="ma-1"
                :color="search_btn_color"
                outlined
                v-if="item.canEditData"
                v-on:click="openDataEditWindow(item.statement_no)">
                詳細
              </v-btn>
              <v-btn small 
                class="ma-1"
                :color="search_btn_color"
                outlined
                v-if="item.canCreatePDF"
                @click="createStatementPDF(item.statement_no, item.cover_month)">
                PDF作成
              </v-btn>
              <v-btn small
                class="ma-1"
                :color="search_btn_color"
                outlined
                v-if="item.canUploadFile"
                v-on:click="showUploadDialog(item.statement_no)">
                ファイル登録
              </v-btn>
              <v-btn small depressed dark 
                class="ma-1"
                color="red darken-1" 
                v-if="item.statement_no && item.status != 'withdrawn'"
                v-on:click="showWithdrawDialog(item.statement_no)">
                取り下げ
              </v-btn>
            </v-row>
          </template>
        </v-data-table>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import feeCalculatorService from '@/services/feeCalculatorService';
import formatterutil from "@/util/formatter"
import downloadutil from "@/util/download"
import FeeCalculateHelper from '@/components/feeCalculate/FeeCalculateHelper.vue'
import RegisterCsvDialog from '@/components/feeCalculate/RegisterCsvDialog.vue'

export default {
  name: 'feestatements',
  title: '手数料明細:手数料明細書検索',
  data () {
    return {
      selected_page: 'feestatements',
      // 検索入力項目用
      input_supplier_cd: '',
      input_status: '',
      input_pdf_status: '',
      input_month: '',
      input_statement_no: '',

      // データ表示用
      options: {},
      totalcount: 0,
      itemsperpage: 20,
    
      headers: [
        { text: '明細書番号', align: 'start', sortable: false, value: 'statement_no' },
        { text: '発行日時', align: 'start', sortable: false, value: 'published_datetime_jst', formatter: formatterutil.datetimeJSTFormatter  },
        { text: '月度', align: 'start', sortable: false, value: 'cover_month' },
        { text: 'パートナー（仕入先CD/得意先CD）', align: 'start', sortable: false, value: 'partner_cds' },
        { text: '明細行数', align: 'start', sortable: false, value: 'line_number', formatter: formatterutil.numberFormatter},
        { text: '手数料金額', align: 'start', sortable: false, value: 'fee_amount', formatter: formatterutil.numberFormatter },
        { text: 'データステータス', align: 'start', sortable: false, value: 'status', formatter: formatterutil.feeStatementStatusFormatter },
        { text: 'PDFステータス', align: 'start', sortable: false, value: 'pdf_status', formatter: formatterutil.feeStatementPdfStatusFormatter },
        { text: 'ダウンロード', value: 'download'},
        { text: 'マニュアル作成', value: 'manual_file_download'},
        { text: 'アクション', width:400, value: 'action'},
      ],

      // 検索条件
      suppliers:[],
      status_list:[
        {value:"assigned", text:"明細書番号設定済"}, 
        {value:"ready", text:"データ作成済"},
        {value:"withdrawn", text:"取り下げ"},
        {value:"error", text:"処理エラー"},
      ],
      pdf_status_list:[
        {value:"in_progress", text:"作成中"}, 
        {value:"created", text:"作成済"},
        {value:"processing", text:"処理中"},
        {value:"error", text:"処理エラー"},
      ],
      statements_res: null,
      statements: [],

      // 画面操作用
      process_message:'test',
      process_message_type:'success',
      dialog: false,

      search_loading: false,
      search_btn_color: "light-blue darken-4",
      search_finished: false,
      input_month_menu: false,

      // マニュアル作成ファイルアップロード
      upload_dialog: false,
      upload_statement_no: null,
      upload_manual_pdf_file: null,
      upload_manual_excel_file: null,

      // 取り下げ
      withdraw_confirm_dialog: false,
      withdraw_statement_no: null,

      helperParam: {
        stepper_step: 1,
        helper_dialog: false,
      },

      /** CSV登録ダイアログ */
      uploadHelperParam: {
        /** ダイアログ表示可否 */
        visible: false,
      }
    }
  },
  components: {
    FeeCalculateHelper,
    RegisterCsvDialog
  },
  watch: {
    options: {
      handler () {
        this.getStatements()
      },
      deep: true,
    },
  },
  methods: {
    // 描画処理用メソッド
    openUploadDialog() {
      this.upload_dialog = true
    },

    
    // 検索用メソッド
    // 0. 手数料請求対象サプライヤ検索
    getTargetPartners () {
      feeCalculatorService.getTargetPartners().then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          return
        }

        this.suppliers = data
        this.search_finished=true
      })
    },

    // 1. 手数料明細一覧表示
    getStatements () {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""

      this.statements_res=null
      this.statements=[]

      let {page, itemsPerPage} = this.options

      const limit = itemsPerPage
      const offset = (page-1) * itemsPerPage

      let month = this.input_month.replace( '-', '' );
      let supplier_cd = this.input_supplier_cd
      let statement_no = this.input_statement_no
      let status = this.input_status
      let pdf_status = this.input_pdf_status

      feeCalculatorService.getStatements(
        month, supplier_cd, statement_no, status, pdf_status, limit, offset
        ).then(data => {

        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          this.search_loading = false
          this.search_finished=true

          return
        }

        // 取得データ用
        this.statements_res = data
        this.statements = this.statements_res.statements
        this.setProcessAvailability()
        this.totalcount = this.statements_res.total

        this.search_loading = false
        this.search_finished=true

      })

    },

    setProcessAvailability() {
      let statements = this.statements

      for (let item of statements) {

        // データ編集ボタン：readyで許可
        // PDF作成ボタン：readyで許可（すでにPDFファイルがあるレコードでは確認ダイアログ出す）
        // データ再作成ボタン：assigned, readyで許可
        // データDLボタン：ファイルが存在するときに許可
        // PDFDLボタン：ファイルが存在するときに許可
        // エクセルDLボタン：ファイルが存在するときに許可
        // ファイル登録：取り下げ以外で許可
        item.canCreateData = false;
        item.canEditData = false;
        item.canCreatePDF = false;
        item.canDownloadData = false;
        item.canDownloadPDF = false;
        item.canDownloadExcel = false;
        item.canUploadFile = false;

        if (item.status == "assigned"){
          item.canCreateData = true
        }
        if (item.status == "ready"){
          item.canCreateData = true
          item.canEditData = true
          item.canCreatePDF = true
        }
        if (item.status != "withdrawn"){
          item.canUploadFile = true
        }
        if (item.data_presigned_url != null){
          item.canDownloadData = true
        }
        if (item.pdf_presigned_url != null){
          item.canDownloadPDF = true
        }
        if (item.excel_presigned_url != null){
          item.canDownloadExcel = true
        }

      }

      this.statements = statements
      return

    },

    refreshSearch() {
      if(this.statements_res) {
        this.getStatements()
      }
      return
    },

    showWithdrawDialog(statement_no) {
      this.withdraw_statement_no = statement_no
      this.withdraw_confirm_dialog = true
      return
    },

    showUploadDialog(statement_no) {
      this.upload_statement_no = statement_no
      this.upload_manual_pdf_file = null
      this.upload_manual_excel_file = null
      this.upload_dialog = true
      return
    },
    
    // マニュアル作成ファイルアップロード
    uploadFiles() {
      
      feeCalculatorService.uploadManualFile(
        this.upload_statement_no, this.upload_manual_pdf_file, this.upload_manual_excel_file)      
        .then(data => {
        this.getStatements()
        this.process_message = data.detail
        this.process_message_type = "info"
        this.dialog = true

      })
      .catch((error) => {
        console.log(error)
        this.process_message = error.detail
        this.process_message_type = "error"
        this.dialog = true
      })
    },

    // データ編集窓を開く
    openDataEditWindow(statement_no) {
      let resolvedRoute = this.$router.resolve({
        name: "viewStatementDetail",
        params: {statement_no: statement_no}
      });
      // function(){window.open('リンク先のファイルパス','window1','width=ウィンドウの幅,height=ウィンドウの高さ,scrollbars=1');
      window.open(resolvedRoute.href, 'editwindow', 'width=1600, height=900,  scrollbars=1,resizable=1, menubar=0, toolbar=0');
    },

    // 明細書取り下げ
    withdrawStatement (statement_no) {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""
      this.withdraw_confirm_dialog = false

      if(!statement_no) {
        this.process_message = "明細書を指定してください"
        this.process_message_type = "error"
        this.dialog = true
        this.search_loading = false
        return
      }

      feeCalculatorService.withdrawStatement(statement_no).then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          this.search_loading = false
          return
        }

        this.refreshSearch()
        this.process_message = "明細書番号[" + statement_no + "]を取り下げました"
        this.process_message_type = "info"
        this.dialog = true
        this.search_loading = false

      })
    },

    // データ再作成
    resetStatementDetailData(statement_no, cover_month) {
      this.search_loading = true
      feeCalculatorService.resetStatementDetailData(statement_no, cover_month).then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          this.search_loading = false
          return
        }

        this.refreshSearch()
        this.process_message = "明細書番号[" + statement_no + "]の再作成を行い、PDFの再出力を開始しました"
        this.process_message_type = "info"
        this.dialog = true
        this.search_loading = false

      })
    },

    // PDF作成
    createStatementPDF(statement_no, cover_month) {
      this.search_loading = true
      feeCalculatorService.createStatementPDF(statement_no, cover_month)
      .then(data => {
        this.refreshSearch()
        this.process_message = data.detail
        this.process_message_type = "info"
        this.dialog = true
        this.search_loading = false

      })
      .catch((error) => {
        console.log(error)
        this.process_message = error.detail
        this.process_message_type = "error"
        this.dialog = true
        this.search_loading = false
      })
    },

    downloadFile(url, filename) {
      try{
        downloadutil.downloadFile(url, filename)
      } catch(error) {
        console.log(error)
      }
    },

    /** CSV アップロード成功後 */
    onUploadCsvSuccess(message) {
      this.process_message = message
      this.process_message_type = "info"
      this.dialog = true
    },

  },
  mounted () {
    const initial_statement_no = this.$route.query.statement_no
    if (initial_statement_no && initial_statement_no.length > 0) {
      this.input_statement_no = initial_statement_no
    }
    this.getTargetPartners()
    this.getStatements()
  },
}
</script>
