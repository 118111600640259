<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>
      <v-container fluid class="pa-5">
        <!-- サマリテーブル -->
        <v-data-table
            :headers="statement_headers"
            :items="statement"
            :items-per-page="-1"
            class="elevation-1 mb-5"
            hide-default-footer
            disable-filtering
            disable-sort
            dense
        >
        <!-- formatter適用 -->
        <template
            v-for="header in statement_headers.filter((header) =>
            header.hasOwnProperty('formatter') 
          )"
            v-slot:[`item.${header.value}`]="{ header, value }"
        >
          {{ header.formatter(value) }}
        </template>
        <template v-slot:item.download="{ item }">
            <v-row justify="center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                  v-if="item.canDownloadData" 
                  v-on="on"
                  v-bind="attrs"
                  v-on:click="downloadFile(
                    item.data_presigned_url, 
                    (item.data_file_name ? item.data_file_name : item.statement_no + '.json')
                    )"
                  color="primary">mdi-code-json</v-icon>
                </template>
                <span>JSONデータ</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                    v-if="item.canDownloadPDF" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.pdf_presigned_url, 
                      (item.pdf_file_name ? item.pdf_file_name : item.statement_no + '.pdf')
                      )"
                    color="primary">mdi-file-pdf-box </v-icon>
                </template>
                <span>PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    v-if="item.canDownloadExcel" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.excel_presigned_url, 
                      (item.excel_file_name ? item.excel_file_name : item.statement_no + '.xslx')
                      )"
                    color="primary">mdi-microsoft-excel </v-icon>
                </template>
                <span>EXCEL</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon medium class="ma-1"
                  v-if="(item.manual_pdf_presigned_url != null)" 
                  v-on="on"
                  v-bind="attrs"
                  v-on:click="downloadFile(
                    item.manual_pdf_presigned_url, 
                    (item.manual_pdf_file_name ? item.manual_pdf_file_name : item.statement_no + '_MANUAL.pdf')
                    )"
                  color="deep-orange">mdi-file-pdf-box</v-icon>
                </template>
                <span>マニュアル作成PDF</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon 
                    v-if="(item.manual_excel_presigned_url != null)" 
                    v-on="on"
                    v-bind="attrs"
                    v-on:click="downloadFile(
                      item.manual_excel_presigned_url, 
                      (item.manual_excel_file_name ? item.manual_excel_file_name : item.statement_no + '_MANUAL.xslx')
                      )"
                    color="deep-orange">mdi-microsoft-excel </v-icon>
                </template>
                <span>マニュアル作成EXCEL</span>
              </v-tooltip>
            </v-row>
          </template>
      </v-data-table>

      <!-- 明細テーブル -->
      <div id="list-table">
        <v-data-table
            :headers="headers"
            :items="records"
            :loading="search_loading"
            :items-per-page="-1"
            height="600"
            class="elevation-1 fixed-column-table"
            no-data-text="明細データがありません"
            hide-default-footer
            disable-filtering
            disable-sort
            fixed-header
            dense
        >
        <!-- テーブルヘッダ -->
        <thead>
          <tr>
            <th colspan="2" style="text-align: center">ID</th>
            <th colspan="7" style="text-align: center">当初受注</th>
            <th colspan="7" style="text-align: center">変キャン</th>
            <th colspan="2" style="text-align: center">最新金額</th>
            <th colspan="3" style="text-align: center">手数料方式</th>
            <th colspan="4" style="text-align: center">手数料金額</th>
            <th colspan="4" style="text-align: center">NEWTON伝票</th>
            <th colspan="4" style="text-align: center">比較金額</th>
          </tr>
          <tr>
            <th v-for="(h,index) in headers" :key="index">
              <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <span v-on="on">{{h.text}}</span>
              </template>
              <span>{{h.text}}</span>
            </v-tooltip>
            </th>
          </tr>
        </thead>
        <!-- eslint-disable vue/no-unused-vars -->
        <template v-for="(h,index) in headers" v-slot:[`header.${h.value}`]="{ header }">
          {{h.text}}
          <v-tooltip bottom :key="index" v-if="h.hint" color="success">
            <template v-slot:activator="{ on }">
              <v-icon small v-on="on" color="success">mdi-information</v-icon>
            </template>
            <span>{{h.hint}}</span>
          </v-tooltip>
        </template>
        <!-- eslint-enable -->

        <!-- formatter適用 -->
        <template
            v-for="(header, key) in headers.filter((header) =>
            header.hasOwnProperty('formatter') 
          )"
            v-slot:[`item.${header.value}`]="{ header, value }"
        >
          <span :key="key" v-if="[
            'irregular_type',
            'irregular_source',
            'fee_calculate_rule',
            'first_fee_in_order',
            ].includes(header.value)">
            {{ header.formatter(header.value, value) }} </span>
            <span v-else :key="key">{{ header.formatter(value) }} </span>
        </template>
      </v-data-table>

      </div>
      </v-container>
      <v-footer fixed>


        <v-spacer></v-spacer>

          <v-btn dark
            :color=btn_color
            class="ma-5"
            @click="closeWindow()">
            閉じる
          </v-btn>        

      </v-footer>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import feeCalculatorService from '@/services/feeCalculatorService';
import formatterutil from "@/util/formatter"
import downloadutil from "@/util/download"

export default {
  name: 'viewStatementDetail',
  title: '明細データ詳細',
  props: {
    statement_no: String,
  },
  data () {
    return {
      btn_color: "light-blue darken-4",

      // データ表示用
      statement_headers: [
        { text: '明細書番号', class: 'grey lighten-4', align: 'start', value: 'statement_no' },
        { text: 'パートナー', class: 'grey lighten-4', align: 'start', value: 'partner_cds' },
        { text: '月度', class: 'grey lighten-4', align: 'start', value: 'cover_month' },
        { text: '対象期間From', class: 'grey lighten-4', align: 'start', value: 'cover_date_from' },
        { text: '対象期間To', class: 'grey lighten-4', align: 'start', value: 'cover_date_to' },
        { text: '行数', class: 'grey lighten-4', align: 'start', value: 'line_number', formatter: formatterutil.numberFormatter },
        { text: '手数料金額', class: 'grey lighten-4', align: 'start', value: 'fee_amount', formatter: formatterutil.numberFormatter },
        { text: 'データステータス', class: 'grey lighten-4', align: 'start', value: 'status', formatter: formatterutil.feeStatementStatusFormatter },
        { text: 'データ作成日時', class: 'grey lighten-4', align: 'start', value: 'published_datetime_jst', formatter: formatterutil.datetimeJSTFormatter},
        { text: 'PDFステータス', class: 'grey lighten-4', align: 'start', value: 'pdf_status', formatter: formatterutil.feeStatementPdfStatusFormatter },
        { text: 'DL', class: 'grey lighten-4', align: 'start', value: 'download', formatter: formatterutil.feeStatementPdfStatusFormatter },
      ],

      headers: [
        // { text: '', align: 'start', value: 'id' },
        // { text: '', align: 'start', value: 'transaction_history_id' },
        // ID
        { text: '行番号', width:80, hint: '同一明細書番号内での連番', class: 'grey lighten-4', align: 'start', value: 'line_no' },
        { text: '履歴番号', width:80, hint: '異なるG#によるイレギュラー伝票含めた、受注時G#内の通番', class: 'grey lighten-4', align: 'start', value: 'mep_history_no'},
        // 当初受注
        { text: '仕入先コード', class: 'cyan lighten-5', align: 'start', value: 'supplier_cd' },
        { text: '得意先', class: 'cyan lighten-5', align: 'start', value: 'customer_cd'},
        { text: '受注時グローバルNo', class: 'cyan lighten-5', width:150, align: 'start', value: 'order_global_no'},
        { text: '当初型番', class: 'cyan lighten-5', width:180, align: 'start', value: 'initial_product_cd' },
        { text: '当初発注日', class: 'cyan lighten-5', width:80, align: 'start', value: 'initial_po_date'},
        { text: '当初受注金額', class: 'cyan lighten-5', width:100, align: 'end', value: 'initial_order_amount', formatter: formatterutil.numberFormatter},
        { text: '当初出荷日', class: 'cyan lighten-5', width:80, align: 'start', value: 'initial_vsd'},
        // 変キャン
        { text: '変更処理用グローバルNo.', class: 'amber lighten-5', width:150, align: 'start', value: 'irregular_global_no'},
        { text: '変更処理日', class: 'amber lighten-5', width:80, align: 'start', value: 'irregular_registered_date'},
        { text: '変キャン区分', class: 'amber lighten-5', width:80, hint: 'update / cancel', align: 'start', value: 'irregular_type', formatter: formatterutil.mepFeeStatementTranslateFormatter},
        { text: '変キャン起因', class: 'amber lighten-5', width:80, hint: 'confirmshippment / customer / partner / misumi / other', align: 'start', value: 'irregular_source', formatter: formatterutil.mepFeeStatementTranslateFormatter},
        { text: '変更後出荷日', class: 'amber lighten-5', width:80, align: 'start', value: 'irregular_vsd'},
        { text: '変更後受注金額', class: 'amber lighten-5', width:100, hint: '変更行のみ', align: 'end', value: 'irregular_order_amount', formatter: formatterutil.numberFormatter},
        { text: 'キャンセル料金額', class: 'amber lighten-5', width:100, hint: 'キャンセル行のみ', align: 'end', value: 'cancel_fee_amount', formatter: formatterutil.numberFormatter},
        // 最新金額
        { text: '最新受注金額', class: 'light-blue lighten-5', width:100, hint: 'キャンセルは含まない', align: 'end', value: 'latest_order_amount', formatter: formatterutil.numberFormatter},
        { text: '最新請求金額', class: 'light-blue lighten-5', width:100, hint: 'キャンセル料を含む', align: 'end', value: 'latest_invoice_amount', formatter: formatterutil.numberFormatter},
        // 手数料方式
        { text: '手数料計算対象金額', class: 'deep-orange lighten-5', width:100, align: 'end', value: 'fee_base_amount', formatter: formatterutil.numberFormatter},
        { text: '根拠', class: 'deep-orange lighten-5', align: 'start', value: 'fee_base_reason'},
        { text: '手数料計算方法', class: 'deep-orange lighten-5', hint: 'regular / zero / 20%', align: 'start', value: 'fee_calculate_rule', formatter: formatterutil.mepFeeStatementTranslateFormatter},
        // 手数料金額
        { text: '当該行だけでの手数料金額', class: 'indigo lighten-4', width:100, align: 'end', value: 'isolated_fee_amount', formatter: formatterutil.numberFormatter},
        { text: '過去の累積手数料請求額', class: 'indigo lighten-4', width:100, hint: '受注時グローバルNo.での計算', align: 'end', value: 'past_fee_amount', formatter: formatterutil.numberFormatter},
        { text: '当該行での手数料請求額', class: 'indigo lighten-4', width:100, align: 'end', value: 'line_fee_amount', formatter: formatterutil.numberFormatter},
        { text: '位置づけ', class: 'indigo lighten-4', width:80, align: 'start', value: 'first_fee_in_order', formatter: formatterutil.mepFeeStatementTranslateFormatter},
        // NEWTON伝票
        { text: '伝票グローバルNo', class: 'teal lighten-5', align: 'start', value: 'global_no'},
        { text: '伝票区分', class: 'teal lighten-5', align: 'start', value: 'slip_type'},
        { text: '削除', class: 'teal lighten-5', align: 'start', value: 'delete_flag'},
        { text: 'NEWTON金額', class: 'teal lighten-5', width:100, hint: 'マイナス伝票区分は負数変換、削除フラグは0変換', align: 'end', value: 'newton_calculate_amount', formatter: formatterutil.numberFormatter},
        // 比較金額
        { text: '比較手数料対象金額A', class: 'teal lighten-5', width:100, hint: '理由D：キャンセル前の対象金額、理由E：変更前の対象金額', align: 'end', value: 'compare_fee_base_a', formatter: formatterutil.numberFormatter},
        { text: '比較手数料金額A', class: 'teal lighten-5', width:100, hint: '比較手数料対象金額A * 通常料率', align: 'end', value: 'compare_fee_amount_a', formatter: formatterutil.numberFormatter},
        { text: '比較手数料対象金額B', class: 'teal lighten-5', width:100, hint: '理由D：キャンセル料、理由E：変更後の対象金額', align: 'end', value: 'compare_fee_base_b', formatter: formatterutil.numberFormatter},
        { text: '比較手数料金額B', class: 'teal lighten-5', width:100, hint: '比較手数料対象金額B * （理由Dなら20％、理由Eなら通常料率）', align: 'end', value: 'compare_fee_amount_b', formatter: formatterutil.numberFormatter},
      ],

      statements_res: null,
      statement: [],
      records: [],

      // 画面操作用
      search_loading: false,
      process_message:'',
      process_message_type:'',
      dialog: false,

      // 編集用
      irregular_type_selects: [
        { text: "変更", value: "update" },
        { text: "キャンセル", value: "cancel" },
      ],
      irregular_source_selects: [
        { text: "出荷日確定", value: "confirmshippment" },
        { text: "顧客", value: "customer" },
        { text: "パートナー", value: "partner" },
        { text: "ミスミ", value: "misumi" },
        { text: "その他", value: "other" },
      ],
      fee_base_reason_select: [
        { text: "A", value: "A" },
        { text: "B", value: "B" },
        { text: "C", value: "C" },
        { text: "D1", value: "D1" },
        { text: "D2", value: "D2" },
        { text: "E", value: "E" },
        { text: "F", value: "F" },
      ],
      fee_calculate_rule_select: [
        { text: "通常", value: "regular" },
        { text: "0%", value: "zero" },
        { text: "20%", value: "20%" },
      ],
      first_fee_in_order_select: [
        { text: "通常請求", value: "1" },
        { text: "差額請求", value: "0" },
      ],

    }
  },

  methods: {

    // 手数料明細データ取得
    getStatementAndDetail () {
      this.search_loading = true
      this.process_message=""
      this.process_message_type=""


      feeCalculatorService.getStatementAndDetail(this.statement_no).then(data => {
        if ("error" in data) {
          this.process_message = data.detail
          this.process_message_type = "error"
          this.dialog = true
          return
        }

        // 取得データ用
        this.statements_res = data
        this.statement = [this.statements_res.statement]
        this.setProcessAvailability()
        this.records = this.statements_res.records


        this.search_loading = false
      })
    },

    setProcessAvailability() {
      let statement = this.statement[0]

      // データ編集ボタン：readyで許可
      // PDF作成ボタン：readyで許可（すでにPDFファイルがあるレコードでは確認ダイアログ出す）
      // データ再作成ボタン：assigned, readyで許可
      // データDLボタン：ファイルが存在するときに許可
      // PDFDLボタン：ファイルが存在するときに許可
      // エクセルDLボタン：ファイルが存在するときに許可
      // ファイル登録：取り下げ以外で許可
      statement.canCreateData = false;
      statement.canEditData = false;
      statement.canCreatePDF = false;
      statement.canDownloadData = false;
      statement.canDownloadPDF = false;
      statement.canDownloadExcel = false;
      statement.canUploadFile = false;

      if (statement.status == "assigned"){
        statement.canCreateData = true
      }
      if (statement.status == "ready"){
        statement.canCreateData = true
        statement.canEditData = true
        statement.canCreatePDF = true
      }
      if (statement.status != "withdrawn"){
        statement.canUploadFile = true
      }
      if (statement.data_presigned_url != null){
        statement.canDownloadData = true
      }
      if (statement.pdf_presigned_url != null){
        statement.canDownloadPDF = true
      }
      if (statement.excel_presigned_url != null){
        statement.canDownloadExcel = true
      }

      this.statement[0] = statement
      return

    },

    downloadFile(url, filename) {
      try{
        downloadutil.downloadFile(url, filename)
      } catch(error) {
        console.log(error)
      }
    },

    closeWindow(){
      window.close()
    }

  },
  mounted () {
    this.getStatementAndDetail()
  },
}
</script>
<style lang="sass">
.fixed-column-table th:nth-child(1)
  position: sticky !important
  position: -webkit-sticky !important
  left: 0
  z-index: 9999 !important
  background: white
  border-right: 1px gray solid
.fixed-column-table tr > td:nth-child(1)
  position: sticky !important
  position: -webkit-sticky !important
  left: 0
  z-index: 9999 !important
  background: white
  border-right: 1px gray solid
.fixed-column-table tr > td
  white-space: nowrap
  max-width: 200px
  overflow: hidden
  text-overflow: ellipsis
</style>
<style>
  .v-input {
    font-size: 0.9em;
  }

  .v-text-field input {
    font-size: 1.2em;
  }

  </style>