<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card flat width="100%">
        <v-card-title>
          顧客案件状態一覧
          <v-spacer></v-spacer>
          <v-row justify="end">
            <v-card flat>
              <v-spacer></v-spacer>
              <v-btn v-on:click="downloadUsertrackCsv('usertrack')"
                     class="mr-2 mt-2"
                     outlined
                     color="indigo" small
              >
                <v-icon class="mr-1" small>mdi-file-table-outline</v-icon>
                顧客案件状態一覧CSV
              </v-btn>
              <v-btn v-on:click="downloadUsertrackCsv('settingspec')"
                     class="mr-2 mt-2"
                     outlined
                     color="indigo" small
              >
                <v-icon class="mr-1" small>mdi-file-table-outline</v-icon>
                仕様設定中一覧CSV
              </v-btn>
            </v-card>
          </v-row>
        </v-card-title>
          <template>
            <v-alert
                border="left"
                colored-border
                type="info"
                elevation="2"
                dense
            >
              検索条件・ソート順指定後、検索ボタンを押下して下さい。
            </v-alert>
            <v-row dense>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_qt_status"
                      outlined placeholder="見積ステータス"
                      clearable
                      :items="qtstatus" item-value="value" item-text="text">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_so_status"
                      outlined placeholder="注文ステータス"
                      clearable
                      :items="sostatus" item-value="text" item-text="text">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_customer_code"
                      outlined placeholder="得意先"
                      clearable
                      :items="customers"
                      item-value="customer_code"
                      :item-text="item => (item.customer_code === 'ec_member' ? '' : '[' + item.customer_code + ']') + item.ntv_customer_name1">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                    dense
                    v-model="input_user_cd"
                    outlined placeholder="担当者"
                    clearable
                    :items="users"
                    item-value="user_cd"
                    :item-text="item => `${item.client_name} [${item.customer_name}]`"
                  >
                    <template v-slot:item="{item}">
                      <span style="min-width: 6em;">{{ item.client_name }}</span>
                      <span style="padding-left: 1em;">[{{ item.customer_name }}]</span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_service_category_code"
                      outlined placeholder="サービスカテゴリ"
                      clearable
                      :items="servicecategories"
                      :item-value="item => item.qt_condition_category_code"
                      :item-text="item => item.qt_condition_category">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_servicecard_id"
                      outlined placeholder="サービスカード"
                      clearable
                      :items="servicecards" item-value="service_card_id" item-text="service_card_title">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                      dense
                      v-model="input_product_part_number"
                      outlined placeholder="型番"
                      clearable>
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-autocomplete
                      dense
                      v-model="input_sort_by"
                      outlined placeholder="並び順"
                      clearable
                      :items="sortby"
                      item-value="value" item-text="text">
                  </v-autocomplete>
                </v-col>
                <v-col>
                  <v-btn :outlined=search_btn_outline
                         :color=search_btn_color
                         class="ml-5"
                         @click="setFilterData();getListDataFromApi(true)">
                    <v-icon class="mr-1">mdi-magnify</v-icon>
                    検索</v-btn>
                </v-col>
            </v-row>
          </template>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="usertracks"
          :items-per-page="itemsperpage"
          :search="search"
          :loading="loading"
          :options.sync="options"
          :server-items-length="totalcount"
          :footer-props = "{ 'items-per-page-text':'行/ページ:',
             'items-per-page-options': [10,20,25,50,100]}"
          show-expand
          single-expand
          class="elevation-1"
          item-key="id"
          disable-sort
        >
          <template
            v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
            v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.project_name="{ item }">
            <v-icon v-if="item.project_user_visibility=='削除済'" color="red" medium>mdi-delete-circle-outline</v-icon>
            {{ item.project_name }}
          </template>
          <template v-slot:item.cad_file_id="{ item }">
            <v-icon v-if="item.cad_file_id" v-on:click="downloadCad(item.cad_file_id, item.cad_file_name)" color="primary" medium>mdi-cloud-download-outline</v-icon>
          </template>
          <template v-slot:item.qt_status="{ item }">
            <v-chip v-if="item.qt_status=='仕様設定中'" color="red darken-3" outlined class="ma-0" small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status=='見積中'" color="success" class="ma-0" small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status=='見積完了'" color="cyan" class="ma-0" dark small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status=='見積不可'" color="red lighten-1" class="ma-0" small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status=='型番確定済'" color="blue darken-4" class="ma-0" dark small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status=='キャンセル'" color="blue-grey" class="ma-0" dark small>{{item.qt_status}}</v-chip>
            <v-chip v-else-if="item.qt_status" class="ma-2" small>{{item.qt_status}}</v-chip>
          </template>

          <template v-slot:item.mep_order_status="{ item }">
            <v-chip v-if="item.mep_order_status=='注文確定'" color="red darken-3" outlined class="ma-0" small>{{item.mep_order_status}}</v-chip>
            <v-chip v-else-if="item.mep_order_status=='製造中'" color="success" class="ma-0" small>{{item.mep_order_status}}</v-chip>
            <v-chip v-else-if="item.mep_order_status=='出荷済'" color="blue darken-4" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
            <v-chip v-else-if="item.mep_order_status=='キャンセル'" color="blue-grey" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
            <v-chip v-else-if="item.mep_order_status=='変更またはキャンセル'" color="blue-grey" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
            <v-chip v-else-if="item.mep_order_status" class="ma-2" small>{{item.mep_order_status}}</v-chip>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">

              <v-container fluid>
                <v-row dense>
                  <v-col>

              <v-card class="mx-auto" max-width="370" elevation="1" outlined>
                <v-card-text>
                  <div>顧客</div>
                  <v-simple-table dense class="pb-10">
                  <tbody>
                    <tr><td>顧客id:</td><td>{{ item.user_id }}</td></tr>
                    <tr><td>得意先CD:</td><td>{{ item.customer_code }}</td></tr>
                    <tr><td>得意先名:</td><td>{{ item.ntv_customer_name1 }}</td></tr>
                    <tr><td>ユーザCD:</td><td>{{ item.user_cd }}</td></tr>
                    <tr><td>担当者名:</td><td>{{ item.client_name }}</td></tr>
                    <tr><td>電話番号:</td><td>{{ item.user_tel }}</td></tr>
                    <tr><td>最終ログイン日:</td><td>{{ item.user_last_login_at_jst }}</td></tr>
                  </tbody>
                  </v-simple-table>
                  <div>プロジェクト</div>
                  <v-simple-table dense class="pb-10">
                  <tbody>
                    <tr><td>プロジェクトid:</td><td>{{ item.project_id }}</td></tr>
                    <tr><td>プロジェクト名:</td><td>{{ item.project_name }}</td></tr>
                    <tr><td>削除:</td><td><v-icon v-if="item.project_user_visibility=='削除済'" color="red" medium>mdi-delete-circle-outline</v-icon>
                      {{ item.project_user_visibility }}</td></tr>
                    <tr><td>プロジェクト作成:</td><td>{{ item.project_created_at_jst }}</td></tr>
                  </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
                  </v-col>
                  <v-col>
              <v-card class="mx-auto" max-width="370" elevation="1" outlined>
                <v-card-text>
                  <div>見積依頼</div>
                  <v-simple-table dense>
                  <tbody>
                    <tr><td>パートナー:</td><td>{{ item.supplier_name }}</td></tr>
                    <tr><td>サービスカード:</td><td>{{ item.service_card_title }}</td></tr>
                    <tr><td>CADファイルID:</td><td>{{ item.cad_file_id }}</td></tr>
                    <tr><td>CADファイル名:</td><td>{{ item.cad_file_name }}</td></tr>
                    <tr><td>見積ステータス:</td><td>
                      <v-chip v-if="item.qt_status=='仕様設定中'" color="red darken-3" outlined class="ma-2" small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status=='見積中'" color="success" class="ma-2" small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status=='見積完了'" color="cyan" class="ma-2" dark small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status=='見積不可'" color="red lighten-1" class="ma-2" small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status=='型番確定済'" color="blue darken-4" class="ma-2" dark small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status=='キャンセル'" color="blue-grey" class="ma-2" dark small>{{item.qt_status}}</v-chip>
                      <v-chip v-else-if="item.qt_status" class="ma-2" small>{{item.qt_status}}</v-chip>
                    </td></tr>
                    <tr><td>見積受領日時:</td><td>{{ item.qt_requested_at_jst }}</td></tr>
                    <tr><td>Version:</td><td>{{ item.qt_condition_name }}</td></tr>
                    <tr><td>見積依頼数量:</td><td>{{ item.qt_condition_quantity }}</td></tr>
                    <tr><td>カテゴリ:</td><td>{{ item.qt_condition_category }}</td></tr>
                    <tr><td>用途:</td><td>{{ item.qt_condition_usage }}</td></tr>
                    <tr><td>材料:</td><td>{{ item.qt_condition_material }}</td></tr>
                    <tr><td>工法:</td><td>{{ item.qt_condition_method }}</td></tr>
                    <tr><td>後処理:</td><td>{{ item.qt_condition_postprocessing }}</td></tr>
                    <tr><td>後加工:</td><td>{{ item.qt_condition_finishing }}</td></tr>
                    <tr><td>プリンター:</td><td>{{ item.qt_condition_printer }}</td></tr>
                  </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
                  </v-col>
                  <v-col>
              <v-card class="mx-auto" max-width="370" elevation="1" outlined>
                <v-card-text >
                  <div>見積回答</div>
                  <v-simple-table dense class="pb-10">
                  <tbody>
                    <tr><td>初回応答時間:</td><td>{{ item.qt_time_to_first_answer }}</td></tr>
                    <tr><td>回答日時:</td><td>{{ item.qt_answered_at_jst }}</td></tr>
                    <tr><td>回答時間:</td><td>{{ item.qt_time_to_answer }}</td></tr>
                    <tr><td>スペックID:</td><td>{{ item.spec_id }}</td></tr>
                    <tr><td>単価:</td><td>{{ item.answer_price_amount }}</td></tr>
                    <tr><td>実働納期（MIN):</td><td>{{ item.answer_delivery_min }}</td></tr>
                    <tr><td>実働納期（MAX）:</td><td>{{ item.answer_delivery_max }}</td></tr>
                  </tbody>
                  </v-simple-table>
                  <div>受注</div>
                  <v-simple-table dense class="pb-10">
                  <tbody>
                    <tr><td>注文ステータス:</td><td>
                      <v-chip v-if="item.mep_order_status=='注文確定'" color="red darken-3" outlined class="ma-0" small>{{item.mep_order_status}}</v-chip>
                      <v-chip v-else-if="item.mep_order_status=='製造中'" color="success" class="ma-0" small>{{item.mep_order_status}}</v-chip>
                      <v-chip v-else-if="item.mep_order_status=='出荷済'" color="blue darken-4" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
                      <v-chip v-else-if="item.mep_order_status=='キャンセル'" color="blue-grey" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
                      <v-chip v-else-if="item.mep_order_status=='変更またはキャンセル'" color="blue-grey" class="ma-0" dark small>{{item.mep_order_status}}</v-chip>
                      <v-chip v-else-if="item.mep_order_status" class="ma-2" small>{{item.mep_order_status}}</v-chip>
                    </td></tr>
                    <tr><td>受注日時:</td><td>{{ item.order_accepted_at_jst }}</td></tr>
                    <tr><td>受注単価（税抜）:</td><td>{{ item.sales_price_amount }}</td></tr>
                    <tr><td>合計金額（税抜）:</td><td>{{ item.sales_total_amount }}</td></tr>
                    <tr><td>グローバルナンバー:</td><td>{{ item.global_no }}</td></tr>
                    <tr><td>型番:</td><td>{{ item.product_part_number }}</td></tr>
                    <tr><td>お客様注文番号:</td><td>{{ item.customer_reference_number }}{{ item.customer_sub_reference_number }}</td></tr>
                    <tr><td>出荷日:</td><td>{{ item.confirmed_shipment_date }}</td></tr>
                    <tr><td>送り状番号:</td><td>{{ item.invoice_info }}</td></tr>
                  </tbody>
                  </v-simple-table>
                </v-card-text>
              </v-card>
              </v-col>
              </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import usertrackService from '../services/usertrackService'
import { mdiDeleteCircleOutline, mdiCloudDownloadOutline } from '@mdi/js'
import axios from 'axios'
import { saveAs } from 'file-saver';
import { API, graphqlOperation } from 'aws-amplify'
import moment from "moment";
import formatterutil from "@/util/formatter"

export default {
  name: 'usertrack',
  title: '顧客案件状態一覧',
  data () {
    return {
      email: this.$store.state.user.attributes.email,
      search: '',
      headers: [],
      usertracks: [],
      detailusertrack: [],
      customers: [],
      all_users: [],
      servicecategories: [],
      servicecards: [],
      allstatus: [],
      deleteDialog: false,
      icons: {
        mdiDeleteCircleOutline,
        mdiCloudDownloadOutline,
      },
      options: {},
      totalcount: 0,
      itemsperpage: 25,
      loading: true,
      input_qt_status: '',
      input_so_status: '',
      input_customer_code: '',
      input_user_cd: '',
      input_service_category_code: '',
      input_servicecard_id:'',
      input_product_part_number: '',
      input_sort_by:'user_last_login_at_jst desc',
      filter_qt_status: '',
      filter_so_status: '',
      filter_customer_code: '',
      filter_user_cd: '',
      filter_service_category_code: '',
      filter_servicecard_id:'',
      filter_product_part_number: '',
      sort_by:'user_last_login_at_jst desc',
      qtstatus: [
        {value: 'SettingSpecification', text: '仕様設定中'},
        {value: 'Quoting', text:'見積中'},
        {value: 'QuotationFinished', text:'見積完了'},
        {value: 'FixedModelNumber', text:'型番確定済'},
        {value: 'WrongQuoted', text:'見積不可'},
        {value: 'Canceled', text:'キャンセル'},],
      sostatus: [
        {text: '注文確定'},
        {text:'製造中'},
        {text:'出荷済'},
        {text:'キャンセル'},
        {text:'変更またはキャンセル'},],
      sortby: [
        {value: 'user_last_login_at_jst asc', text:'最終ログイン - 昇順'},
        {value: 'user_last_login_at_jst desc', text:'最終ログイン - 降順'},
        {value: 'project_created_at_jst asc', text:'プロジェクト作成 - 昇順'},
        {value: 'project_created_at_jst desc', text:'プロジェクト作成 - 降順'},
        {value: 'qt_time_to_first_answer asc', text:'初回応答時間 - 昇順'},
        {value: 'qt_time_to_first_answer desc', text:'初回応答時間 - 降順'},
        {value: 'qt_time_to_answer asc', text:'回答時間 - 昇順'},
        {value: 'qt_time_to_answer desc', text:'回答時間 - 降順'},
        {value: 'order_accepted_at_jst asc', text:'受注日時 - 昇順'},
        {value: 'order_accepted_at_jst desc', text:'受注日時 - 降順'},
        {value: 'confirmed_shipment_date asc', text:'確定出荷日 - 昇順'},
        {value: 'confirmed_shipment_date desc', text:'確定出荷日 - 降順'},],
      switch1: true,
      search_btn_color: "light-blue darken-4",
      search_btn_outline: true,
      search_finished: false,
    }
  },
  watch: {
    options: {
      handler () {
        if(!this.loading) {
          this.getListDataFromApi(false)
        }
      },
      deep: true,
    },
    input_qt_status: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    input_so_status: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    input_customer_code: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
      this.resetInputUserCdIfNeeded()
    },
    input_user_cd: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    input_service_category_code: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
      this.getServiceCards(this.input_service_category_code)
    },
    input_servicecard_id: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    input_product_part_number: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    input_sort_by: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
  },
  computed: {
    users: function () {
      const 
      customers = this.customers,
      input_customer_code = this.input_customer_code

      if (!customers.length) return []

      const filtered_users = input_customer_code ? this.all_users.filter(user => user.customer_code === input_customer_code) : this.all_users

      const formatted_users = filtered_users.map(function(user) {
        const customer = customers.find(customer => customer.customer_code === user.customer_code)
        return {
          user_cd: user.user_cd,
          client_name: user.client_name,
          customer_name: customer ? customer.ntv_customer_name1 : ''
        }
      })

      return formatted_users
    }
  },
  methods: {
    deleteFormatter (value, deletevalue) {
      if(deletevalue == '削除済'){
        return value + '(削除済)';
      } else {
        return value;
      }
    },
    datetimeHmsFormatter (value) {
      if (value) {
        return moment(value).format('YY/MM/DD HH:mm:ss');
      } else {
        return value;
      }
    },
    datetimeHmFormatter (value) {
      if (value) {
        return moment(value).format('YY/MM/DD HH:mm');
      } else {
        return value;
      }
    },
    dateFormatter (value) {
      if (value) {
        return moment(value).format('YY/MM/DD');
      } else {
        return value;
      }
    },

    async getDetailusertrack(usertrack){
      return [
        {user_id:usertrack.user_id,
          customer_code:usertrack.customer_code,
          ntv_customer_name1:usertrack.ntv_customer_name1,
          user_cd:usertrack.user_cd,
          client_name:usertrack.client_name,
          user_last_login_at_jst:usertrack.user_last_login_at_jst,
        },
        {
          project_id:usertrack.project_id,
          project_name:usertrack.project_name,
          project_user_visibility:usertrack.project_user_visibility,
          project_created_at_jst:usertrack.project_created_at_jst,
        },
        {
          service_card_title:usertrack.service_card_title,
          cad_file_id:usertrack.cad_file_id,
          cad_file_name:usertrack.cad_file_name,
          qt_status:usertrack.qt_status,
          qt_requested_at_jst:usertrack.qt_requested_at_jst,
          qt_condition_name:usertrack.qt_condition_name,
          qt_condition_quantity:usertrack.qt_condition_quantity,
          qt_condition_category:usertrack.qt_condition_category,
          qt_condition_usage:usertrack.qt_condition_usage,
          qt_condition_material:usertrack.qt_condition_material,
          qt_condition_method:usertrack.qt_condition_method,
          qt_condition_postprocessing:usertrack.qt_condition_postprocessing,
          qt_condition_finishing:usertrack.qt_condition_finishing,
          qt_condition_printer:usertrack.qt_condition_printer,
        },
        {
          qt_time_to_first_answer:usertrack.qt_time_to_first_answer,
          qt_answered_at_jst:usertrack.qt_answered_at_jst,
          qt_time_to_answer:usertrack.qt_time_to_answer,
          spec_id:usertrack.spec_id,
          answer_price_amount:usertrack.answer_price_amount,
          answer_delivery_min:usertrack.answer_delivery_min,
          answer_delivery_max:usertrack.answer_delivery_max,
        },
        {
        
          order_accepted_at_jst:usertrack.order_accepted_at_jst,
          sales_price_amount:usertrack.sales_price_amount,
          sales_total_amount:usertrack.sales_total_amount,
          global_no:usertrack.global_no,
          product_part_number:usertrack.product_part_number,
          customer_reference_number:usertrack.customer_reference_number,
          customer_sub_reference_number:usertrack.customer_sub_reference_number,
          confirmed_shipment_date:usertrack.confirmed_shipment_date,
        }
      ]
    },
    async getCadFilePresignedUrl (cad_file_id) {
        const getCadFilePresignedUrlQuery = `query getCadFilePresignedUrl($cad_file_id:String) {
          getCadFilePresignedUrl(cad_file_id:$cad_file_id) {
            cad_file_id
            cad_file_url
          }
        }`
        const params = {
              cad_file_id: cad_file_id
        }
        const presignedurl = await API.graphql(graphqlOperation(getCadFilePresignedUrlQuery, params))
        const file_url = presignedurl.data.getCadFilePresignedUrl.cad_file_url

        return file_url
    },
    async downloadCad(cad_file_id, cad_file_name) {

      const presignedurl= await this.getCadFilePresignedUrl(cad_file_id)

      const file_name = cad_file_id + "_" + cad_file_name
      const response = await axios.get(presignedurl, {responseType:'blob'})
                      .catch((error) => {
                          console.log(error)
                      })
      if (response && (200 == response.status)) {
          saveAs(response.data, file_name)
      }
    },
    async getUsertrackFilePresignedUrl (filetype) {
        const getLatestUsertrackCsvsQuery = `query getLatestUsertrackCsvs {
          getLatestUsertrackCsvs {
            usertrack_file_url
            settingspec_file_url
          }
        }`
        const presignedurl = await API.graphql(graphqlOperation(getLatestUsertrackCsvsQuery))
        let file_url = ''
        
        if(filetype == 'usertrack') {
          file_url = presignedurl.data.getLatestUsertrackCsvs.usertrack_file_url
        } else if (filetype == 'settingspec') {
          file_url = presignedurl.data.getLatestUsertrackCsvs.settingspec_file_url
        }
        return file_url
    },
    async downloadUsertrackCsv(filetype) {

      try{
        const presignedurl= await this.getUsertrackFilePresignedUrl(filetype)
        const response = await axios.get(presignedurl, {responseType:'blob'})
                        .catch((error) => {
                            console.log(error)
                        })
        const filename = presignedurl.match(".+/(.+?)([?#;].*)?$")[1];

        if (response && (200 == response.status)) {
            saveAs(response.data, filename)
        }
        
      } catch(error) {
        console.log(error)
      }
    },
    setFilterData() {
      this.filter_qt_status = this.input_qt_status
      this.filter_so_status= this.input_so_status
      this.filter_customer_code = this.input_customer_code
      this.filter_user_cd = this.input_user_cd
      this.filter_service_category_code = this.input_service_category_code
      this.filter_servicecard_id = this.input_servicecard_id
      this.filter_product_part_number = this.input_product_part_number
      this.sort_by = this.input_sort_by
    },

    getListDataFromApi (searchFromFirstPage) {
      this.loading = true
      if(searchFromFirstPage) {
        this.options.page = 1
      }

      let {page, itemsPerPage} = this.options

      const limit = itemsPerPage
      const offset = (page - 1) * itemsPerPage

      const paramQtStatus = this.filter_qt_status
      const paramSoStatus= this.filter_so_status
      const paramCustomerId = this.filter_customer_code
      const paramUserCd = this.filter_user_cd
      const paramServiceCategoryCode = this.filter_service_category_code
      const paramServicecardId = this.filter_servicecard_id
      const paramProductPartNumber = this.filter_product_part_number
      const paramSortBy = this.sort_by

      usertrackService.getUsertracks(limit, offset, paramSortBy, paramQtStatus, paramSoStatus, paramCustomerId, paramUserCd, paramServiceCategoryCode, paramServicecardId, paramProductPartNumber).then(data => {
        this.usertracks = data.usertrack
        this.totalcount = data.listcount.total
        this.loading = false
        this.search_btn_color="light-blue darken-4"
        this.search_btn_outline=true
        this.search_finished=true
      })
    },

    resetInputUserCdIfNeeded() {
      const input_user_cd = this.input_user_cd
      if (input_user_cd && !this.users.some(user => user.user_cd === input_user_cd)) this.input_user_cd = ''
    },

    createServiceCardList(categoryServiceCards) {
      let serviceCardList = []
      for(let categoryList of categoryServiceCards) {
        for(let serviceCard of categoryList.service_cards) {
          serviceCardList.push(serviceCard)
          }
      }
      return serviceCardList
    },

    async getServiceCards(serviceCategoryCode) {
      const categoryServiceCards = await usertrackService.getUsertrackCategoryServiceCards(serviceCategoryCode)
      this.servicecards = this.createServiceCardList(categoryServiceCards)
    }, 

    async getCategoryServiceCards() {
      const categoryServiceCards = await usertrackService.getUsertrackCategoryServiceCards()
      this.servicecards = this.createServiceCardList(categoryServiceCards)

      let serviceCategoryList = []
      let serviceCategoryObject = {}
      for (let categoryServiceCard of categoryServiceCards) {
        Object.keys(categoryServiceCard).forEach((key, index) => {
          if (key === "qt_condition_category" || key === "qt_condition_category_code") {
            serviceCategoryObject[key] = categoryServiceCard[key]
          }
          if (index === (Object.keys(categoryServiceCard).length - 1)) {
            serviceCategoryList.push(serviceCategoryObject)
            serviceCategoryObject = {}
          }
        })
      }
      this.servicecategories = serviceCategoryList
    },
  },
  async mounted () {
    this.search = ''
    this.getListDataFromApi(true)
    this.headers =  [
      { text: 'id', value: 'id' },
      { text: '得意先名', value: 'ntv_customer_name1', width: "8%" },
      { text: '担当者名', value: 'client_name', width: "8%" },
      { text: '最終ログイン', value: 'user_last_login_at_jst', formatter: this.datetimeHmFormatter },
      { text: 'プロジェクト名', value: 'project_name', width: "10%"},
      { text: 'プロジェクト作成', value: 'project_created_at_jst', formatter: this.datetimeHmsFormatter},
      { text: 'サービスカテゴリ', value: 'qt_condition_category', width: "5%" },
      { text: 'パートナー', value: 'supplier_name', width: "8%" },
      { text: 'サービスカード', value: 'service_card_title', width: "10%" },
      { text: '見積ステータス', value: 'qt_status' },
      { text: '初回応答時間', value: 'qt_time_to_first_answer', formatter: formatterutil.secondsToDatetimeFormatter },
      { text: '回答時間', value: 'qt_time_to_answer', formatter: formatterutil.secondsToDatetimeFormatter },
      { text: '注文ステータス', value: 'mep_order_status' },
      { text: '受注日時', value: 'order_accepted_at_jst', formatter: this.dateFormatter },
      { text: '確定出荷日', value: 'confirmed_shipment_date', formatter: this.dateFormatter },
      { text: 'CADファイル', value: 'cad_file_id' },
      { text: '', value: 'data-table-expand' },
    ]
    this.customers.push(
      { customer_code: 'ec_member', ntv_customer_name1: 'EC会員' },
      ...await usertrackService.getUsertrackCustomers()
    )
    this.all_users = await usertrackService.getUsertrackUsers()
    await this.getCategoryServiceCards()
  }
}
</script>