/* eslint-disable no-console */
import { API, Auth } from 'aws-amplify'
import axios from "axios";

const STATEMENT_FORMAT_VERSION = "10"

const postCreatePDF = (myInit) => {
  console.log(myInit)
  return new Promise((resolve, reject) => {
    API.post('pdf-creator', '/v1/fee', myInit)
    .then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("pdf-creator POST failed.")
    })
  })
}

const updatePartnerFeeFlag = (id, flag, myInit) => {
  console.log(myInit)
  return new Promise((resolve, reject) => {
    API.put('feecalc', '/api/v1/partner/' + id + '/' + flag, myInit)
    .then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("update partner fee flag PUT failed.")
    })
  })
}

const getUploadPresignedUrls = (myInit) => {
  console.log(myInit)
  return new Promise((resolve, reject) => {
    API.get('feecalc', '/api/v1/fee_statement/upload_url', myInit)
    .then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("pdf-creator POST failed.")
    })
  })
}

const setManualFiles = (myInit, statement_no) => {
  console.log(myInit)
  return new Promise((resolve, reject) => {
    API.put('feecalc', '/api/v1/fee_statement/manual/' + statement_no, myInit)
    .then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("pdf-creator POST failed.")
    })
  })
}

const clearPdf = (myInit, statement_no) => {
  console.log(myInit)
  return new Promise((resolve, reject) => {
    API.put('feecalc', '/api/v1/fee_statement/clearpdf/' + statement_no, myInit)
    .then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("clear pdf info failed.")
    })
  })
}

const uploadFile = (url, file) => {
  return new Promise((resolve, reject) => {
    axios.put(
      url,
      file,
      {
        headers: {
          'Content-Type': file.type,
        },
      }
    ).then(res=>{
      return resolve(res)
    }).catch(error => {
      console.log(error)
      return reject("file upload failed." + error.message)
    })
  })
}

export default {

  async getTargetPartners () {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const res = await API.get('feecalc', '/api/v1/partner/targets', myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },

  async searchPartners (company_name, supplier_cd, customer_cd, mep_partner_sales_fee_target) {

    let queryStringParameters = {}

    if (company_name) {
      queryStringParameters.company_name = company_name
    }
    if (supplier_cd) {
      queryStringParameters.supplier_cd = supplier_cd
    }
    if (customer_cd) {
      queryStringParameters.customer_cd = customer_cd
    }
    if (mep_partner_sales_fee_target) {
      queryStringParameters.mep_partner_sales_fee_target = mep_partner_sales_fee_target
    }

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryStringParameters
    };

    const res = await API.get('feecalc', '/api/v1/partner/search', myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },

  async getTransactionHistory (supplier_cd, cover_month) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const res = await API
      .get('feecalc', '/api/v1/transaction_history/monthly/' + supplier_cd + '/' + cover_month, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },

  async getTransaction (supplier_cd, global_no, product_cd, statement_no, cover_month, update_datetime_jst_date_from, update_datetime_jst_date_to, limit) {

    let queryStringParameters = {limit: limit}
    if (supplier_cd) {
      queryStringParameters.supplier_cd = supplier_cd
    }
    if (global_no) {
      queryStringParameters.global_no = global_no
    }
    if (product_cd) {
      queryStringParameters.product_cd = product_cd
    }
    if (statement_no) {
      queryStringParameters.statement_no = statement_no
    }
    if (cover_month) {
      queryStringParameters.cover_month = cover_month
    }
    if (update_datetime_jst_date_from) {
      queryStringParameters.update_datetime_jst_date_from = update_datetime_jst_date_from
    }
    if (update_datetime_jst_date_to) {
      queryStringParameters.update_datetime_jst_date_to = update_datetime_jst_date_to
    }

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryStringParameters
    };

    const res = await API
      .get('feecalc', '/api/v1/transaction_history/transaction', myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },
  
  async getUnpublished (supplier_cd, limit) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const res = await API
      .get('feecalc', '/api/v1/transaction_history/unpublished/' + supplier_cd + '/' + limit, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },

  //updatePartnerFeeFlag
  // headers: {
//     Authorization: token,
//   },
// };

// const result = await API
//   .put('feecalc', '/api/v1/partner/' + id + '/' + flag, myInit)
  async setPartnerFeeTarget (id, flag) {
    let token
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
      .then(user => {
        token = user.signInUserSession.idToken.jwtToken
      })
      .then(() => updatePartnerFeeFlag(id, flag, 
        {
          headers: {
            Authorization : "Bearer " + token,
          },
        }
      ))
      .then(() => {
        return resolve({"detail":"手数料請求対象フラグを更新しました"})
      })
      .catch((error) => {
        console.log(error);
        return reject({
          "error":"error", 
          "detail":"手数料請求対象フラグの更新に失敗しました。" + error
        })
      })
    })

},

  async setOrderGlobalNo (global_no, order_global_no) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const result = await API
      .put('feecalc', '/api/v1/transaction_history/order_global_no/' + global_no + '/' + order_global_no, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return result
  },

  async setIrregularInfo (global_no, update_count, irregular_type, irregular_source) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
      body: {
        global_no: global_no,
        update_count: update_count,
        irregular_type: irregular_type,
        irregular_source: irregular_source,
      },
    };

    const result = await API
      .put('feecalc', '/api/v1/transaction_history/irregular', myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return result
  },

  async setAndCreateStatementData (supplier_cd, cover_month, ids) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
      body: {
        supplier_cd: supplier_cd,
        cover_month: cover_month,
        ids: ids
      },
    };

    const result = await API
      .post('feecalc', '/api/v1/fee_statement/' + STATEMENT_FORMAT_VERSION, myInit)
      .catch((error) => {
        console.log(error)
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    if(result.statement_no && result.detail === "success") {
      this.createStatementPDF(result.statement_no, cover_month)
    }

    return result
  },


  async resetStatementDetailData (statement_no, cover_month) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const result = await API
      .post('feecalc', '/api/v1/fee_statement/reset/' + statement_no + '/' + STATEMENT_FORMAT_VERSION, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    if(result.statement_no && result.detail === "success") {
      this.createStatementPDF(result.statement_no, cover_month)
    }

    return result
  },


  async getStatements (cover_month, supplier_cd, statement_no, status, pdf_status, limit, offset) {

    let queryStringParameters = {}

    queryStringParameters.limit = limit
    queryStringParameters.offset = offset

    if (supplier_cd) {
      queryStringParameters.supplier_cd = supplier_cd
    }
    if (cover_month) {
      queryStringParameters.cover_month = cover_month
    }
    if (statement_no) {
      queryStringParameters.statement_no = statement_no
    }
    if (status) {
      queryStringParameters.status = status
    }
    if (pdf_status) {
      queryStringParameters.pdf_status = pdf_status
    }

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryStringParameters
    };

    const res = await API
      .get('feecalc', '/api/v1/fee_statement/statements', myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },

  async getMonthlyStatements (cover_month) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      }
    };

    const res = await API
      .get('feecalc', '/api/v1/fee_statement/monthly/'+ cover_month, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },


  async getMonthlyCheckdata (cover_month) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      }
    };

    const res = await API
      .get('feecalc', '/api/v1/fee_statement/monthly/checkdata/'+ cover_month, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },


  async getStatementAndDetail (statement_no) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      }
    };

    const res = await API
      .get('feecalc', '/api/v1/fee_statement/statement/' + statement_no, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return res
  },


  async createStatementPDF(statement_no, cover_month) {
    let token
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          token = user.signInUserSession.idToken.jwtToken
        })
        .then(() => clearPdf(
          {
            headers: {
              Authorization : "Bearer " + token,
            },
          }
        ))
        .then(() => postCreatePDF(
          {
            headers: {
              Authorization : "Bearer " + token,
            },
            body: {
              statement_no: statement_no,
              cover_month: cover_month
            }
          }
        ))
        .then(() => {
          return resolve({"detail":"PDF作成を開始しました"})
        })
        .catch((error) => {
          console.log(error);
          return reject({
            "error":"error", 
            "detail":"PDF作成指示に失敗しました。" + error
          })
        })
      })

  },

  async uploadManualFile (statement_no, manual_pdf_file, manual_excel_file) {
    let token
    let getPresignedUrlsParam = {}
    let manualUrlInfo = {
      statement_no: statement_no,
      manual_pdf_path: null,
      manual_excel_path: null,
      manual_pdf_upload_url: null,
      manual_excel_upload_url: null,
    }

    getPresignedUrlsParam.statement_no = statement_no

    console.log(manual_pdf_file[0].name)
    console.log(manual_excel_file[0].name)


    if (manual_pdf_file != null && manual_pdf_file[0] != null && manual_pdf_file[0].name.split('.').pop() == 'pdf') {
      getPresignedUrlsParam.manual_pdf_file = manual_pdf_file[0].name
    }
    if (manual_excel_file != null && manual_excel_file[0] != null && manual_excel_file[0].name.split('.').pop() == 'xlsx') {
      getPresignedUrlsParam.manual_excel_file = manual_excel_file[0].name
    }

    console.log(getPresignedUrlsParam)

    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          token = user.signInUserSession.idToken.jwtToken
        })
        .then(() => getUploadPresignedUrls(
          {
            headers: {
              Authorization : "Bearer " + token,
            },
            queryStringParameters: getPresignedUrlsParam
          }
        ))
        .then(urls => {manualUrlInfo = urls})
        .then(() => {
          if (manualUrlInfo.manual_pdf_path != null && manualUrlInfo.manual_pdf_upload_url != null){
            uploadFile(manualUrlInfo.manual_pdf_upload_url, manual_pdf_file[0])
          }
        })
        .then(() => {
          if (manualUrlInfo.manual_excel_path != null && manualUrlInfo.manual_excel_upload_url != null){
            uploadFile(manualUrlInfo.manual_excel_upload_url, manual_excel_file[0])
          }
        })
        .then(() => setManualFiles(
          {
            headers: {
              Authorization : "Bearer " + token,
            },
            body: {
              manual_pdf_path: manualUrlInfo.manual_pdf_path,
              manual_excel_path: manualUrlInfo.manual_excel_path,
            },
          }, 
          statement_no
        ))
        .then(() => {
          return resolve({"detail":"マニュアル作成ファイルを更新しました"})
        })
        .catch((error) => {
          console.log(error);
          return reject({
            "error":"error", 
            "detail":"マニュアル作成ファイルの更新に失敗しました。" + error
          })
        })
      })

  },

  async withdrawStatement (statement_no) {

    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const myInit = {
      headers: {
        Authorization: token,
      },
    };

    const result = await API
      .del('feecalc', '/api/v1/fee_statement/'+ statement_no, myInit)
      .catch((error) => {
        let errres = error.response.data
        errres["error"] = "error"

        return errres
    });

    return result
  },

  /** 
   * CSV 登録
   * NOTE: CSV を元に取引履歴テーブルにデータを登録します
   * @param {string} base64String - CSV ファイルの base64 エンコード文字列
   */
  async registerCsvFile(base64string){
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;

    const params = {
      headers: {
          Authorization: token,
      },
      body: {
        base64string,
      },
    };
    

    const res = await API.
      post('feecalc', '/api/v1/transaction_history/register/csvfile', params).
      catch((error) => {
        if(error.response.status !== 412){
          return {
            error: "error",
            detail: "CSV ファイルの登録に失敗しました",
          }
        }
        return {
          error: "error",
          detail: error.response.data.detail,
        }
      });
    
    return res;
  }
}