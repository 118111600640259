<template>
  <v-card flat v-if="uploadHelperParam.visible">
    <v-dialog
      v-model="uploadHelperParam.visible"
      persistent
      max-width="800"
    >
      <v-card>
        <v-card-title class="text-h6">
          仕入追加・単価修正+/-CSVファイルを登録します
        </v-card-title>
        <v-card-text>
          <v-alert dense outlined type="info">
            統合ダウンロードサイトの「NT401  発注データ」の1明細を登録します
          </v-alert>
          <v-alert dense outlined type="error" v-if="error_message">
            {{ error_message }}
          </v-alert>
        </v-card-text>
        <validation-observer ref="observer" v-slot="{invalid}">
          <validation-provider
            v-slot="{ errors }"
            name="統合ダウンロードサイト NT401  発注データのCSV"
            rules="ext:csv|required"
          >
            <v-file-input
              accept="text/csv"
              persistent-hint outlined
              v-model="upload_csv_file"
              :error-messages="errors"
              chips
              label="統合ダウンロードサイト NT401  発注データのCSV"
              hint="拡張子「.csv」のファイルを選択してください"
              class="mr-6"
            ></v-file-input>
          </validation-provider>
          <v-card-actions class="pb-4">
            <v-spacer></v-spacer>
            <v-btn color="gray darken-1" text @click="closeDialog()" :disabled="loading">キャンセル</v-btn>
            <v-btn color="blue darken-4" :disabled="invalid || loading" outlined @click="registerCsv()">実行する</v-btn>
          </v-card-actions>
        </validation-observer>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import { ValidationObserver, ValidationProvider } from "vee-validate";
  import feeCalculatorService from '@/services/feeCalculatorService';

  export default {
    name: 'RegisterCsvDialog',
    components: {
      ValidationProvider,
      ValidationObserver,
    },
    data () {
      return {
        /** CSV ファイル */
        upload_csv_file: null,

        /** 処理メッセージ */
        error_message: "",

        /** ローディング */
        loading: false,
      }
    },
    props: {
      uploadHelperParam: {
        /** ダイアログ表示可否 */
        visible: Boolean
      },
      /** アップロード成功後の処理 */
      onUploadCsvSuccess: Function,
    },
    methods: {
      async registerCsv() {
        this.error_message = "";
        this.loading = true;
        const base64String = await this._encodeToBase64(this.upload_csv_file);

        feeCalculatorService.registerCsvFile(base64String).then(data => {
          if ("error" in data) {
            this.error_message = data.detail;
          } else {
            this.closeDialog();
            this.$emit("onUploadCsvSuccess", data.detail);
          }
          this.loading = false;
        })
      },
      /** ダイアログクローズイベント */
      closeDialog() {
        this.upload_csv_file = null;
        this.uploadHelperParam.visible = false;
        this.error_message = "";
      },
      /** base64 へのエンコード */
      async _encodeToBase64(...parts) {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(reader.result);
          };
          reader.readAsDataURL(new Blob(parts));
        });
      },
    },
    watch: {
      /** CSV ファイルを監視し、クリアされたらエラーメッセージを空にする */
      upload_csv_file(newValue) {
        if(!newValue){
          this.error_message = "";
        }
      }
    }
  }
</script>
