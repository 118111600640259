<script> 
  export default { 
    data: () => ({
      isviewform: false,
      isadminform: false,
      issent: false,
      supplier_loading: false,
      bank_loading: false,
      customercd_loading: false,
      prosugate_loading: false,
      mep_loading: false,
      jira_loading: false,
      id: '',
      regist_user_cognito_sub: '',
      regist_user_name: '',
      regist_user_email: '',
      create_datetime: '',
      update_datetime: '',
      status: '',
      bank_status: '',
      prosugate_status: '',
      gmind_status: '',
      mep_servicedesk_orgid: '',
      mep_servicedesk_account_id: '',
      jira_message: '',
      mep_message: '',
      mep_supplier_id: '',
      assignee_id: '',
      assignee_name: '',
      complete_elapsed_seconds: '',
      complete_datetime: '',
      supplier_cd: '',
      customer_cd: '',
      memo: '',
      validation_supplier: false,
      validation_person: false,
      validation_bank: false,
      validation_invoiceperson: false,
      validation_confirm: false,
      company_name_kanji : '',
      company_name_kana : '',
      zip_cd : '',
      address_1_kanji : '',
      address_2_kanji : '',
      address_1_kana : '',
      address_2_kana : '',
      tel : '',
      fax : '',
      person_name_sei_kanji : '',
      person_name_mei_kanji : '',
      person_name_sei_kana : '',
      person_name_mei_kana : '',
      buka_name_kanji : '',
      buka_name_kana : '',
      person_email : '',
      person_email_confirm : '',
      bank_code : '',
      branch_code : '',
      bank_name : '',
      branch_name : '',
      account_type :'',
      account_number : '',
      account_name : '',
      bank_attachment : [],
      bank_attachment_filename: [],
      comission_person_name_sei_kanji : '',
      comission_person_name_mei_kanji : '',
      comission_person_name_sei_kana : '',
      comission_person_name_mei_kana : '',
      comission_person_email : '',
      comission_person_email_confirm : '',
      confirm_check: false,
    }),
  }
</script>