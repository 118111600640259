/* eslint-disable no-console */
import { API, Auth } from 'aws-amplify'

export default {
    async getUserPoolUserList(userpool) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const params = {
            headers: {
                Authorization: token,
            },
            body: {
                user_pool_name: userpool,
                action: "list",
                email_address: "",
            },
        };
        const res = await API.post('userpooluseroperation', '/v1/userpool_user_operation', params);
        return res
    },
    async registerAccountToUserPool(userpool, email) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const params = {
            headers: {
                Authorization: token,
            },
            body: {
                user_pool_name: userpool,
                action: "create",
                email_address: email,
            },
        };
        const res = await API.post('userpooluseroperation', '/v1/userpool_user_operation', params);
        return res
    },
    async deleteAccountFromUserPool(userpool, email) {
        const user = await Auth.currentAuthenticatedUser();
        const token = user.signInUserSession.idToken.jwtToken;

        const params = {
            headers: {
                Authorization: token,
            },
            body: {
                user_pool_name: userpool,
                action: "delete",
                email_address: email,
            },
        };
        const res = await API.post('userpooluseroperation', '/v1/userpool_user_operation', params);
        return res
    },
}
