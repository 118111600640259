<script> 
  export default { 
    data: () => ({
      company_name_kanji : {id:'company_name_kanji', name:'', value: '', hint:''},
      company_name_kana : {id:'company_name_kana', name:'', value: '', hint:''},
      zip_cd : {id:'zip_cd', name:'', value: '', hint:''},
      address_kanji : {id:'address_kanji', name:'', value: '', hint:''},
      address_kana : {id:'address_kana', name:'', value: '', hint:''},
      address_kana_trimmed : {id:'address_kana_trimmed', name:'', value: '', hint:''},
      tel : {id:'tel', name:'', value: '', hint:''},
      person_name_kanji : {id:'person_name_kanji', name:'', value: '', hint:''},
      person_name_kana : {id:'person_name_kana', name:'', value: '', hint:''},
      person_tel : {id:'person_tel', name:'', value: '', hint:''},
      person_email : {id:'person_email', name:'', value: '', hint:''},
    }),
  }
</script>