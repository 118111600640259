import axios from "axios";
import candidateService from "@/services/candidateService";
import { saveAs } from 'file-saver';

export default {
    async downloadFile(fileUrl, filename) {
        try{
            const response = await axios.get(fileUrl, {responseType:'blob'})
                .catch((error) => {
                    console.log(error)
                })
            if (response && (200 == response.status)) {
                saveAs(response.data, filename)
            }

        } catch(error) {
            console.log(error)
        }
    },

    async downloadTemplate(filename) {
        try{
            const presignedurl= await candidateService.getTemplatePresignedUrl(filename)
            const response = await axios.get(presignedurl, {responseType:'blob'})
                .catch((error) => {
                    console.log(error)
                })
            if (response && (200 == response.status)) {
                saveAs(response.data, filename)
            }

        } catch(error) {
            console.log(error)
        }
    },
    downloadCSV (form, filename) {
        let csv = ''
        let header = ''
        let body= ''
        //const datas = this.subform
        for (const d in form) {
            header += form[d]['name'] + ','
        }
        for (const d in form) {
            if(form[d]['value']) {
                body += form[d]['value'] + ','
            } else {
                body += "" + ','
            }
        }

        csv += header + '\n'
        csv += body + '\n'

        let blob = new Blob([String.fromCharCode(0xFEFF) + csv], {type: "text/csv"});
        try {
            saveAs(blob, filename)
        } catch(error) {
            console.log(error)
        }

    },
}