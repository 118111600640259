<template>
  <v-container>
    <v-row dense>
      <v-col cols="4" class="text-h6 pt-5" align-self="center">お問い合わせJIRA登録情報</v-col>
      <v-col cols="1" class="pt-5" align-self="center"><v-chip v-if="this.form.status != 'open'" color="success" dark class="ma-0" small>{{ $t(this.form.mep_servicedesk_account_id?"done":"processing") }}</v-chip></v-col>
      <v-col class="pt-5">
        <v-btn
            v-if="this.form.status == 'processing' && this.form.supplier_cd && (!this.form.mep_servicedesk_orgid || !this.form.mep_servicedesk_account_id)"
            elevation="5"
            color="orange"
            class="white--text"
            :loading="form.jira_loading"
            :disabled="form.jira_loading"
            @click="updateJIRA"
        >
          連携する
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="12" md="12" sm="12">
        <v-alert dense outlined type="error" v-if="this.form.jira_message">
          {{ this.form.jira_message }}
        </v-alert>
      </v-col>

      <v-col lg="12" md="12" sm="12">
        <v-simple-table class="pa-5">
          <tbody>

          <tr v-if="this.form.mep_servicedesk_orgid">
            <th width="20%">組織ID</th>
            <td colspan="3">{{ this.form.mep_servicedesk_orgid }}</td>
          </tr>
          <tr v-if="this.form.mep_servicedesk_account_id">
            <th width="20%">顧客アカウントID</th>
            <td colspan="3">{{ this.form.mep_servicedesk_account_id }}</td>
          </tr>

          <tr v-for="item in subform" :key="item.id">
            <th width="20%">{{ item.name }}</th>
            <td>{{ item.value }}</td>
            <td align="right"><v-btn icon @click="doCopy(item.id, item.value)"><v-icon dark>mdi-content-copy</v-icon></v-btn></td>
            <td width="10%" align="left"><v-chip v-if="item.showcopy">
              Copied!
            </v-chip></td>
          </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm'
import JIRAInfoForm from "@/forms/JIRAInfoForm";

export default {
  name: 'JIRAInfo',
  title: 'お問い合わせJIRA登録情報',
  components: {
  },
  props: {
    form: PartnerForm,
    subform: JIRAInfoForm,
  },
  data: () => ({
    checkbox: false,
    ZenSP: "　",
  }),
  methods: {
    doCopy(id, value) {
      this.$copyText(value)
      for (const d in this.subform) {
        if(id == this.subform[d]['id']) {
          this.subform[d]['showcopy'] = true
        } else {
          this.subform[d]['showcopy'] = false
        }
      }
    },
    async updateJIRA() {
      await this.$emit('updateJIRA')
    },
  },
  async mounted () {
    for (const d in this.subform) {
      this.subform[d]['showcopy'] = false
    }
  }
}
</script>
