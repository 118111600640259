<template>
  <v-container
      class="py-6"
      fluid
  >
    <v-row justify="center">
      <v-col cols="3">
        <v-sheet rounded="lg" outlined>
          <v-sheet
              color="grey lighten-4"
              class="pa-5"
          >
            <v-row dense>
              <v-col class="grey--text">申請受付番号</v-col>
            </v-row>
            <v-row dense>
              <v-col class="indigo--text caption">{{ this.form.id }}
                <v-chip color="success" dark class="ma-0" small>{{ $t(this.form.status) }}</v-chip>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="grey--text">仕入先コード</v-col>
              <v-col class="indigo--text caption" v-if="this.form.supplier_cd">{{ this.form.supplier_cd }} </v-col>
              <v-col class="indigo--text caption" v-else>(未登録) </v-col>
            </v-row>
            <v-row dense>
              <v-col class="grey--text">得意先コード</v-col>
              <v-col class="indigo--text caption" v-if="this.form.customer_cd">{{ this.form.customer_cd }} </v-col>
              <v-col class="indigo--text caption" v-else>(未登録) </v-col>
            </v-row>
            <v-row dense>
              <v-col class="grey--text">ミスミ対応者</v-col>
              <v-col class="indigo--text caption" v-if="this.form.assignee_name">{{ this.form.assignee_name }} </v-col>
              <v-col class="indigo--text caption" v-else>(未設定) </v-col>
            </v-row>
            <v-row dense>
              <v-col class="grey--text">経過時間</v-col>
              <v-col class="indigo--text caption">{{ this.form.complete_elapsed_seconds }} </v-col>
            </v-row>
            <v-row class="grey--text font-weight-bold" justify="center">
              <v-btn
                  v-if="this.form.status == 'open'"
                  width="80%"
                  elevation="5"
                  color="orange"
                  class="ma-2 white--text"
                  :loading="loading"
                  :disabled="loading"
                  @click="startprocessing"
              >
                処理開始
              </v-btn>

              <v-btn
                  v-if="this.form.status=='processing' && this.form.supplier_cd && this.form.customer_cd && this.form.bank_status=='done' && this.form.prosugate_status=='done' && this.form.mep_servicedesk_account_id && this.form.mep_supplier_id"
                  width="80%"
                  elevation="5"
                  color="orange"
                  class="ma-2 white--text"
                  :loading="loading"
                  :disabled="loading"
                  @click="endprocessing"
              >
                完了
              </v-btn>
            </v-row>
          </v-sheet>
          <v-divider></v-divider>
          <v-list>
            <v-list-item
                v-for="[icon, text, hash, statusstr, loadingstatus, error] in links"
                :key="icon"
                link
                @click="$router.push({ hash: hash })"
            >
              <v-list-item-icon>
                <v-icon>{{ icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title>
                <div class="caption">{{ text }}</div>
              </v-list-item-title>
              <v-progress-circular v-if="form[loadingstatus] == true"
                  :size="30"
                  color="primary"
                  indeterminate
              ></v-progress-circular>
              <v-icon v-else-if="form[error]" color="red">mdi-alert-circle</v-icon>
              <v-icon v-else-if="['supplier_cd','customer_cd','mep_servicedesk_account_id','mep_supplier_id'].indexOf(statusstr) > -1 && form[statusstr]" color="success">mdi-check-underline-circle </v-icon>
              <v-icon v-else-if="['supplier_cd','customer_cd','mep_servicedesk_account_id','mep_supplier_id'].indexOf(statusstr) > -1">mdi-check-underline-circle-outline </v-icon>
              <v-icon v-else-if="statusstr.length > 0 && form[statusstr] == 'done'" color="success">mdi-check-underline-circle </v-icon>
              <v-icon v-else-if="statusstr.length > 0">mdi-check-underline-circle-outline </v-icon>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-col>

      <v-col cols="9" >
        <v-sheet
            min-height="70vh"
            rounded="lg"
        >
    <transition :name="transitionName" mode="out-in">
      <component
          :is="subPage"
          :form="form"
          :subform="subForm"
          @back="back"
          @next="next"
          @confirm="confirm"
          @updateSupplierCd="updateSupplierCd"
          @updateCustomerCd="updateCustomerCd"
          @updateBankProcess="updateBankProcess"
          @updateProsugateProcess="updateProsugateProcess"
          @updateGmindProcess="updateGmindProcess"
          @updateJIRA="updateJIRA"
          @updateMEP="updateMEP"
      ></component>
    </transition>

        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PartnerForm from '@/forms/PartnerForm.vue'
import Confirm from '@/components/Confirm.vue'
import SupplierInfo from "@/components/SupplierInfo";
import BankInfo from "@/components/BankInfo";
import CustomerInfo from "@/components/CustomerInfo";
import ProsugateInfo from "@/components/ProsugateInfo";
import GmindInfo from "@/components/GmindInfo";
import candidateService from "@/services/candidateService";
import Header from "@/components/Header";
import SupplierInfoForm from "@/forms/SupplierInfoForm";
import BankInfoForm from "@/forms/BankInfoForm";
import CustomerInfoForm from "@/forms/CustomerInfoForm";
import ProsugateInfoForm from "@/forms/ProsugateInfoForm";
import GmindInfoForm from "@/forms/GmindInfoForm";
import JIRAInfoForm from "@/forms/JIRAInfoForm";
import JIRAInfo from "@/components/JIRAInfo";
import MepPartnerInfo from "@/components/MepPartnerInfo";
import MepPartnerInfoForm from "@/forms/MepPartnerInfoForm";

export default {
  name: 'Viewcandidate',
  title: 'パートナー登録申請内容確認',
  components: { Header },
  data: () => ({
    form: PartnerForm,
    supplierinfoform: SupplierInfoForm,
    bankinfoform: BankInfoForm,
    customerinfoform: CustomerInfoForm,
    prosugateinfoform: ProsugateInfoForm,
    gmindinfoform: GmindInfoForm,
    jirainfoform: JIRAInfoForm,
    meppartnerinfoform: MepPartnerInfoForm,
    transitionName: '',
    candidateHeaders: null,
    loading: false,
    links: [
      ['mdi-receipt', '内容確認', '#confirm','','',''],
      ['mdi-account-plus', '仕入先マスタ登録', '#supplierinfo','supplier_cd','supplier_loading',''],
      ['mdi-bank', '口座登録', 'bankinfo','bank_status','bank_loading',''],
      ['mdi-account-plus-outline', '利用料用得意先コード登録', 'customerinfo','customer_cd','customercd_loading',''],
      ['mdi-truck', 'PROSUGATE登録', 'prosugateinfo','prosugate_status','prosugate_loading',''],
      ['mdi-star-face', 'mep パートナー登録', 'meppartnerinfo','mep_supplier_id','mep_loading','mep_message'],
      ['mdi-face-agent', 'サポートデスク登録', 'jirainfo','mep_servicedesk_account_id','jira_loading','jira_message'],
    ],
  }),
  props: {
    id: String,
  },
  computed: {
    subPage () {
      switch (this.$route.hash) {
        case '#confirm':
          return Confirm
        case '#supplierinfo':
          return SupplierInfo
        case '#bankinfo':
          return BankInfo
        case '#customerinfo':
          return CustomerInfo
        case '#prosugateinfo':
          return ProsugateInfo
        case '#gmindinfo':
          return GmindInfo
        case '#meppartnerinfo':
          return MepPartnerInfo
        case '#jirainfo':
          return JIRAInfo
        default:
          return Confirm
      }
    },
    subForm () {
      switch (this.$route.hash) {
        case '#confirm':
          return this.form
        case '#supplierinfo':
          return this.supplierinfoform
        case '#bankinfo':
          return this.bankinfoform
        case '#customerinfo':
          return this.customerinfoform
        case '#prosugateinfo':
          return this.prosugateinfoform
        case '#gmindinfo':
          return this.gmindinfoform
        case '#meppartnerinfo':
          return this.meppartnerinfoform
        case '#jirainfo':
          return this.jirainfoform
        default:
          return this.form
      }
    },
  },
  methods: {
    confirm () {
      this.transitionName = 'slide-back'
      this.$router.push({ hash: '#confirm' })
    },
    back () {
      this.transitionName = 'slide-back'
      switch (this.$route.hash) {
        default:
          this.$router.push('/viewcandidate')
          break
      }
    },
    next () {
      this.transitionName = 'slide-next'
      switch (this.$route.hash) {
        default:
          this.$router.push({ hash: '#confirm' })
          break
      }
    },
    async startprocessing() {
      this.loading = true

      const result = await candidateService.startPartnerCandidateStatus(this.form)
      this.form.id = result.id
      this.form.status = result.status
      this.form.bank_status = result.bank_status
      this.form.prosugate_status = result.prosugate_status
      this.form.gmind_status = result.gmind_status
      this.form.issent = result.issent
      this.form.assignee_name = result.assignee_name
      this.loading = false
    },
    async endprocessing() {
      this.loading = true
      const result = await candidateService.closePartnerCandidateStatus(this.form)
      this.form.id = result.id
      this.form.status = result.status
      this.form.issent = result.issent
      this.loading = false
    },
    async updateSupplierCd(suppliercd) {
      this.updateLoading('supplier_loading', true)
      const res = await candidateService.updatePartnerSupplierCd(this.form, this.prosugateinfoform, suppliercd)
      this.updateLoading('supplier_loading', false)
      if (res.id == this.form.id) {
        this.form.supplier_cd = suppliercd
        await this.updateJIRA()
        if (this.form.mep_servicedesk_orgid && this.form.mep_servicedesk_account_id) {
          await this.updateMEP()
        }
      }
    },
    async updateBankProcess(bankstatus) {
      this.updateLoading('bank_loading', true)
      const result = await candidateService.updateBankProcess(this.form, bankstatus)
      this.updateLoading('bank_loading', false)
      this.form.id = result.id
      this.form.bank_status = result.bank_status
    },
    async updateProsugateProcess(prosugatestatus) {
      this.updateLoading('prosugate_loading', true)
      const result = await candidateService.updateProsugateProcess(this.form, prosugatestatus)
      this.updateLoading('prosugate_loading', false)
      this.form.id = result.id
      this.form.prosugate_status = result.prosugate_status
    },
    async updateGmindProcess(gmindstatus) {
      const result = await candidateService.updateGmindProcess(this.form, gmindstatus)
      this.form.id = result.id
      this.form.gmind_status = result.gmind_status
    },
    async updateCustomerCd(customercd) {
      this.updateLoading('customercd_loading', true)
      const res = await candidateService.updatePartnerCustomerCd(this.form, customercd)
      this.updateLoading('customercd_loading', false)
      if (res.id == this.form.id) {
        this.form.customer_cd = customercd
      }
    },
    async updateJIRA() {
      this.updateLoading('jira_loading', true)
      const res = await candidateService.updateJIRA(this.form)
      this.updateLoading('jira_loading', false)
      this.form.jira_message=res.jira_message
      this.form.mep_servicedesk_orgid = res.mep_servicedesk_orgid
      this.form.mep_servicedesk_account_id = res.mep_servicedesk_account_id
      this.meppartnerinfoform.mep_servicedesk_orgid = res.mep_servicedesk_orgid
    },
    async updateMEP() {
      this.updateLoading('mep_loading', true)
      const res = await candidateService.updateMEP(this.form)
      this.updateLoading('mep_loading', false)

      if(res.mep_message && res.mep_message != 'success') {
        this.form.mep_message = res.mep_message
      }
      this.form.mep_supplier_id = res.mep_supplier_id
    },
    async getSubProcStatus(statname) {
      return this.form[statname]
    },
    async updateLoading(name, loading){
      this.$set(this.form, name, loading);
    }
  },
  async created () {
    const forms = await candidateService.getPartnerCandidateById(this.id, "ADMIN")
    this.form = forms.form
    this.supplierinfoform = forms.supplierinfoform
    this.bankinfoform = forms.bankinfoform
    this.customerinfoform = forms.customerinfoform
    this.prosugateinfoform = forms.prosugateinfoform
    this.gmindinfoform = forms.gmindinfoform
    this.meppartnerinfoform = forms.meppartnerinfoform
    this.jirainfoform = forms.jirainfoform
    this.form.isadminform = true
  },
}
</script>
