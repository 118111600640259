import moment from "moment";
import i18n from "@/i18n";

export default {
    datetimeFormatter (value) {
        if (value) {
            //return moment(value).format('YY/MM/DD HH:mm');
            return moment.utc(value).local().format('YY/MM/DD HH:mm');
        } else {
            return value;
        }
    },
    datetimeJSTFormatter (value) {
        if (value) {
            return moment(value).format('YY/MM/DD HH:mm');
        } else {
            return value;
        }
    },
    secondsToDatetimeFormatter (value) {
        if (value) {
            value = Math.round(value)
            var timeD = Math.floor(value / (24 * 60 * 60));
            var timeH = Math.floor(value % (24 * 60 * 60) / (60 * 60));
            var timeM = Math.floor(value % (24 * 60 * 60) % (60 * 60) / 60);
            var timeS = value % (24 * 60 * 60) % (60 * 60) % 60;
            var timeDMS = timeD + '日 ' + ("00" + timeH).slice(-2) + ':' + ("00" + timeM).slice(-2) + ':' + ("00" + timeS).slice(-2);
            return timeDMS;
        } else {
            return value;
        }
    },
    htmlBrToLineFeedFormatter(value){
        if(value) {
            value = value.replace(/(<br>|<br\/>|<br \/>|<BR>|<BR\/>|<BR \/>)/g, '\n');
        }
        return value
    },
    cmsApplicationStatusFormatter(value) {
        return i18n.t("cms.application_status." + value)
    },
    cmsPageStatusFormatter(value) {
        return i18n.t("cms.page_status." + value)
    },
    cmsApplicationTypeFormatter(value) {
        return i18n.t("cms.application_type." + value)
    },
    mepPartnerCandidateStatusFormatter(value) {
        if (value) {
            return i18n.t("partner.candidate_status." + value)
        } else {
            return "-"
        }
    },
    mepPartnerSalesFeeTargetFormatter(value) {
        if (value) {
            return i18n.t("partner.mep_partner_sales_fee_target." + value)
        } else {
            return null
        }
    },

    mepFeeStatementTranslateFormatter(key, value) {
        if (value) {
            return i18n.t("fee_statement." + key + "." + value)
        } else {
            return "-"
        }
    },

    answerDeliveryRangeFormatter(valarr) {
        if(valarr) {
            if (valarr[0] == valarr[1]) {
                return valarr[1]
            } else {
                return valarr[0] + "～" + valarr[1]
            }
        } else {
            return null
        }
    },
    numberFormatter(value) {
        if(value) {
            value = new Intl.NumberFormat('ja-JP').format(value)
        }
        return value
    },
    feeStatementStatusFormatter(value) {
        if (value) {
            return i18n.t("fee_statement.status." + value)
        } else {
            return "-"
        }
    },
    feeStatementPdfStatusFormatter(value) {
        if (value) {
            return i18n.t("fee_statement.pdf_status." + value)
        } else {
            return "-"
        }
    }

}