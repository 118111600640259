/* eslint-disable no-console */
import Vue from 'vue'
import Router from 'vue-router'
import usertrack from '@/components/Usertrack.vue'
import candidate from '@/components/Candidate.vue'
import mailhistory from '@/components/MailHistory.vue'
import cmsapplication from '@/components/CmsApplication.vue'
import viewcandidate from '@/components/ViewCandidate.vue'
import accountregister from '@/components/AccountRegister.vue'
import transactionhistory from '@/components/feeCalculate/TransactionHistory.vue'
import partnerSearch from '@/components/feeCalculate/PartnerSearch.vue'
import feestatements from '@/components/feeCalculate/FeeStatements.vue'
import monthlyfeestatements from '@/components/feeCalculate/MonthlyFeeStatementReport.vue'
import viewStatementDetail from '@/components/feeCalculate/ViewStatementDetail.vue'
import versions from '@/views/Versions.vue'
import SignIn from '@/components/SignIn.vue'
import store from '@/store/index.js'

import { AmplifyEventBus } from 'aws-amplify-vue'
import * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'

Vue.use(Router)
Vue.use(AmplifyPlugin, AmplifyModules)

let user

function getUser() {
  return Vue.prototype.$Amplify.Auth.currentAuthenticatedUser().then((data) => {
    if (data && data.signInUserSession) {
      store.commit('setUser', data)
      return data;
    }
  }).catch(() => {
    store.commit('setUser', null)
    return null
  })
}

AmplifyEventBus.$on('authState', async (state) => {
  if (state === 'signedOut'){
    user = null
    store.commit('setUser', null)
    router.push({path: '/signin'}, () => {})
  } else if (state === 'signedIn') {
    user = await getUser();
    router.push({path: '/'})
  }
})

const routes = [
  {
    path: '/usertrack',
    name: 'usertrack',
    component: usertrack,
    meta: { requireAuth: true }
  },
  {
    path: '/candidate',
    name: 'candidate',
    component: candidate,
    meta: { requireAuth: true }
  },

  {
    path: '/mailhistory',
    name: 'mailhistory',
    component: mailhistory,
    meta: { requireAuth: true }
  },
  {
    path: '/cmsapplication',
    name: 'cmsapplication',
    component: cmsapplication,
    meta: { requireAuth: true }
  },
  {
    path: '/viewcandidate:id',
    name: 'viewcandidate',
    component: viewcandidate,
    props: true,
    meta: { requireAuth: true }
  },
  {
    path: '/transactionhistory',
    name: 'transactionhistory',
    component: transactionhistory,
    meta: { requireAuth: true }
  },
  {
    path: '/partnerSearch',
    name: 'partnerSearch',
    component: partnerSearch,
    meta: { requireAuth: true }
  },
  {
    path: '/feestatements',
    name: 'feestatements',
    component: feestatements,
    meta: { requireAuth: true }
  },
  {
    path: '/monthlyfeestatements',
    name: 'monthlyfeestatements',
    component: monthlyfeestatements,
    meta: { requireAuth: true }
  },
  {
    path: '/viewStatementDetail:statement_no',
    name: 'viewStatementDetail',
    component: viewStatementDetail,
    props: true,
    meta: { requireAuth: true }
  },
  {
    path: '/signin',
    name: 'signin',
    component: SignIn
  },
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/accountregister/:userpool',
    name: 'accountregister',
    component: accountregister,
    props: true,
    meta: { requireAuth: true }
  },
  {
    path: '/versions',
    name: 'versions',
    component: versions
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeResolve(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)) {
    user = await getUser()
    if (!user) {
      return next({
        path: '/signin'
      })
    }
    return next()
  }
  return next()
})

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

export default router