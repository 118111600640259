/* eslint-disable no-console */
import { API, graphqlOperation } from 'aws-amplify'

export default {
  async getUsertracks (limit, offset, paramSortBy, paramQtStatus, paramSoStatus, paramCustomerCode, paramUserCd, paramServiceCategoryCode, paramServicecardId, paramProductPartNumber) {

    var queryParamDef = '$offset:Int!, $limit:Int!'
    var queryParamStr = 'offset:$offset, limit:$limit'
    const params = {
      offset: offset,
      limit: limit
    }

    if (paramQtStatus) {
      queryParamDef += ', $qtstatus:String'
      queryParamStr += ', qtstatus:$qtstatus'
      params['qtstatus'] = paramQtStatus
    }
    if (paramSoStatus) {
      queryParamDef += ', $sostatus:String'
      queryParamStr += ', sostatus:$sostatus'
      params['sostatus'] = paramSoStatus

    }
    if (paramCustomerCode) {
      queryParamDef += ', $customercode:String'
      queryParamStr += ', customercode:$customercode'
      params['customercode'] = paramCustomerCode
    }
    if (paramUserCd) {
      queryParamDef += ', $usercd:String'
      queryParamStr += ', usercd:$usercd'
      params['usercd'] = paramUserCd
    }
    if (paramServicecardId) {
      queryParamDef += ', $servicecardid:String'
      queryParamStr += ', servicecardid:$servicecardid'
      params['servicecardid'] = paramServicecardId
    }
    if (paramSortBy) {
      queryParamDef += ', $orderby:String'
      queryParamStr += ', orderby:$orderby'
      params['orderby'] = paramSortBy
    }
    if (paramServiceCategoryCode) {
      queryParamDef += ', $servicecategorycode:String'
      queryParamStr += ', servicecategorycode:$servicecategorycode'
      params['servicecategorycode'] = paramServiceCategoryCode
    }
    if (paramProductPartNumber) {
      queryParamDef += ', $productpartnumber:String'
      queryParamStr += ', productpartnumber:$productpartnumber'
      params['productpartnumber'] = paramProductPartNumber
    }

    const listUsertracks = `query getUsertracks(` + queryParamDef + `) {
      getUsertracks(` + queryParamStr + `) {
        usertrack {
          id
          user_id
          user_tel
          customer_code
          ntv_customer_name1
          user_cd
          client_name
          user_last_login_at_jst
          project_id
          project_name
          project_user_visibility
          project_created_at_jst  
          supplier_name
          service_card_title
          qt_condition_name
          qt_condition_quantity
          qt_condition_category
          qt_condition_usage
          qt_condition_material
          qt_condition_method
          qt_condition_postprocessing
          qt_condition_finishing
          qt_condition_printer
          cad_file_id
          cad_file_name
          qt_status
          qt_requested_at_jst
          qt_answered_at_jst
          spec_id
          answer_price_amount
          answer_delivery_min        
          answer_delivery_max
          sales_price_amount
          sales_total_amount
          order_accepted_at_jst
          customer_reference_number
          customer_sub_reference_number
          product_part_number
          global_no
          confirmed_shipment_date
          invoice_info
          mep_order_status
          qt_time_to_first_answer
          qt_time_to_answer
        }
        listcount {
          total
        }
      }
    }`

    const usertracks = await API.graphql(graphqlOperation(listUsertracks, params))
    //console.log(usertracks.data.getUsertracks)
    return usertracks.data.getUsertracks

  },
  async getUsertrackCustomers () {
    const listUsertrackCustomers = `query getUsertrackCustomers {
      getUsertrackCustomers {
        customer_code
        ntv_customer_name1
      }
    }`
    const customers = await API.graphql(graphqlOperation(listUsertrackCustomers))
    return customers.data.getUsertrackCustomers
  },

  async getUsertrackUsers () {
    const listUsertrackUsers = `query getUsertrackUsers {
      getUsertrackUsers {
        user_cd
        client_name
        customer_code
      }
    }`
    const users = await API.graphql(graphqlOperation(listUsertrackUsers))
    return users.data.getUsertrackUsers
  },

  async getUsertrackCategoryServiceCards (serviceCategoryCode) {
    const params = {
      servicecategorycode: serviceCategoryCode
    }
    const listUsertrackCategoryServiceCards = `query getUsertrackCategoryServiceCards($servicecategorycode:String) {
      getUsertrackCategoryServiceCards(servicecategorycode:$servicecategorycode) {
          qt_condition_category_code
          qt_condition_category
          service_cards {
            service_card_id
            service_card_title
          }
        }
      }`
    const categoryServiceCards = await API.graphql(graphqlOperation(listUsertrackCategoryServiceCards, params))
    return categoryServiceCards.data.getUsertrackCategoryServiceCards
  },

}