<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>
      <v-card flat width="100%">
        <v-card-title>
          メール送信履歴
        </v-card-title>
          <v-alert
              border="left"
              colored-border
              type="info"
              elevation="2"
          >
            現在、mepopから送信されたメール（パートナー登録申請関連）だけが履歴参照可能です。
          </v-alert>
          <v-row class="mt-3" align="center">
            <template>
              <v-col cols="3">
                <v-autocomplete
                    dense
                    v-model="filter_mailtype"
                    outlined placeholder="メール種類" clearable
                    :items="mailtypes" item-value="mailtype_code" item-text="mailtype_name">
                </v-autocomplete>
              </v-col>
              <v-col cols="2">
              <v-autocomplete
                  dense
                  v-model="filter_status"
                  outlined placeholder="APIステータス" clearable
                  :items="statuses" item-value="item" item-text="text">
              </v-autocomplete>
              </v-col>
              <v-col cols="3">
              <v-text-field
                  dense
                  v-model="filter_to_addresses"
                  append-icon="mdi-magnify"
                  label="送信先アドレス（部分）"
              ></v-text-field>
              </v-col>

            </template>
          </v-row>
          <v-row align="center" class="mt-0 mb-5">
            <template>
              <v-card rounded outlined flat class="pl-3 ml-3">
                <span class="text-subtitle-2">日時(From)</span>
                <v-card-title class="pb-0">
                <v-menu
                    v-model="fdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        dense
                        clearable
                        v-model="filter_fdate"
                        label="日付"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="filter_fdate"
                      @input="fdatemenu = false"
                  ></v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-menu
                    v-model="ftimemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                                  class="ma-0"
                                  clearable
                                  v-model="filter_ftime"
                                  label="時刻"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-model="filter_ftime"
                      @input="ftimemenu = false"
                      format="24hr"
                      scrollable
                  ></v-time-picker>
                </v-menu>
                </v-card-title>
              </v-card>
              <v-card rounded outlined flat class="pl-3 ml-3">
                <span class="text-subtitle-2">日時(To)</span>
                <v-card-title class="pb-0">
                <v-menu
                    v-model="tdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                        class="ma-0"
                        clearable
                        v-model="filter_tdate"
                        label="日付"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="filter_tdate"
                      @input="tdatemenu = false"
                  ></v-date-picker>
                </v-menu>

                <v-menu
                    v-model="ttimemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                                  class="ma-0"
                                  clearable
                                  v-model="filter_ttime"
                                  label="時刻"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-model="filter_ttime"
                      @input="ttimemenu = false"
                      format="24hr"
                      scrollable
                  ></v-time-picker>
                </v-menu>
                </v-card-title>
              </v-card>
              <v-col lg="2" md="2" sm="2" align-self="center">
                <v-btn x-large outlined color="light-blue darken-4" class="ml-10" @click="getListDataFromApi()">
                  <v-icon class="mr-1">mdi-pencil-outline</v-icon>
                  検索</v-btn>
              </v-col>
            </template>
          </v-row>
        <v-divider></v-divider>
        <v-data-table
            :headers="headers"
            :items="mailhistoryitems"
            :options.sync="options"
            :server-items-length="totalcount"
            :loading="loading"
            :items-per-page="itemsperpage"
            :footer-props = "{ 'items-per-page-text':'行/ページ:',
             'items-per-page-options': [10,20,25,50]}"
            class="elevation-1"
            show-expand
            fixed-header
            height="500"
        >
          <template
              v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
              v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.message="{ item }">
              <span  style="word-break : break-all;"> {{ item.message }}</span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container fluid>
                <v-row dense width="100%">
                  <v-col cols="4">
                    <v-card elevation="1" outlined>
                      <v-card-text>
                        <div>title</div>
                        <v-simple-table dense class="pb-10">
                          <tbody>
                          <tr><td>日時:</td><td>{{ item.create_datetime }}</td></tr>
                          <tr><td>履歴ID:</td><td>{{ item.id }}</td></tr>
                          <tr><td>送信グループID:</td><td>{{ item.group_id }}</td></tr>
                          <tr><td>APIステータスコード:</td><td>{{ item.status }}</td></tr>
                          <tr><td>メール種類ID:</td><td>{{ item.mailtype_id }}</td></tr>
                          <tr><td>メール種類コード:</td><td>{{ item.mailtype_code }}</td></tr>
                          <tr><td>メール種類:</td><td>{{ item.mailtype_code }}</td></tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="8">
                    <v-card elevation="1" outlined>
                      <v-card-text>
                        <div>title</div>
                        <v-simple-table dense class="pb-10">
                          <tbody>
                          <tr><td width="20%">送信元アドレス:</td><td style="word-break : break-all;">{{ item.from_address }}</td></tr>
                          <tr><td width="20%">送信先アドレス(TO):</td><td style="word-break : break-all;">{{ item.to_addresses }}</td></tr>
                          <tr><td width="20%">送信先アドレス(CC):</td><td style="word-break : break-all;">{{ item.cc_addresses }}</td></tr>
                          <tr><td width="20%">送信先アドレス(BCC):</td><td style="word-break : break-all;">{{ item.bcc_addresses }}</td></tr>
                          <tr><td width="20%">タイトル:</td><td style="word-break : break-all;">{{ item.subject }}</td></tr>
                          <tr><td width="20%">本文:</td><td style="word-break : break-all; white-space:pre; ">{{ htmlBrToLineFeedFormatter(item.content) }}</td></tr>
                          <tr><td width="20%">添付ファイル:</td><td style="word-break : break-all;">
                            <template v-if="checkMailAttachmentsFormat(item.attachments)">
                              <v-card flat
                                  v-for="(attachment) of mailAttachmentsFormatter(item.attachments)"
                                  :key="attachment.file_s3_key"
                              >
                                  <v-simple-table dense class="ma-2">
                                    <tbody>
                                      <tr><td width="150" bgcolor="#f0f8ff">添付ファイル名:</td><td style="word-break : break-all;">{{ attachment.file_name }}</td></tr>
                                      <tr><td width="150" bgcolor="#f0f8ff">Bucket:</td><td style="word-break : break-all;">{{ attachment.file_s3_bucket }}</td></tr>
                                      <tr><td width="150" bgcolor="#f0f8ff">Key:</td><td style="word-break : break-all;">{{ attachment.file_s3_key }}</td></tr>
                                    </tbody>
                                  </v-simple-table>
                              </v-card>
                            </template>
                            <template v-else>
                              {{ mailAttachmentsFormatter(item.attachments) }}
                            </template>
                          </td></tr>
                          <tr><td width="15%">メールサービス:</td><td style="word-break : break-all;">{{ item.service }}</td></tr>
                          <tr><td width="15%">APIメッセージ:</td><td style="word-break : break-all;">{{ item.message }}</td></tr>
                          </tbody>
                        </v-simple-table>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>

        </v-data-table>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import mailhistoryService from '@/services/mailhistoryService'
import moment from 'moment'
import formatterutil from "@/util/formatter"

export default {
  name: 'mailhistory',
  title: 'メール送信履歴',
  data () {
    return {
      totalcount: 0,
      itemsperpage: 25,
      mailhistoryitems: [],
      loading: true,
      options: {},
      mailtypes: [],
      filter_to_addresses: '',
      filter_mailtype: '',
      filter_fdate: '',
      filter_ftime: '',
      filter_tdate: '',
      filter_ttime: '',
      fdatemenu: false,
      ftimemenu: false,
      tdatemenu: false,
      ttimemenu: false,
      filter_status:'',
      statuses: [{item: '1', text: '1xx'},
        {item: '2', text:'2xx'},
        {item: '3', text:'3xx'},
        {item: '4', text:'4xx'},
        {item: '5', text:'5xx'}],

      headers: [
        { text: '送信グループID', align: 'start', sortable: false, value: 'group_id' },
        { text: 'メール種類コード', align: 'start', sortable: false, value: 'mailtype_code' },
        { text: 'タイトル', align: 'start', sortable: false, value: 'subject' },
        { text: '送信先アドレス(TO)', align: 'start', sortable: false, value: 'to_addresses' },
        { text: '日時', align: 'start', sortable: false, value: 'create_datetime', formatter: formatterutil.datetimeFormatter },
        { text: 'APIステータスコード', align: 'start', sortable: false, value: 'status' },
        { text: 'APIメッセージ', align: 'start', sortable: false, value: 'message' },
        { text: '', value: 'data-table-expand' },
      ],
    }
  },
  watch: {
    options: {
      handler () {
        this.getListDataFromApi()
      },
      deep: true,
    },
  },
  methods: {
    htmlBrToLineFeedFormatter(value) {
      if(value) {
        return formatterutil.htmlBrToLineFeedFormatter(value)
      }
    },
    checkMailAttachmentsFormat(value) {
      if(value) {
        //あるはずのキーがなければFalseを戻す(一時期添付ファイル名だけを配列で持たせていたのでその確認)
        if(value.indexOf("file_s3_bucket") < 0) {
          return false
        }
        else {
          return true
        }
      }
      return false
    },
    mailAttachmentsFormatter(value) {
      if(value) {
        //null文字列で入ってきてしまっているものは空文字で戻す
        if(value == 'null') {
          return ""
        }
        //あるはずのキーがなければそのまま戻す
        if(value.indexOf("file_s3_bucket") < 0) {
          return value
        }
        try {
          const formatted = JSON.parse(value)
          return formatted
        } catch (e) {
          console.warn("unexpected format of email attachments")
          return value
        }
      }
      return value
    },
    getAllMailTypes () {
      mailhistoryService.getMailTypes().then(data => {
        this.mailtypes = data.mailtypes
      })
    },
    getListDataFromApi () {
      this.loading = true
      let {page, itemsPerPage} = this.options

      const limit = itemsPerPage
      const offset = (page-1) * itemsPerPage

      let datetime_from = this.filter_fdate
      if (datetime_from) {
        if (this.filter_ftime) {
          datetime_from += " "
          datetime_from += this.filter_ftime
        } else {
          this.filter_ftime = "00:00"
          datetime_from += " 00:00:00"
        }
        datetime_from = moment(datetime_from).utc().format('YYYY-MM-DD HH:mm:ss');
      } else {
        if (this.filter_ftime) {
          this.filter_ftime = ""
        }
      }
      let datetime_to = this.filter_tdate
      if (datetime_to) {
        if (this.filter_ttime) {
          datetime_to += " "
          datetime_to += this.filter_ttime
        } else {
          this.filter_ttime = "00:00"
          datetime_to += " 00:00:00"
        }
        datetime_to = moment(datetime_to).utc().format('YYYY-MM-DD HH:mm:ss');
      } else {
        if (this.filter_ttime) {
          this.filter_ttime = ""
        }
      }
      let mailtype_code = this.filter_mailtype
      let to_addresses = this.filter_to_addresses
      let status = this.filter_status

      const param = {
        "datetime_from":datetime_from,
        "datetime_to":datetime_to,
        "mailtype_code":mailtype_code,
        "to_addresses":to_addresses,
        "status":status,
      }

      mailhistoryService.getMailHistory(param, limit, offset).then(data => {
        this.mailhistoryitems = data.mailhistory
        this.totalcount = data.total
        this.loading = false
      })
    },
  },
  mounted () {
    this.getAllMailTypes()
    this.getListDataFromApi()
  },
}
</script>