/* eslint-disable no-console */
import { API } from 'aws-amplify'
import apiutil from "@/util/apiutil"

export default {

  async getMailHistory (param, limit, offset) {

    const token = await apiutil.getMepapiToken()

    let queryParameters= {
      offset: offset,
      limit: limit,
    }
    if (param['datetime_from']) {
      queryParameters['datetime_from'] = param['datetime_from']
    }
    if (param['datetime_to']) {
      queryParameters['datetime_to'] = param['datetime_to']
    }
    if (param['mailtype_code']) {
      queryParameters['mailtype_code'] = param['mailtype_code']
    }
    if (param['to_addresses']) {
      queryParameters['to_addresses'] = param['to_addresses']
    }
    if (param['status']) {
      queryParameters['status'] = param['status']
    }

    const myInit = {
      headers: {
        Authorization: token,
      },
      queryStringParameters: queryParameters,
    };
    const res = await API.get('mail', '/api/v1/mailHistory', myInit);
    return res
  },
  async getMailTypes () {
    const token = await apiutil.getMepapiToken()

    const myInit = {
      headers: {
        Authorization: token,
      },
    };
    const res = await API.get('mail', '/api/v1/mailType', myInit);
    return res
  },
}