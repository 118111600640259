var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","glid-list-md":""}},[_c('v-layout',{attrs:{"row":"","wrap":""}},[_c('v-container',{staticClass:"pa-5",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"elevation-1 mb-5",attrs:{"headers":_vm.statement_headers,"items":_vm.statement,"items-per-page":-1,"hide-default-footer":"","disable-filtering":"","disable-sort":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.statement_headers.filter(function (header) { return header.hasOwnProperty('formatter'); } 
        )),function(header){return {key:("item." + (header.value)),fn:function(ref){
        var header = ref.header;
        var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.download",fn:function(ref){
        var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [(item.canDownloadData)?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"medium":"","color":"primary"},on:{"click":function($event){_vm.downloadFile(
                  item.data_presigned_url, 
                  (item.data_file_name ? item.data_file_name : item.statement_no + '.json')
                  )}}},'v-icon',attrs,false),on),[_vm._v("mdi-code-json")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("JSONデータ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [(item.canDownloadPDF)?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"medium":"","color":"primary"},on:{"click":function($event){_vm.downloadFile(
                    item.pdf_presigned_url, 
                    (item.pdf_file_name ? item.pdf_file_name : item.statement_no + '.pdf')
                    )}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-pdf-box ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [(item.canDownloadExcel)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary"},on:{"click":function($event){_vm.downloadFile(
                    item.excel_presigned_url, 
                    (item.excel_file_name ? item.excel_file_name : item.statement_no + '.xslx')
                    )}}},'v-icon',attrs,false),on),[_vm._v("mdi-microsoft-excel ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("EXCEL")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [((item.manual_pdf_presigned_url != null))?_c('v-icon',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"medium":"","color":"deep-orange"},on:{"click":function($event){_vm.downloadFile(
                  item.manual_pdf_presigned_url, 
                  (item.manual_pdf_file_name ? item.manual_pdf_file_name : item.statement_no + '_MANUAL.pdf')
                  )}}},'v-icon',attrs,false),on),[_vm._v("mdi-file-pdf-box")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("マニュアル作成PDF")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [((item.manual_excel_presigned_url != null))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"deep-orange"},on:{"click":function($event){_vm.downloadFile(
                    item.manual_excel_presigned_url, 
                    (item.manual_excel_file_name ? item.manual_excel_file_name : item.statement_no + '_MANUAL.xslx')
                    )}}},'v-icon',attrs,false),on),[_vm._v("mdi-microsoft-excel ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("マニュアル作成EXCEL")])])],1)]}}],null,true)}),_c('div',{attrs:{"id":"list-table"}},[_c('v-data-table',{staticClass:"elevation-1 fixed-column-table",attrs:{"headers":_vm.headers,"items":_vm.records,"loading":_vm.search_loading,"items-per-page":-1,"height":"600","no-data-text":"明細データがありません","hide-default-footer":"","disable-filtering":"","disable-sort":"","fixed-header":"","dense":""},scopedSlots:_vm._u([_vm._l((_vm.headers),function(h,index){return {key:("header." + (h.value)),fn:function(ref){
                    var header = ref.header;
return [_vm._v(" "+_vm._s(h.text)+" "),(h.hint)?_c('v-tooltip',{key:index,attrs:{"bottom":"","color":"success"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"small":"","color":"success"}},on),[_vm._v("mdi-information")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.hint))])]):_vm._e()]}}}),_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); } 
        )),function(header,key){return {key:("item." + (header.value)),fn:function(ref){
        var header = ref.header;
        var value = ref.value;
return [([
          'irregular_type',
          'irregular_source',
          'fee_calculate_rule',
          'first_fee_in_order' ].includes(header.value))?_c('span',{key:key},[_vm._v(" "+_vm._s(header.formatter(header.value, value))+" ")]):_c('span',{key:key},[_vm._v(_vm._s(header.formatter(value))+" ")])]}}})],null,true)},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v("ID")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"7"}},[_vm._v("当初受注")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"7"}},[_vm._v("変キャン")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"2"}},[_vm._v("最新金額")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"3"}},[_vm._v("手数料方式")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("手数料金額")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("NEWTON伝票")]),_c('th',{staticStyle:{"text-align":"center"},attrs:{"colspan":"4"}},[_vm._v("比較金額")])]),_c('tr',_vm._l((_vm.headers),function(h,index){return _c('th',{key:index},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('span',_vm._g({},on),[_vm._v(_vm._s(h.text))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(h.text))])])],1)}),0)])])],1)],1),_c('v-footer',{attrs:{"fixed":""}},[_c('v-spacer'),_c('v-btn',{staticClass:"ma-5",attrs:{"dark":"","color":_vm.btn_color},on:{"click":function($event){return _vm.closeWindow()}}},[_vm._v(" 閉じる ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }