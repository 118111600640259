<template>
  <v-app>
    <Header :key="updateKey" />

    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>

  </v-app>
</template>
<script>
import Header from "@/components/Header";
/* eslint-disable no-console */
export default {
  name: 'app',
  components: {
    Header
  },
  data() {
    return {
        updateKey: "",
    }
  },
  mounted() {
    this.$store.watch(
        (state, getters) => getters.user,
        () => {
            this.updateKey = new Date().toLocaleString();
        }
    )
  }
}
</script>