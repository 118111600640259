<template>
  <v-container fluid glid-list-md>
    <v-layout row wrap>
      <v-card flat width="100%">
        <v-card-title>
          CMS申請管理
          <v-spacer></v-spacer>
          <v-alert
              class="text-subtitle-2"
              dense
              type="info"
              outlined
          >
            mep側のステータス更新後、mepopに反映されるまでタイムラグがあります。
          </v-alert>
        </v-card-title>
        <v-row>
          <v-col lg="12" md="12" sm="12">
            <v-alert dense outlined :type=this.process_message_type v-if="this.process_message">
              {{ this.process_message }}
            </v-alert>
          </v-col>
        </v-row>

          <v-row>
            <template>
              <v-spacer></v-spacer>
              <v-col cols="2">
                <v-autocomplete
                    dense
                    v-model="filter_application_status"
                    outlined placeholder="ステータス"
                    :items="applicationstatus" item-value="value" item-text="text">
                </v-autocomplete>
              </v-col>
              <v-col cols="3">
              <v-autocomplete
                  dense
                  v-model="filter_application_type"
                  outlined placeholder="コンテンツ種類" clearable
                  :items="applicationtype" item-value="value" item-text="text">
              </v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-menu
                    v-model="fdatemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        dense
                        clearable
                        v-model="filter_fdate"
                        label="From日付"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        class="ma-0"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                      v-model="filter_fdate"
                      @input="fdatemenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="2">
                <v-menu
                    v-model="ftimemenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense
                                  class="ma-0"
                                  clearable
                                  v-model="filter_ftime"
                                  label="From時刻"
                                  prepend-icon="mdi-clock-outline"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                      v-model="filter_ftime"
                      @input="ftimemenu = false"
                      format="24hr"
                      scrollable
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-btn :outlined=search_btn_outline
                       :color=search_btn_color
                       class="ml-5"
                       @click="getListDataFromApi()">
                  <v-icon class="mr-1">mdi-pencil-outline</v-icon>
                  検索</v-btn>
              </v-col>
            </template>
          </v-row>
        <v-divider></v-divider>
        <template>
            <v-card class="ma-3" color="blue-grey lighten-5" flat>

              <v-card-title class="mt-2 pt-2 pl-2 pb-0 white" align="top">
              <v-autocomplete
                  dense
                  v-model="table_filter_application_type"
                  outlined placeholder="ページ種類フィルタ" clearable
                  :items="applicationtype" item-value="value" item-text="text">
              </v-autocomplete>
              <v-spacer></v-spacer>
              <v-autocomplete
                  dense
                  v-model="table_filter_partner"
                  outlined placeholder="パートナーフィルタ" clearable
                  :items="partners" item-value="official_name" item-text="official_name">
              </v-autocomplete>
              <v-spacer></v-spacer>
              <v-text-field
                  dense
                  v-model="search"
                  append-icon="mdi-filter"
                  label="テキストフィルタ"
              ></v-text-field>
              </v-card-title>
            </v-card>

        </template>

        <v-divider></v-divider>
        <v-data-table
            :headers="headers"
            :items="cmsapplicationitems"
            :loading="loading"
            :search="search"
            show-expand
            class="elevation-1"
        >
          <template
              v-for="header in headers.filter((header) =>
              header.hasOwnProperty('formatter')
            )"
              v-slot:[`item.${header.value}`]="{ header, value }"
          >
            {{ header.formatter(value) }}
          </template>
          <template v-slot:item.previewaction="{ item }">
            <v-btn :href="getPreviewUrl(item)"
                   target="_blank"
                   color="pink" outlined small>
            プレビュー</v-btn>
          </template>
          <template v-slot:item.viewaction="{ item }">
            <v-btn :href="getOpenUrl(item)"
                   target="_blank"
                   color="pink" outlined small>
              開く</v-btn>
          </template>
          <template v-slot:item.declineaction="{ item }">
            <v-btn :disabled=checkActionDisability(item)
                   :loading="checkActionBtnLoading(item, 'deny')"
                   @click="confirmCMSApplicationAction(item,'deny')"
                   color="blue-grey lighten-4"
                   small
                   width="100%">要修正</v-btn><br/>
          </template>
          <template v-slot:item.approveaction="{ item }">
            <v-btn v-if="item.application_status=='applying' && item.page_status!='page-public'"
                   :disabled=checkActionDisability(item)
                   :loading="checkActionBtnLoading(item, 'open-private')"
                   @click="confirmCMSApplicationAction(item,'open-private')"
                   class="mb-1" color="light-green lighten-4"
                   small
                   width="100%">テスト公開</v-btn>
            <v-btn :disabled=checkActionDisability(item)
                   :loading="checkActionBtnLoading(item, 'open-public')"
                   @click="confirmCMSApplicationAction(item,'open-public')"
                   class="mb-1 font-weight-bold" color="blue lighten-3"
                   small
                   width="100%">公開</v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container fluid>
                <v-row dense width="100%">
                  <v-col cols="7">
                    <v-card elevation="1" outlined>
                      <v-card-text>
                        <span>申請ID: <v-chip color="green" outlined @click="$copyText(item.id)">{{ item.id }}</v-chip></span>
                        <span class="ml-5">ページID: <v-chip v-if="item.application_type.indexOf('partner_page')==0" color="green" outlined @click="$copyText(item.cms_partner_page_id)">{{ item.cms_partner_page_id }}</v-chip>
                        <v-chip v-else-if="item.application_type.indexOf('service_card')==0" color="green" outlined @click="$copyText(item.cms_service_card_id)">{{ item.cms_service_card_id }}</v-chip></span>
                        <span class="ml-5">コンテンツID: <v-chip v-if="item.application_type.indexOf('partner_page')==0" color="green" outlined @click="$copyText(item.cms_partner_page_content_id)">{{ item.cms_partner_page_content_id }}</v-chip>
                        <v-chip v-else-if="item.application_type.indexOf('service_card')==0" color="green" outlined @click="$copyText(item.cms_service_card_content_id)">{{ item.cms_service_card_content_id }}</v-chip></span>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
              </td>

            </template>
        </v-data-table>

        <v-dialog
            v-model="confirm_dialog"
            persistent
            max-width="600"
        >
          <v-card>
            <v-card-title class="text-h6">
              CMSコンテンツ処理を行います
            </v-card-title>
            <v-card-text>
              <v-simple-table class="pa-2">
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">パートナー</td><td class="pa-2">{{ confirm_dialog_texts.partner }}</td></tr>
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">ページ種類</td><td class="pa-2">{{ $t("cms.application_type." + confirm_dialog_texts.application_type) }}</td></tr>
                <tr v-if="confirm_dialog_texts.application_type.indexOf('service_card') == 0">
                  <td width="30%" class="pa-2 blue-grey lighten-5">サービスカード名</td><td class="pa-2">{{ confirm_dialog_texts.servicecard_title }}</td></tr>
                <tr><td width="30%" class="pa-2 blue-grey lighten-5">処理</td><td class="pa-2 font-weight-bold red--text">{{ $t("cms.action_type." + confirm_dialog_texts.action_type) }}</td></tr>
              </v-simple-table>
              <v-sheet v-if="confirm_dialog_texts.action_type=='deny'" class="pa-2">
              <span class="pa-2 blue-grey lighten-5">修正理由</span>
              <v-textarea
                  v-model="confirm_dialog_texts.action_reason"
                  name="input-7-1"
                  label="修正箇所など"
                  auto-grow
                  value=""
                  outlined
                  class="ma-2"
              ></v-textarea>
              </v-sheet>
              <v-alert dense outlined type="error" v-if="confirm_dialog_texts.action_type=='open-private' && confirm_dialog_texts.page_status=='page-public'">
                公開中のページでテスト公開処理をしようとしています。テスト公開処理をすることで、顧客ユーザからは該当ページが見えなくなります。
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="confirm_dialog=false;action_loading_application={}">キャンセル</v-btn>
              <v-btn color="blue darken-4" outlined @click="postCMSApplicationAction();confirm_dialog=false;">処理する</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import cmsApplicationService from '@/services/cmsApplicationService'
import moment from 'moment'
import i18n from "@/i18n";
import formatterutil from "@/util/formatter"

export default {
  name: 'cmsapplication',
  title: 'CMS申請管理',
  data () {
    return {
      cmsapplicationitems: [],
      loading: true,
      headers: [],
      search: '',
      search_btn_color: "light-blue darken-4",
      search_btn_outline: true,
      search_finished: false,
      search_finished_applicationstatus: 'applying',
      process_message:'',
      process_message_type:'',
      partners:[],
      applicationstatus: [
          {value: 'applying', text: '申請中'},
          {value: 'testing', text:'テスト公開中'},
        {value: 'opening', text:'公開中'},],
      applicationtype: [
          {value: 'partner_page', text: 'パートナーページ'},
          {value: 'service_card', text:'サービスカード'},],
      filter_application_status: 'applying', // 初期値
      filter_application_type: '',
      filter_fdate: '',
      filter_ftime: '',
      table_filter_partner: '',
      table_filter_application_type: '',
      fdatemenu: false,
      ftimemenu: false,
      action_disabled_application_id:[], // リアルタイムでDBに承認ステータスを持ってきていないので、ステータス更新を一度したらいったん画面上でボタンを押せなくするために申請IDを保持
      action_loading_application:{}, // ローディングをつけるアクションボタン特定用。id:actiontype
      confirm_dialog:false,
      confirm_action:"",
      confirm_dialog_texts:{
        application_id:'',
        application_type:'',
        action_type:'',
        partner:'',
        servicecard_title:'',
        action_reason:'',
        page_status:''
      }
    }
  },
  methods: {
    partnerFilter(value) {
      if (!this.table_filter_partner) {
        return true
      }
      return value.indexOf(this.table_filter_partner) >= 0
    },
    applicationTypeFilter(value) {
      if (!this.table_filter_application_type) {
        return true
      }
      return value.indexOf(this.table_filter_application_type) >= 0
    },
    getPreviewUrl(item) {
      const type = item.application_type
      const partnerpageid = item.cms_partner_page_content_id
      const servicecardid = item.cms_service_card_content_id
      const previewurl = process.env.VUE_APP_MEP_PARTNER_URL + "ja-jp/app/serviceEdit/preview/"
      if(type.indexOf("service_card") ===0) {
        return previewurl + "card/card-id:" + servicecardid
      } else if(type.indexOf("partner_page") ===0) {
        return previewurl + "partner/partner-id:" + partnerpageid
      }
    },
    getOpenUrl(item) {
      const type = item.application_type
      //const partnerpageid = item.cms_partner_page_id
      const partnerid = item.supplier_id
      const servicecardid = item.cms_service_card_id
      const previewurl = process.env.VUE_APP_MEP_CLIENT_URL + "ja-jp/app/fa/servicecards/"
      if(type.indexOf("service_card") ===0) {
        return previewurl + "card/card-id:" + servicecardid
      } else if(type.indexOf("partner_page") ===0) {
        return previewurl + "partner/partner-id:" + partnerid
      }
    },
    getListDataFromApi () {
      this.loading = true

      this.process_message=""
      this.process_message_type=""
      this.action_loading_application = {}

      let datetime_from = this.filter_fdate
      if (datetime_from) {
        if (this.filter_ftime) {
          datetime_from += " "
          datetime_from += this.filter_ftime
        } else {
          this.filter_ftime = "00:00"
          datetime_from += " 00:00:00"
        }
        datetime_from = moment(datetime_from).utc().format('YYYY-MM-DD HH:mm:ss');
      } else {
        if (this.filter_ftime) {
          this.filter_ftime = ""
        }
      }

      let application_status = this.filter_application_status
      let application_type = this.filter_application_type

      const param = {
        "application_status":application_status,
        "application_type":application_type,
        "latest_edit_at":datetime_from,
      }

      cmsApplicationService.getCmsApplications(param).then(data => {
        this.cmsapplicationitems = data.cmsapplications
        this.partners = data.partners
        this.action_disabled_application_id = data.holdItems
        this.loading = false
        this.search_btn_color="light-blue darken-4"
        this.search_btn_outline=true
        this.search_finished=true
      })
    },
    checkActionDisability(item) {
      if (this.action_disabled_application_id.indexOf(item.id)>=0) {
        return true
      } else {
        return false
      }
    },
    checkActionBtnLoading(item, btnname) {
      if (item.id in this.action_loading_application) {
        const actiontype = this.action_loading_application[item.id]
        if (actiontype == btnname) {
          return true
        } else {
          return false
        }
      }
    },
    confirmCMSApplicationAction(item, btnname) {
      this.setConfirmDialogText(item, btnname)
      this.confirm_dialog = true
      this.action_loading_application[item.id] = this.confirm_dialog_texts.action_type
    },

    postCMSApplicationAction() {
      var processitem = {}
      this.process_message=""
      this.process_message_type=""

      // 処理
      // 全件回すのはよろしくないが、この画面ではサーバから取得するアイテム数が一定で抑えられる見込み（最低限ステータスでフィルタがかかっている）
      for (const applicationitem in this.cmsapplicationitems) {
        if (this.confirm_dialog_texts.application_id == this.cmsapplicationitems[applicationitem]['id']) {
          processitem = this.cmsapplicationitems[applicationitem]
          break;
        }
      }

      cmsApplicationService.processCmsApplication(processitem, this.confirm_dialog_texts.action_type, this.confirm_dialog_texts.action_reason).then(data => {
        if (data >= 500) {
          this.process_message = "処理エラーが発生しました。コンテンツ申請ステータスが最新でない可能性があります。5分ほど後に再読み込みをしてください。しばらく時間を置いても解消されない場合、レコードを展開して表示される申請ID・ページID・コンテンツIDと合わせて基盤開発チームへご連絡ください"
          this.process_message_type = "error"
          this.action_loading_application = {}
        } else if (data >= 400) {
          this.process_message = "パラメータチェックに失敗しました。レコードを展開して表示される申請ID・ページID・コンテンツIDと合わせて基盤開発チームへご連絡ください"
          this.process_message_type = "error"
          this.action_loading_application = {}
        } else if (data == 200) {
          this.process_message = i18n.t("cms.action_type." + this.confirm_dialog_texts.action_type) + "処理が完了しました。"
          this.process_message_type = "info"
          this.action_disabled_application_id.push(processitem.id)
          this.action_disabled_application_id = Array.from(new Set(this.action_disabled_application_id))
          this.action_loading_application = {}
        }
      })
    },
    setConfirmDialogText(item, btnname) {
      this.confirm_dialog_texts = {
        application_id: item.id,
        application_type: item.application_type,
        action_type: btnname,
        partner: item.official_name,
        servicecard_title: item.title,
        action_reason: '',
        page_status: item.page_status
      }
    }
  },
  watch: {
    filter_application_status: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    filter_application_type: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    filter_fdate: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    filter_ftime: function() {
      this.search_btn_color="orange"
      this.search_btn_outline=false
      this.search_finished=false
    },
    search_finished: function(newvar) {
      if(newvar == true) {
        const orgheaders = [
          {
            text: 'ページ種類',
            align: 'start',
            sortable: true,
            value: 'application_type',
            formatter: formatterutil.cmsApplicationTypeFormatter,
            filter: this.applicationTypeFilter,
          },
          {
            text: 'パートナー',
            align: 'start',
            sortable: true,
            value: 'official_name',
            filter: this.partnerFilter,
          },
          {text: 'サービスカード', align: 'start', sortable: true, value: 'title'},
          {text: '申請者', align: 'start', sortable: true, value: 'apply_by_name'},
          {
            text: '編集ステータス',
            align: 'start',
            sortable: true,
            value: 'application_status',
            formatter: formatterutil.cmsApplicationStatusFormatter,
          },
          {
            text: '公開ステータス',
            align: 'start',
            sortable: true,
            value: 'page_status',
            formatter: formatterutil.cmsPageStatusFormatter,
          },
          {
            text: '申請日時',
            align: 'start',
            sortable: true,
            value: 'apply_at',
            formatter: formatterutil.datetimeFormatter,
           },
          {
            text: '更新日時',
            align: 'start',
            sortable: true,
            value: 'latest_edit_at',
            formatter: formatterutil.datetimeFormatter,
           },
          {text: 'プレビュー', value: 'previewaction'},
          {text: '開く', value: 'viewaction'},
          {text: '要修正', value: 'declineaction', align: 'center'},
          {text: '承認', value: 'approveaction', align: 'center'},
          {text: '', value: 'data-table-expand' },
        ]

        var typeheaders = []
        if (this.filter_application_status == 'applying') {
          typeheaders = ['application_type', 'official_name', 'apply_by_name', 'title', 'application_status', 'page_status', 'apply_at', 'previewaction', 'declineaction', 'approveaction']
        } else if (this.filter_application_status == 'testing') {
          typeheaders = ['application_type', 'official_name', 'apply_by_name', 'title', 'application_status', 'page_status', 'apply_at', 'previewaction', 'approveaction']
        } else {
          typeheaders = ['application_type', 'official_name', 'title', 'application_status', 'page_status', 'latest_edit_at', 'viewaction']
        }

        var selectedheaders = []
        for (const headeritem in orgheaders) {
          if (typeheaders.includes(orgheaders[headeritem]['value'])) {
            selectedheaders.push(orgheaders[headeritem])
          }
        }
        this.headers = selectedheaders
      }

    }
  },
  mounted () {
    this.search = ''
    this.getListDataFromApi()
  },
}
</script>