<template>
  <v-app-bar
      app
      color="light-blue darken-4"
      dark
      dense
  >
    <v-menu
        bottom
        left
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            v-on="on"
        >
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list dense nav>
        <template v-for="nav_list in nav_lists">
            <v-list-item v-if="nav_list.linkto" :key="nav_list.name" :to="nav_list.linkto">
                <v-list-item-icon>
                    <v-icon>{{ nav_list.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <nested-menu v-if='nav_list.accountmenu' :key='nav_list.name' :nav-name='nav_list.name'
            :nav-icon='nav_list.icon' :menu-items='account_nav_lists' v-on:nested-menu-click='onMenuItemClick' />
        </template>
      </v-list>
    </v-menu>
    <v-app-bar-title>mepop</v-app-bar-title>
    <v-spacer></v-spacer>
    <v-btn v-if="email" class="pr-2" text>{{email}}</v-btn>
  </v-app-bar>
</template>

<script>
  import NestedMenu from './NestedMenu.vue'
  export default {
    name: 'Header',
    components: {
      NestedMenu
    },
    data () {
        return {
            email: this.$store.state.user ? this.$store.state.user.attributes.email : "",
            nav_lists: [{name:"Home", linkto:"/",icon:'mdi-home'},
                {name:"顧客案件状態一覧", linkto:"/usertrack",icon:'mdi-file-table-box-outline'},
                {name:"アカウント登録", icon:"mdi-account-settings", accountmenu: true},
                {name:"パートナー登録申請一覧", linkto:"/candidate",icon:'mdi-account-plus'},
                {name:"CMS申請管理", linkto:"/cmsapplication",icon:'mdi-book-open-page-variant-outline'},
                {name:"手数料明細", linkto:"/transactionhistory",icon:'mdi-calculator'},
                {name:"メール送信履歴", linkto:"/mailhistory",icon:'mdi-email-multiple-outline'},
                {name:"Releases", linkto:"/versions",icon:'mdi-rocket-launch-outline'},
                {name: this.$store.state.user ? "Sign Out" : "Sign In", linkto: "/signin", icon: 'mdi-account-circle'}],
            account_nav_lists: [
                {name:"パートナー登録申請サイト アカウント登録・一覧・削除", linkto: "/accountregister/partner", icon: 'mdi-account-settings'},
                {name:"mepopユーザ アカウント登録・一覧・削除", linkto: "/accountregister/mepop", icon: 'mdi-account-settings'},
            ],
        }
    },
    methods: {
        onMenuItemClick () {
            document.getElementById("app").click();
        },
    },
  }
</script>
