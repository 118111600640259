<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        mepop系リリース履歴
      </v-card-title>
      <v-sheet class="ml-auto" max-width="300" flat>
        <v-select
            :items="releaseapps"
            v-model="selectedapp"
            label="アプリケーション"
            outlined
        ></v-select>
      </v-sheet>
      <v-sheet class="mx-auto" max-width="90%" flat>
          <v-timeline dense>
            <v-timeline-item v-for="item in versions" :key=item.version color="light-blue darken-4" fill-dot small>
                <template v-slot:opposite>
                  <span
                      :class="`headline font-weight-bold`"
                      v-text="item.date"
                  ></span>
                </template>
                <v-card class="elevation-2">
                  <v-card-title :class="`headline font-weight-light`">
                    {{item.version}}
                  </v-card-title>
                  <v-list dense v-for="ticket in item.tickets" :key=ticket.id>
                    <v-btn :href="getJiraUrl(ticket.id)"
                           target="_blank"
                           text
                           color="light-blue darken-4">
                    {{ ticket.id }}</v-btn>{{ ticket.title }}
                  </v-list>
                </v-card>
            </v-timeline-item>
          </v-timeline>
      </v-sheet>
    </v-card>
  </v-container>
</template>
<style>
:root {
  --timeline-opposite-item-width: 160px;
  --timeline-line-width: 8px;
}

.v-timeline--dense .v-timeline-item__opposite {
  display: inline-block;
}

.v-timeline-item__opposite {
  flex: none;
  min-width: var(--timeline-opposite-item-width);
}

/* line: divider in the middle is 96px wide by default */
.v-application--is-ltr .v-timeline--dense:not(.v-timeline--reverse):before {
  left: calc(
      var(--timeline-opposite-item-width) +
      (96px - var(--timeline-line-width)) / 2
  );
  width: var(--timeline-line-width);
}
</style>
<script>
import releaseService from "@/services/releaseService";
import VersionForm from "@/forms/VersionForm";

export default {
  name: 'Release',
  title: 'Releases',
  data () {
    return {
      versions: VersionForm,
      allversions: VersionForm,
      selectedapp: "",
      releaseapps:["ALL", "mepop", "mepop-front","mepop-partner","mepop-front-api","mepop-partner-api", "mepop-usertrack", "mepmailman", "mepopcore"]
    }
  },
  methods: {
    getJiraUrl(ticket_id) {
      return "https://tickets.tools.misumi.jp/jira/browse/" + ticket_id
    }
  },
  watch: {
    selectedapp: {
      handler () {
        if(this.selectedapp == "ALL") {
          this.versions = this.allversions
        } else {
          let filteredlist = []
          const filterstr = this.selectedapp + " v"
          for (const item in this.allversions) {
            if (this.allversions[item]['version'].indexOf(filterstr) === 0) {
              filteredlist.push(this.allversions[item])
            }
          }
          this.versions = filteredlist
        }
      },
      deep: true,
    },
  },
  async created () {
    const versions = await releaseService.getReleases()
    this.allversions = versions
    this.versions = versions
  },
}
</script>