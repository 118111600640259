<template>
  <v-container>
    <v-layout row wrap>
        <v-card flat width="100%">
            <v-card-title>
            {{ appsitename }} アカウント登録
            </v-card-title>
            <v-alert
                border="left"
                colored-border
                type="info"
                elevation="2"
            >
            Eメールアドレスがアカウント名となります。登録後、登録したEメールアドレスに仮パスワードを送信します。メールを受信した{{ apphuman }}は、メール文面に沿って、{{ appsite }}に初回ログインを行い、パスワードを再設定する手順となります。
            </v-alert>
        </v-card>

        <v-card flat width="50%">
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-text-field
                   :label="appemail"
                    placeholder="you@example.com"
                    clearable
                    :rules="[rules.required, rules.email]"
                    v-model="form.email"
                >
                    <template v-slot:append-outer>
                        <v-btn :disabled="isInValidEmail" color="primary" @click="confirmRegisterAccount">登録</v-btn>
                    </template>
                </v-text-field>
            </v-form>
        </v-card>

        <v-card flat width="100%">
            <v-data-table
                :headers="headers"
                :items="userpoolusers"
                item-key="email"
                class="elevation-1"
                :search="search"
                :loading="loadingvariable"
            >

                <template v-slot:[`item.account_delete`]="{ item }">
                    <v-btn color="error" @click="deleteConfirm(item.email)">削除</v-btn>
                </template>

                <template v-slot:top>
                    <v-text-field
                        v-model="search"
                        :label="appemail"
                        class="mx-4"
                        clearable
                    ></v-text-field>
                </template>
            </v-data-table>
        </v-card>

        <v-dialog v-model="confirmRegisterDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">登録確認</v-card-title>
                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        Eメール: {{ form.email }}
                    </div>
                    <div>{{ confirmRegisterAccountMessage }}</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="confirmRegisterDialog = false">キャンセル</v-btn>
                    <v-btn color="green darken-1" text @click="registerAccount">登録</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">削除確認</v-card-title>
                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        Eメール: {{ deleteAccount }}
                    </div>
                    <div>を削除します。よろしいでしょうか？</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="deleteDialog = false">キャンセル</v-btn>
                    <v-btn color="green darken-1" text @click="deleteItem(deleteAccount)">削除</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="banDeleteDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">削除不可</v-card-title>
                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        ご自身のEメール: {{ banDeleteAccount }}
                    </div>
                    <div>を削除できません。</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="banDeleteDialog = false">了解</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="banRegisterDialog" persistent max-width="600">
            <v-card>
                <v-card-title class="headline">登録不可</v-card-title>
                <v-card-text>
                    <div class="my-4 text-subtitle-1">
                        Eメール: {{ form.email }}
                    </div>
                    <div>は既に登録されています。</div>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="banRegisterDialog = false">了解</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
/* eslint-disable no-console */
import accountRegisterService from '../services/accountRegisterService'
import store from "@/store";

const _apptype = new Map([['partner', 'パートナー登録申請サイト'], ['mepop', 'mepopユーザ']]);
const _appsite = new Map([['partner', 'パートナー登録申請サイト'], ['mepop', 'mepop']]);
const _appemail = new Map([['partner', 'パートナー担当者側 Eメール'], ['mepop', 'mepopユーザ Eメール']]);
const _apphuman = new Map([['partner', '担当者'], ['mepop', 'mepopユーザ']]);
const _appuserpool = new Map([['partner', 'mepopPartnerUserPool'], ['mepop', 'mepopUserPool']]);

export default {
    name: 'AccountRegister',
    title: 'アカウント登録',
    props: {
        userpool: String,
    },
    data() {
        return {
            appsitename: "",
            appsite: "",
            appemail: "",
            form: {
                email: "",
            },
            apphuman: "",
            appuserpool: "",
            rules: {
                required: value => !!value || 'This field is required.',
                email: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Not an email address.'
                }
            },
            confirmRegisterDialog: false,
            deleteDialog: false,
            deleteAccount: null,
            banDeleteDialog: false,
            banRegisterDialog: false,
            banDeleteAccount: null,
            search: '',
            userpoolusers: [],
            regexMisumiEmail: new RegExp(/^.*@misumi\.co\.jp$/),
            confirmRegisterAccountMessage: "",
            loadingvariable: false,
            valid: false,
            lazy: false,
        }
    },
    computed: {
        headers () {
            return [
                {
                    text: 'Eメール',
                    align: 'start',
                    sortable: true,
                    value: 'email',
                },
                {
                    text: 'ステータス',
                    sortable: true,
                    filterable: false,
                    value: 'status',
                },
                {
                    text: 'アカウント削除',
                    filterable: false,
                    value: 'account_delete',
                },
            ]
        },
        isInValidEmail(){
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const reg = new RegExp(pattern);
            return !reg.test(this.form.email);
        }
    },
    created() {
        this.appsitename = _apptype.get(this.$route.params.userpool)
        this.appsite = _appsite.get(this.$route.params.userpool)
        this.appemail = _appemail.get(this.$route.params.userpool)
        this.apphuman = _apphuman.get(this.$route.params.userpool)
        this.appuserpool = _appuserpool.get(this.$route.params.userpool)
        this.getUserPoolUsers()
    },
    watch: {
        '$route'(to) {
            this.appsitename = _apptype.get(to.params.userpool)
            this.appsite = _appsite.get(this.$route.params.userpool)
            this.appemail = _appemail.get(to.params.userpool)
            this.apphuman = _apphuman.get(to.params.userpool)
            this.appuserpool = _appuserpool.get(to.params.userpool)
            this.userpoolusers = []
            this.search = ""
            this.getUserPoolUsers()
            this.$refs.form.reset()
        },
    },
    methods: {
        getUserPoolUsers() {
            this.loadingvariable = true
            accountRegisterService.getUserPoolUserList(this.appuserpool).then(
                response => {
                    const res_data = response.body
                    const obj = JSON.parse(res_data)
                    this.userpoolusers = obj.Records
                    this.loadingvariable = false
                }
            )
        },
        confirmRegisterAccount() {
            var index = this.userpoolusers.findIndex(e => e.email === this.form.email);
            if (index !== -1) {
                this.banRegisterDialog = true;
                return
            }

            if (this.appuserpool == 'mepopUserPool' && !this.regexMisumiEmail.test(this.form.email)) {
                this.confirmRegisterAccountMessage = "ミスミ以外のメールアドレスでmepopユーザを作成しようとしています。続行しますか？"
            } else if (this.appuserpool == 'mepopPartnerUserPool' && this.regexMisumiEmail.test(this.form.email)) {
                this.confirmRegisterAccountMessage = "ミスミのメールアドレスでパートナー登録申請サイトユーザを作成しようとしています。続行しますか？"
            } else {
                this.confirmRegisterAccountMessage = "を登録します。よろしいでしょうか。"
            }
            this.confirmRegisterDialog = true;
        },
        registerAccount() {
            accountRegisterService.registerAccountToUserPool(this.appuserpool, this.form.email).then(
                response => {
                    response.body
                    this.getUserPoolUsers();
                    this.$refs.form.reset();
                }
            )
            this.confirmRegisterDialog = false;
        },
        deleteConfirm(account) {
            this.deleteDialog = true;
            this.deleteAccount = account;
        },
        deleteItem(deleteEmail) {

            if (this.appuserpool == 'mepopUserPool' &&
                deleteEmail == store.state.user.signInUserSession.idToken.payload.email) {
                this.banDeleteDialog = true;
                this.banDeleteAccount = deleteEmail;
                this.deleteDialog = false;
            } else {
                accountRegisterService.deleteAccountFromUserPool(this.appuserpool, deleteEmail).then(
                    response => {
                        response.body
                        this.search = "";
                        this.getUserPoolUsers();
                    }
                )
                this.deleteDialog = false;
            }
        },
    }
}
</script>
