<script> 
  export default { 
    data: () => ({
      bank_attachment_filename: { id:'bank_attachment_filename', name:'', value: [], hint:''},
      company_name_kanji : {id:'company_name_kanji', name:'', value: '', hint:''},
      bank_code : {id:'bank_code', name:'', value: '', hint:''},
      branch_code : {id:'branch_code', name:'', value: '', hint:''},
      bank_name : {id:'bank_name', name:'', value: '', hint:''},
      branch_name : {id:'branch_name', name:'', value: '', hint:''},
      account_type_message : {id:'account_type_message', name:'', value: '', hint:''},
      account_number : {id:'account_number', name:'', value: '', hint:''},
      account_name : {id:'account_name', name:'', value: '', hint:''},
    }),
  }
</script>