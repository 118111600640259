/* eslint-disable no-console */
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import store from "@/store";
import * as charconv from "@/util/charconverter";
import i18n from "@/i18n";
//import PartnerForm from "@/forms/PartnerForm";

export default {

  async getPartnerCandidateHeader () {
    const listPartnerCandidate = `query getPartnerCandidate($operation:String) {
      getPartnerCandidate(operation:$operation) {
        id
        status
        company_name_kanji
        supplier_cd
        customer_cd
        assignee_name
        complete_elapsed_seconds
        complete_datetime
        regist_user_name
        opened_datetime
        update_datetime
      }
    }`
    const params = {
      operation: "ADMIN"
    }
    const partnercandidate = await API.graphql(graphqlOperation(listPartnerCandidate, params))
    return partnercandidate.data.getPartnerCandidate

  },
  async getPartnerCandidateById (id, operation) {
    const listPartnerCandidate = `query getPartnerCandidate($id:String, $operation:String) {
      getPartnerCandidate(id:$id, operation:$operation) {
        id
        supplier_cd
        customer_cd
        regist_user_cognito_sub
        regist_user_name
        regist_user_email
        status
        bank_status
        prosugate_status
        gmind_status
        assignee_id
        assignee_name
        complete_elapsed_seconds
        complete_datetime
        memo
        mep_servicedesk_orgid
        mep_supplier_id
        company_id
        person_id
        bank_id
        comission_person_id
        create_datetime
        update_datetime
        supplier_cd
        customer_cd
        company_name_kanji
        company_name_kana
        zip_cd
        address_1_kanji
        address_2_kanji
        address_1_kana
        address_2_kana
        tel
        invoice_no
        representative_name
        representative_title
        person_name_sei_kanji
        person_name_mei_kanji
        person_name_sei_kana
        person_name_mei_kana
        buka_name_kanji
        buka_name_kana
        person_tel
        person_fax
        person_email
        mep_servicedesk_account_id
        bank_code
        branch_code
        bank_name
        branch_name
        account_type
        account_number
        account_name
        bank_attachment
        comission_person_name_sei_kanji
        comission_person_name_mei_kanji
        comission_person_name_sei_kana
        comission_person_name_mei_kana
        comission_person_tel
        comission_person_email
      }
    }`
    const params = {
      id: id,
      operation: operation
    }
    const partnercandidate = await API.graphql(graphqlOperation(listPartnerCandidate, params))
    const partnercandidateData = partnercandidate.data.getPartnerCandidate[0]

    const form = {
      isadminform: true,
      id: partnercandidateData.id,
      supplier_cd: partnercandidateData.supplier_cd,
      customer_cd: partnercandidateData.customer_cd,
      regist_user_cognito_sub: partnercandidateData.regist_user_cognito_sub,
      regist_user_name: partnercandidateData.regist_user_name,
      regist_user_email: partnercandidateData.regist_user_email,
      create_datetime: partnercandidateData.create_datetime,
      update_datetime: partnercandidateData.update_datetime,
      status: partnercandidateData.status,
      bank_status: partnercandidateData.bank_status,
      prosugate_status: partnercandidateData.prosugate_status,
      gmind_status: partnercandidateData.gmind_status,
      assignee_id: partnercandidateData.assignee_id,
      assignee_name: partnercandidateData.assignee_name,
      complete_elapsed_seconds: this.secondsToDatetimeFormatter(partnercandidateData.complete_elapsed_seconds),
      complete_datetime: partnercandidateData.complete_datetime,
      company_name_kanji: partnercandidateData.company_name_kanji,
      company_name_kana: partnercandidateData.company_name_kana,
      zip_cd: partnercandidateData.zip_cd,
      address_1_kanji: partnercandidateData.address_1_kanji,
      address_2_kanji: partnercandidateData.address_2_kanji,
      address_1_kana: partnercandidateData.address_1_kana,
      address_2_kana: partnercandidateData.address_2_kana,
      tel: partnercandidateData.tel,
      invoice_no: this.invoiceFormatter(partnercandidateData.invoice_no),
      representative_name: partnercandidateData.representative_name,
      representative_title: partnercandidateData.representative_title,
      mep_servicedesk_orgid: partnercandidateData.mep_servicedesk_orgid,
      mep_supplier_id: partnercandidateData.mep_supplier_id,
      person_name_sei_kanji: partnercandidateData.person_name_sei_kanji,
      person_name_mei_kanji: partnercandidateData.person_name_mei_kanji,
      person_name_sei_kana: partnercandidateData.person_name_sei_kana,
      person_name_mei_kana: partnercandidateData.person_name_mei_kana,
      buka_name_kanji: partnercandidateData.buka_name_kanji,
      buka_name_kana: partnercandidateData.buka_name_kana,
      person_tel: partnercandidateData.person_tel,
      person_fax: partnercandidateData.person_fax,
      person_email: partnercandidateData.person_email,
      mep_servicedesk_account_id: partnercandidateData.mep_servicedesk_account_id,
      person_email_confirm: partnercandidateData.person_email,
      bank_code: partnercandidateData.bank_code,
      branch_code: partnercandidateData.branch_code,
      bank_name: partnercandidateData.bank_name,
      branch_name: partnercandidateData.branch_name,
      account_type: partnercandidateData.account_type,
      account_number: partnercandidateData.account_number,
      account_name: partnercandidateData.account_name,
      bank_attachment_filename: JSON.parse(partnercandidateData.bank_attachment),
      comission_person_name_sei_kanji: partnercandidateData.comission_person_name_sei_kanji,
      comission_person_name_mei_kanji: partnercandidateData.comission_person_name_mei_kanji,
      comission_person_name_sei_kana: partnercandidateData.comission_person_name_sei_kana,
      comission_person_name_mei_kana: partnercandidateData.comission_person_name_mei_kana,
      comission_person_tel: partnercandidateData.comission_person_tel,
      comission_person_email: partnercandidateData.comission_person_email,
      comission_person_email_confirm: partnercandidateData.comission_person_email,
    }


    const supplierinfoform = await this.getSupplierInfoForm(form)
    const bankinfoform = await this.getBankInfoForm(form)
    const customerinfoform = await this.getCustomerInfoForm(form)
    const prosugateinfoform = await this.getProsugateInfoForm(form)
    const gmindinfoform = await this.getGmindInfoForm(form)
    const jirainfoform = await this.getJiraInfoForm(form)
    const meppartnerinfoform = await this.getMepParnterInfoForm(form)
    return {
      form: form,
      supplierinfoform: supplierinfoform,
      bankinfoform: bankinfoform,
      customerinfoform: customerinfoform,
      prosugateinfoform: prosugateinfoform,
      gmindinfoform: gmindinfoform,
      jirainfoform: jirainfoform,
      meppartnerinfoform: meppartnerinfoform,
    }
  },
  async getSupplierInfoForm (form) {
    const supplierinfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'仕入先名（漢字）', value: charconv.han2zen(form.company_name_kanji), hint:'（全角）', showcopy:false},
      company_name_kana : {id:'company_name_kana', name:'仕入先名（フリガナ）', value: charconv.zen2han(form.company_name_kana), hint:'（半角カナ30文字）', showcopy:false},
      buka_name_kanji : {id:'buka_name_kanji', name:'部課名（漢字）', value: charconv.han2zen(form.buka_name_kanji), hint:'（全角15文字）', showcopy:false},
      buka_name_kana : {id:'buka_name_kana', name:'部課名（フリガナ）', value: charconv.zen2han(form.buka_name_kana), hint:'（半角カナ30文字）', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'担当者名（漢字）', value: charconv.han2zen(form.person_name_sei_kanji + charconv.SP_ZEN + form.person_name_mei_kanji), hint:'（全角10文字）', showcopy:false},
      person_name_kana : {id:'person_name_kana', name:'担当者名（フリガナ）', value: charconv.zen2han(form.person_name_sei_kana + charconv.SP_HAN + form.person_name_mei_kana), hint:'（半角カナ20文字）', showcopy:false},
      zip_cd : {id:'zip_cd', name:'郵便番号', value: form.zip_cd, hint:'（ハイフン含む8桁）', showcopy:false},
      address_1_kanji : {id:'address_1_kanji', name:'住所1', value: charconv.han2zen(form.address_1_kanji), hint:'（全角15文字）', showcopy:false},
      address_2_kanji : {id:'address_2_kanji', name:'住所2', value: charconv.han2zen(form.address_2_kanji), hint:'（全角15文字）', showcopy:false},
      address_1_kana : {id:'address_1_kana', name:'住所1（フリガナ）', value: charconv.zen2han(form.address_1_kana), hint:'（半角カナ30文字）', showcopy:false},
      address_2_kana : {id:'address_2_kana', name:'住所2（フリガナ）', value: charconv.zen2han(form.address_2_kana), hint:'（半角カナ30文字）', showcopy:false},
      person_tel : {id:'person_tel', name:'TEL', value: form.person_tel, hint:'（ハイフン含む20文字）', showcopy:false},
      person_fax : {id:'person_fax', name:'FAX', value: form.person_fax, hint:'（ハイフン含む20文字）', showcopy:false},
      invoice_no : {id:'invoice_no', name:'インボイス登録番号', value: form.invoice_no, hint:'（半角数字13文字）', showcopy:false},
    }
    return supplierinfoform
  },

  async getBankInfoForm (form) {
    const bankinfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'仕入先名', value: charconv.han2zen(form.company_name_kanji), hint:'（全角）', showcopy:false},
      bank_code : {id:'bank_code', name:'銀行コード', value: form.bank_code, hint:'（数値）', showcopy:false},
      branch_code : {id:'branch_code', name:'支店コード', value: form.branch_code, hint:'（数値）', showcopy:false},
      bank_name : {id:'bank_name', name:'銀行名', value: charconv.han2zen(form.bank_name), hint:'（全角）', showcopy:false},
      branch_name : {id:'branch_name', name:'支店名', value: charconv.han2zen(form.branch_name), hint:'（全角）', showcopy:false},
      account_type : {id:'account_type_message', name:'預金種類', value: i18n.t(form.account_type), hint:'', showcopy:false},
      account_number : {id:'account_number', name:'口座番号', value: form.account_number, hint:'（数値7文字）', showcopy:false},
      account_name : {id:'account_name', name:'口座名義', value: form.account_name, hint:'（半角カナ）', showcopy:false},
    }
    return bankinfoform
  },

  async getCustomerInfoForm (form) {
    const customerinfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'得意先名', value: charconv.han2zen(form.company_name_kanji), hint:'（全角25文字）', showcopy:false},
      company_name_kana : {id:'company_name_kana', name:'得意先名カナ', value: charconv.han2zen(charconv.hiraToKata(form.company_name_kana)), hint:'（全角カナ33文字）', showcopy:false},
      address_kanji : {id:'address_kanji', name:'住所', value: charconv.han2zen(form.address_1_kanji + form.address_2_kanji), hint:'（全角30文字）', showcopy:false},
      address_kana_trimmed : {id:'address_kana_trimmed', name:'住所カナ', value: charconv.zen2han(form.address_1_kana + form.address_2_kana).substr(0,30), hint:'（半角カナ30文字）', showcopy:false},
      tel : {id:'tel', name:'TEL', value: form.tel, hint:'（ハイフンつき半角数字）', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'氏名', value: charconv.han2zen(form.comission_person_name_sei_kanji + charconv.SP_ZEN + form.comission_person_name_mei_kanji), hint:'（全角10文字）', showcopy:false},
      person_name_kana : {id:'person_name_kana', name:'フリガナ', value: charconv.han2zen(charconv.hiraToKata(form.comission_person_name_sei_kana + charconv.SP_ZEN + form.comission_person_name_mei_kana)), hint:'（全角カナ20文字）', showcopy:false},
      person_tel : {id:'person_tel', name:'電話番号', value: form.comission_person_tel, hint:'ハイフンつき半角数字', showcopy:false},
      person_email : {id:'person_email', name:'メールアドレス', value: form.comission_person_email, hint:'', showcopy:false},
    }
    return customerinfoform
  },
  async getProsugateInfoForm (form) {
    const prosugateinfoform = {
      supplier_cd : {id:'supplier_cd', name:'仕入先コード', value: form.supplier_cd, hint:'', showcopy:false},
      company_name_kanji : {id:'company_name_kanji', name:'会社名', value: charconv.han2zen(form.company_name_kanji), hint:'（全角）', showcopy:false},
      person_name_sei_kanji : {id:'person_name_sei_kanji', name:'登録名（氏）', value: charconv.han2zen(form.person_name_sei_kanji), hint:'（全角10文字）', showcopy:false},
      person_name_mei_kanji : {id:'person_name_mei_kanji', name:'登録名（名）', value: charconv.han2zen(form.person_name_mei_kanji), hint:'（全角10文字）', showcopy:false},
      person_email : {id:'person_email', name:'PROSUGATEアカウントID（メールアドレス）', value: form.person_email, hint:'', showcopy:false},
    }
    return prosugateinfoform
  },
  async getGmindInfoForm (form) {
    const gmindinfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'仕入先名', value: charconv.han2zen(form.company_name_kanji), hint:'（全角）', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'氏名', value: charconv.han2zen(form.person_name_sei_kanji + charconv.SP_ZEN + form.person_name_mei_kanji), hint:'（全角10文字）', showcopy:false},
      person_name_kana : {id:'person_name_kana', name:'氏名（カナ）', value: charconv.han2zen(charconv.hiraToKata(form.person_name_sei_kana + charconv.SP_ZEN + form.person_name_mei_kana)), hint:'（全角カナ）', showcopy:false},
      person_email : {id:'person_email', name:'メールアドレス', value: form.person_email, hint:'', showcopy:false},
    }
    return gmindinfoform
  },
  async getJiraInfoForm (form) {
    const jirainfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'組織名', value: charconv.han2zen(form.company_name_kanji), hint:'（全角）', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'顧客名', value: charconv.han2zen(form.person_name_sei_kanji + charconv.SP_ZEN + form.person_name_mei_kanji), hint:'（全角10文字）', showcopy:false},
      person_email : {id:'person_email', name:'メールアドレス', value: form.person_email, hint:'', showcopy:false},
    }
    return jirainfoform
  },
  async getMepParnterInfoForm (form) {
    const meppartnerinfoform = {
      company_name_kanji : {id:'company_name_kanji', name:'仕入先名（漢字）', value: form.company_name_kanji, hint:'（全角）', showcopy:false},
      company_name_kana : {id:'company_name_kana', name:'仕入先名（フリガナ）', value: charconv.han2zen(charconv.hiraToKata(form.company_name_kana)), hint:'（全角カナ）', showcopy:false},
      zip_cd : {id:'zip_cd', name:'郵便番号', value: form.zip_cd, hint:'（ハイフン含む8桁）', showcopy:false},
      address_1_kanji : {id:'address_1_kanji', name:'住所1', value: form.address_1_kanji, hint:'（全角15文字）', showcopy:false},
      address_2_kanji : {id:'address_2_kanji', name:'住所2', value: form.address_2_kanji, hint:'（全角15文字）', showcopy:false},
      address_1_kana : {id:'address_1_kana', name:'住所1（フリガナ）', value: charconv.han2zen(charconv.hiraToKata(form.address_1_kana)), hint:'（全角カナ30文字）', showcopy:false},
      address_2_kana : {id:'address_2_kana', name:'住所2（フリガナ）', value: charconv.han2zen(charconv.hiraToKata(form.address_2_kana)), hint:'（全角カナ30文字）', showcopy:false},
      person_tel : {id:'person_tel', name:'担当者TEL', value: form.person_tel, hint:'（ハイフン含む20文字）', showcopy:false},
      person_fax : {id:'person_fax', name:'担当者FAX', value: form.person_fax, hint:'（ハイフン含む20文字）', showcopy:false},
      person_name_kanji : {id:'person_name_kanji', name:'顧客名', value: form.person_name_sei_kanji + charconv.SP_ZEN + form.person_name_mei_kanji, hint:'（全角10文字）', showcopy:false},
      person_email : {id:'person_email', name:'メールアドレス', value: form.person_email, hint:'', showcopy:false},
      mep_servicedesk_orgid : {id:'mep_servicedesk_orgid', name:'お問い合わせJIRA組織ID', value: form.mep_servicedesk_orgid, hint:'', showcopy:false},
    }
    return meppartnerinfoform
  },


  async startPartnerCandidateStatus (form) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $status: String,
            $bank_status: String,
            $prosugate_status: String,
            $gmind_status: String,
            $assignee_id: String,
            $assignee_name: String
          ) {
              updatePartnerCandidate(
                id:$id,
                status:$status,
                bank_status:$bank_status,
                prosugate_status:$prosugate_status,
                gmind_status:$gmind_status,
                assignee_id:$assignee_id,
                assignee_name:$assignee_name
              ){ id status bank_status prosugate_status gmind_status assignee_id assignee_name }
          } `
    const params = {
      id:form.id,
      status:"processing",
      bank_status:"processing",
      prosugate_status:"processing",
      gmind_status:"processing",
      assignee_id:store.state.user.attributes.sub,
      assignee_name:store.state.user.attributes.name
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    const bank_status = candidate.data.updatePartnerCandidate.bank_status
    const prosugate_status = candidate.data.updatePartnerCandidate.prosugate_status
    const gmind_status = candidate.data.updatePartnerCandidate.gmind_status
    const assignee_id = candidate.data.updatePartnerCandidate.assignee_id
    const assignee_name = candidate.data.updatePartnerCandidate.assignee_name
    if(id){
      return {
        id: id,
        status: status,
        bank_status: bank_status,
        prosugate_status: prosugate_status,
        gmind_status: gmind_status,
        assignee_id: assignee_id,
        assignee_name: assignee_name,
      }
    } else {
      return null
    }
  },

  async updateBankProcess (form, paramBankStatus) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $bank_status: String
          ) {
              updatePartnerCandidate(
                id:$id,
                bank_status:$bank_status
              ){ id bank_status }
          } `
    const params = {
      id:form.id,
      bank_status:paramBankStatus
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const bank_status = candidate.data.updatePartnerCandidate.bank_status
    if(id){
      return {
        id: id,
        bank_status: bank_status,
      }
    } else {
      return null
    }
  },
  async updateProsugateProcess (form, paramProsugateStatus) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $prosugate_status: String
          ) {
              updatePartnerCandidate(
                id:$id,
                prosugate_status:$prosugate_status
              ){ id prosugate_status }
          } `
    const params = {
      id:form.id,
      prosugate_status:paramProsugateStatus
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const prosugate_status = candidate.data.updatePartnerCandidate.prosugate_status
    if(id){
      return {
        id: id,
        prosugate_status: prosugate_status,
      }
    } else {
      return null
    }
  },
  async updateGmindProcess (form, paramGmindStatus) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $gmind_status: String
          ) {
              updatePartnerCandidate(
                id:$id,
                gmind_status:$gmind_status
              ){ id gmind_status }
          } `
    const params = {
      id:form.id,
      gmind_status:paramGmindStatus
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const gmind_status = candidate.data.updatePartnerCandidate.gmind_status
    if(id){
      return {
        id: id,
        gmind_status: gmind_status,
      }
    } else {
      return null
    }
  },
  async closePartnerCandidateStatus (form) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $status: String
          ) {
              updatePartnerCandidate(
                id:$id,
                status:$status
              ){ id status complete_elapsed_seconds complete_datetime }
          } `
    const params = {
      id:form.id,
      status:"done"
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    const complete_elapsed_seconds = candidate.data.updatePartnerCandidate.complete_elapsed_seconds
    const complete_datetime = candidate.data.updatePartnerCandidate.complete_datetime
    if(id){
      return {
        id: id,
        status: status,
        complete_elapsed_seconds: complete_elapsed_seconds,
        complete_datetime: complete_datetime
      }
    } else {
      return null
    }
  },

  async updatePartnerCandidateStatus (form, paramstatus) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $status: String
          ) {
              updatePartnerCandidate(
                id:$id,
                status:$status
              ){ id status }
          } `
    const params = {
      id:form.id,
      status:paramstatus
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))

    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    if(id){
      return {
        id: id,
        status: status,
      }
    } else {
      return null
    }
  },

  async updateJIRA (form) {
    form.jira_message= ""
    const updateJIRAQuery = `
        mutation updateJIRA(
          $id: String!,
          $company_name_kanji : String,
          $person_name_sei_kanji : String,
          $person_name_mei_kanji : String,
          $person_email : String,
          $mep_servicedesk_orgid : String,
          $mep_servicedesk_account_id : String,
        ) {
            updateJIRA(
              id: $id,
              company_name_kanji : $company_name_kanji,
              person_name_sei_kanji : $person_name_sei_kanji,
              person_name_mei_kanji : $person_name_mei_kanji,
              person_email : $person_email,
              mep_servicedesk_orgid : $mep_servicedesk_orgid,
              mep_servicedesk_account_id : $mep_servicedesk_account_id
            ){ mep_servicedesk_orgid mep_servicedesk_account_id message }
        } `
    const params = {
      id : form.id,
      company_name_kanji : form.company_name_kanji,
      person_name_sei_kanji : form.person_name_sei_kanji,
      person_name_mei_kanji : form.person_name_mei_kanji,
      person_email : form.person_email,
      mep_servicedesk_orgid : form.mep_servicedesk_orgid,
      mep_servicedesk_account_id : form.mep_servicedesk_account_id
    }
    const jira = await API.graphql(graphqlOperation(updateJIRAQuery, params))

    const message = jira.data.updateJIRA.message
    const res_servicedesk_orgid = jira.data.updateJIRA.mep_servicedesk_orgid
    const res_servicedesk_account_id = jira.data.updateJIRA.mep_servicedesk_account_id

    return {
      jira_message: message,
      mep_servicedesk_orgid: res_servicedesk_orgid,
      mep_servicedesk_account_id: res_servicedesk_account_id,
    }
  },

  async updateMEP (form) {
    form.mep_message= ""
    const createPartnerRecordQuery = `
        mutation createPartnerRecord(
          $id: String!,
          $supplier_cd: String!,
          $company_name_kanji : String,
          $company_name_kana : String,
          $zip_cd : String,
          $address_1_kanji : String,
          $address_2_kanji : String,
          $address_1_kana : String,
          $address_2_kana : String,
          $person_tel : String,
          $person_fax : String,
          $mep_servicedesk_orgid : String,
          $mep_servicedesk_account_id : String,
          $person_name_sei_kanji : String,
          $person_name_mei_kanji : String,
          $person_email : String,
        ) {
            createPartnerRecord(
              id: $id,
              supplier_cd : $supplier_cd,
              company_name_kanji : $company_name_kanji,
              company_name_kana : $company_name_kana,
              zip_cd : $zip_cd,
              address_1_kanji : $address_1_kanji,
              address_2_kanji : $address_2_kanji,
              address_1_kana : $address_1_kana,
              address_2_kana : $address_2_kana,
              person_tel : $person_tel,
              person_fax : $person_fax,
              mep_servicedesk_orgid : $mep_servicedesk_orgid,
              mep_servicedesk_account_id : $mep_servicedesk_account_id,
              person_name_sei_kanji : $person_name_sei_kanji,
              person_name_mei_kanji : $person_name_mei_kanji,
              person_email : $person_email,
            ){ supplier_id message }
        } `
    const params = {
      id : form.id,
      supplier_cd : form.supplier_cd,
      company_name_kanji : form.company_name_kanji,
      company_name_kana : form.company_name_kana,
      zip_cd : form.zip_cd,
      address_1_kanji : form.address_1_kanji,
      address_2_kanji : form.address_2_kanji,
      address_1_kana : form.address_1_kana,
      address_2_kana : form.address_2_kana,
      person_tel : form.person_tel,
      person_fax : form.person_fax,
      mep_servicedesk_orgid : form.mep_servicedesk_orgid,
      mep_servicedesk_account_id : form.mep_servicedesk_account_id,
      person_name_sei_kanji : form.person_name_sei_kanji,
      person_name_mei_kanji : form.person_name_mei_kanji,
      person_email : form.person_email,
    }
    
    const mep = await API.graphql(graphqlOperation(createPartnerRecordQuery, params))

    const message = mep.data.createPartnerRecord.message
    const res_supplier_id = mep.data.createPartnerRecord.supplier_id

    return {
      mep_message: message,
      mep_supplier_id: res_supplier_id,
    }
  },

  async updatePartnerSupplierCd (form, subform, suppliercd) {
    const updatePartnerSupplierCdQuery = `
          mutation updatePartnerSupplierCd(
            $id: String!,
            $supplier_cd: String,
            $company_name_kanji: String,
            $person_name_sei: String,
            $person_name_mei: String,
            $person_email: String,
          ) {
              updatePartnerSupplierCd(
                id:$id,
                supplier_cd:$supplier_cd
                company_name_kanji:$company_name_kanji
                person_name_sei:$person_name_sei
                person_name_mei:$person_name_mei
                person_email:$person_email
              ){ id status }
          } `
    const params = {
      id:form.id,
      company_name_kanji:subform.company_name_kanji.value,
      person_name_sei:subform.person_name_sei_kanji.value,
      person_name_mei:subform.person_name_mei_kanji.value,
      person_email:subform.person_email.value,
      supplier_cd:suppliercd
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerSupplierCdQuery, params))
    const id = candidate.data.updatePartnerSupplierCd.id
    const status = candidate.data.updatePartnerSupplierCd.status
    if(id){
      return {
        id: id,
        status: status,
      }
    } else {
      return null
    }
  },

  async updatePartnerCustomerCd (form, customercd) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $customer_cd: String
          ) {
              updatePartnerCandidate(
                id:$id,
                customer_cd:$customer_cd
              ){ id status }
          } `
    const params = {
      id:form.id,
      customer_cd:customercd
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))
    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    if(id){
      return {
        id: id,
        status: status,
      }
    } else {
      return null
    }
  },
  async updatePartnerCandidateMemo (form) {
    const updatePartnerCandidateQuery = `
          mutation updatePartnerCandidate(
            $id: String!,
            $memo: String
          ) {
              updatePartnerCandidate(
                id:$id,
                memo:$memo
              ){ id status }
          } `
    const params = {
      id:form.id,
      memo:form.memo
    }
    const candidate = await API.graphql(graphqlOperation(updatePartnerCandidateQuery, params))
    const id = candidate.data.updatePartnerCandidate.id
    const status = candidate.data.updatePartnerCandidate.status
    if(id){
      return {
        id: id,
        status: status,
      }
    } else {
      return null
    }
  },
  async getAttachmentDownloadPresignedUrl (fileid) {
    const getAttachmentDownloadPresignedUrl = `query getAttachmentDownloadPresignedUrl($id:String) {
          getAttachmentDownloadPresignedUrl(id:$id) {
            url
          }
        }`
    const params = {
      id: fileid
    }
    const presignedurl = await API.graphql(graphqlOperation(getAttachmentDownloadPresignedUrl, params))
    const file_url = presignedurl.data.getAttachmentDownloadPresignedUrl.url
    return file_url
  },
  async getTemplatePresignedUrl (filename) {
    const getTemplatePresignedUrl = `query getTemplatePresignedUrl($name:String) {
          getTemplatePresignedUrl(name:$name) {
            name
            url
          }
        }`
    const params = {
      name: filename
    }
    const presignedurl = await API.graphql(graphqlOperation(getTemplatePresignedUrl, params))

    const fileurl = presignedurl.data.getTemplatePresignedUrl.url
    return fileurl
  },

  datetimeFormatter (value) {
    if (value) {
      //return moment(value).format('YY/MM/DD HH:mm');
      return moment.utc(value).local().format('YY/MM/DD HH:mm');
    } else {
      return value;
    }
  },
  secondsToDatetimeFormatter (value) {
    if (value) {
      var timeD = Math.floor(value / (24 * 60 * 60));
      var timeH = Math.floor(value % (24 * 60 * 60) / (60 * 60));
      var timeM = Math.floor(value % (24 * 60 * 60) % (60 * 60) / 60);
      var timeS = value % (24 * 60 * 60) % (60 * 60) % 60;
      var timeDMS = timeD + '日 ' + ("00" + timeH).slice(-2) + ':' + ("00" + timeM).slice(-2) + ':' + ("00" + timeS).slice(-2);
      return timeDMS;
    } else {
      return value;
    }
  },
  /**
   * インボイス番号をフォーマットする
   * @returns T{value} or 空文字
   */
  invoiceFormatter (value) {
    if(value) {
        return `T${value}`;
    }
    return "";
  }
}
